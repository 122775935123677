import React, { Component } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import { DataTable } from '../DataTable/DataTable';
import { connect } from 'react-redux';
import UserSearchComponent from './UserSearch';
import AddUserModalComponent from './AddUserModal';
import { actions } from '../../helpers/messages';
import { Roles } from '../../helpers/constants';
class UserListComponent extends Component {
    
    /* istanbul ignore next  */
    getRoleName(role) {
        const userRole = Roles.find(x => x.userRole === role.toLowerCase());
        return userRole && userRole.label ? userRole.label : role;
    }
    /**
	 * @description function to handle user's name display
	 * @param {*}
	 * @memberof Header
	 */
	/* istanbul ignore next  */
	getNameShort = (name) => {
		return name?.length > 20 ? `${name.substring(0, 20)}...` : name;
	}
    render() {
        const { loading, toggleLoader, fromDashboard, searchString, searchSubmit, refreshUserList, handleSearchSubmit, handleReset, userUpdated, userStatusUpdated, newUserName } = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }) => (<span>{row.original.name ? this.getNameShort(row.original.name) : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Username',
                accessor: 'userName',
                Cell: ({ row }) => (<span>{row.original.userName ? row.original.userName : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({ row }) => (<span>{row.original.email ? row.original.email : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Role',
                accessor: 'group',
                Cell: ({ row }) => (<span>{row.original.group ? this.getRoleName(row.original.group) : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Status',
                userStatus: true
            },
            {
                Header: ' ',
                userAction: true
            }

        ];
        return (
            <div>
                {loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : null}
                <div className="bgGray">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-1">
                                <SideMenu />
                            </div>
                            <div className="col-md-11">
                                <div className="surgeon-list data-list-tabs table-wrap px-3"data-testid="userList">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="file-list-heading">User List</h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <UserSearchComponent handleSearchSubmit={handleSearchSubmit} handleReset={handleReset} />
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <AddUserModalComponent fromDashboard={fromDashboard} userUpdated={userUpdated} />
                                        </div>
                                    </div>
                                    <div className="user-list-container">
                                        <DataTable
                                            listType="user-list"
                                            showPagination={true}
                                            columns={columns}
                                            newUserName={newUserName ? newUserName : ''}
                                            searchString={searchString && searchSubmit ? searchString : ''}
                                            refreshDataList={searchSubmit || refreshUserList}
                                            userStatusUpdated={userStatusUpdated}
                                            toggleLoader={toggleLoader}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => (
    {
        state
    }
)
const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(UserListComponent);
