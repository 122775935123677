import React, { Component } from 'react';
import MultiSelectComponent from '../../../shared/MultiSelectComponent';

export default class FileSearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: '',
            selectedAssets: []
        }
    }
    /** 
     *  @description Function to input change
     *  @param searchString The value in the input field
     *  @memberof FileSearchComponent
     */
    handleOnChange = (field, value) => {
        this.setState({ [field]: value });
    }

    /** 
     *  @description Function to handle search reset
     *  @memberof FileSearchComponent
     */
    handleReset = () => {
        this.setState({ searchString: '', selectedAssets: [] });
        this.props.handleReset();
    }

    handleSubmit = () => {
        const { searchString, selectedAssets } = this.state;
        var assets = '';
        /* istanbul ignore next  */
        if (selectedAssets.length) {
            assets = selectedAssets.map(x => x.value).join(',');
        }
        const params = {
            searchString,
            assetType: assets
        }

        this.props.handleSearchSubmit(params);
    }

    /** 
     *  @description Function to handle search submit by hitting enter key
     *  @param e Keypress event
     *  @memberof FileSearchComponent
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.handleSubmit();
        }
    }

    render() {
        const { assets } = this.props;
        const { searchString, selectedAssets } = this.state;

        return (
            <div className="da-search-wrap">
                <div className="search-dropdown">
                    <MultiSelectComponent
                        id="region"
                        data-testid="assets"
                        noChipsView={true}
                        hasSelectAll={true}
                        className="multiselect-wrap"
                        options={assets && assets.length ? assets : []}
                        selectedValues={selectedAssets}
                        handleSelectChange={(selectedItems) => this.handleOnChange('selectedAssets', selectedItems)}
                        placeholder="Asset Types"
                    />
                </div>
                <div className="search-input d-flex">
                    <input type="text"  data-testid="file-search" id="file-search" placeholder="Search" className="file-search form-control me-2"
                        value={searchString} onChange={(e) => this.handleOnChange('searchString', e.target.value)} onKeyUp={this.onEnterPress} />
                    <button type="button" data-testid="search-submit" id="search-submit" className="btn btn-primary me-2" disabled={searchString === '' && !selectedAssets.length} onClick={this.handleSubmit}>Search</button>
                    <button type="button"  data-testid="search-reset" id="search-reset" className="btn btn-secondary" onClick={this.handleReset}>Reset</button>
                </div>
            </div>
        );
    }
}
