import React, { Component } from 'react';
import { actions } from '../../helpers/messages';
import { errorMessages } from '../../helpers/messages';

export default class RegionDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
    }

    /**
     * @description Function to disable edit mode while restting the form
     * @memberof RegionDetailsComponent
     */
    /* istanbul ignore next  */
    cancelEdit = () => {
        this.myFormRef.reset();
        this.props.toggleEditMode();
    }

    render() {
        const { loading, editMode, toggleEditMode, descCharCount, descCharLimit, editObj, handleInputChange, onEnterPress, showSuccessMsg, showErrorMsg, formErrors, saveRegion, handleCancel, nameCharCount, nameCharLimit } = this.props;
        return (
            <>
                {loading ? <div className="loading-overlay-upload add-surgeon-overlay"><span>{actions.load}</span></div> :
                    <div className="customer-fields surgeon-details">
                        <div className="region-container"  data-testid="regionDetails">
                            <div className="card-body py-0">
                                <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                                    <div className="row">
                                        {showSuccessMsg ?
                                            <div className="col-sm-12">
                                                <div className="customer-save-success-msg">
                                                    <p>{showSuccessMsg}</p>
                                                </div>
                                            </div>
                                            : ''}
                                        {showErrorMsg ?
                                            <div className="col-sm-12">
                                                <div className="customer-save-error-msg">
                                                    <p>{showErrorMsg}</p>
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                    <fieldset className="customer-fields reset-password p-0" disabled={!editMode}>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label">Name{editMode ? <span className="red">*</span> : ''}</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="text"
                                                            id="region"
                                                            data-testid="region"
                                                            autoComplete="off"
                                                            className={`form-control ${formErrors && formErrors.region ? 'has-error' : ''}`}
                                                            value={editObj?.region}
                                                            onChange={(e) => handleInputChange('region', e.target.value)}
                                                            maxLength={nameCharLimit} />
                                                        {formErrors && formErrors.region ?
                                                            <div className="customer-error-msg">
                                                                <p>{formErrors.region}</p>
                                                            </div>
                                                            : <p className="m-0 fs-8-rem error">{errorMessages.fileUpload.charCount(nameCharCount, nameCharLimit)}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label">Description</label>
                                                    <div className="col-sm-8">
                                                        <textarea
                                                            id="description"
                                                            data-testid="description"
                                                            className={`form-control ${formErrors && formErrors.description ? 'has-error' : ''} ${!editMode ? 'disabled' : ''}`}
                                                            maxLength={descCharLimit}
                                                            value={editObj?.description}
                                                            rows="6"
                                                            resize="none"
                                                            onChange={(e) => handleInputChange('description', e.target.value)}
                                                        />
                                                        {formErrors && formErrors.description ?
                                                            <div className="customer-error-msg">
                                                                <p>{formErrors.description}</p>
                                                            </div>
                                                            : <p className="m-0 fs-8-rem error">{errorMessages.fileUpload.charCount(descCharCount, descCharLimit)}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div></fieldset>
                                    <div className="row">
                                        <div className="col-sm-12 text-end">
                                            {!editMode ?
                                                <>
                                                    <button type="button" id="edit-btn" className="btn btn-primary case-action-btn me-0" onClick={toggleEditMode}>Edit</button>
                                                    <button type="button" className="btn btn-secondary ms-2" id="cancel" onClick={handleCancel}>Close</button>
                                                </>
                                                : ''}
                                            {editMode ?
                                                <>
                                                    <button type="button" id="save-btn" className="btn btn-primary case-action-btn" onClick={saveRegion}>Save</button>
                                                    <button type="button" id="cancel-btn" className="btn btn-secondary me-0" onClick={this.cancelEdit}>Cancel</button>
                                                </>
                                                : ''}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                }
            </>
        );
    }
}
