import React, { Component } from 'react'

export default class EngineerComponent extends Component {
	render() {
		return (
			<div className="data-list-tabs">
				<div className="row">
					<div className="col-md-12">
						<div className="dashboard-blocks">
							<div className="dashboard-blocks-body">
								<div className="dashboard-block-title">Engineer Dashboard</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
