import React, { Component } from 'react'
import RegionsList from './RegionsList'
export default class RegionsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fromDashboard: (props.location && props.location.state && props.location.state.fromDashboard === true),
			refreshRegionList: '',
			loading: false,
			newRegionName: '',
		}
	}

	/** 
	 *  @description Function to handle region updated after creation
	 *  @memberof RegionsContainer
	 */
	regionUpdated = (status) => {
		this.setState({ refreshRegionList: status });
	}

	/** 
	 *  @description Function to handle region status updated after creation
	 *  @memberof RegionsContainer
	 */
	regionStatusUpdated = (status) => {
		this.setState({ refreshRegionList: status });
	}

	/** 
	 *  @description Function to toggle loader from sub components
	 *  @memberof RegionsContainer
	 */
	toggleLoader = (status) => {
		this.setState({ loading: status, refreshRegionList: false });
	}
	render() {
		return (
			<RegionsList
				refreshRegionList={this.state.refreshRegionList}
				loading={this.state.loading}
				toggleLoader={this.toggleLoader}
				regionUpdated={this.regionUpdated}
				regionStatusUpdated={this.regionStatusUpdated}
				fromDashboard={this.state.fromDashboard}
			/>
		)
	}
}
