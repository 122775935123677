import React, { Component } from 'react';
import { actions } from '../../../helpers/messages';
import { errorMessages } from '../../../helpers/messages';
import ErrorMsgBlockComponent from '../../Error/ErrorMessageBlock';

export default class AddRegionComponent extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
    }

    /**
     * @description Function to disable edit mode while restting the form
     * @memberof AddRegionComponent
     */
    /* istanbul ignore next  */
    cancelEdit = () => {
        this.myFormRef.reset();
        this.props.handleCancel();
    }

    render() {
        const { loading, regionObj, handleInputChange, onEnterPress, showSuccessMsg, formErrors, saveRegion, regionSaveError, descCharCount, descCharLimit, nameCharCount, nameCharLimit } = this.props;

        return (
            <>
                {loading ? <div className="loading-overlay-upload add-surgeon-overlay"><span>{actions.load}</span></div> : ''}
                <div className="customer-fields surgeon-details" data-testid="addRegion">
                    <div className="region-container">
                        <div className="card-body py-0">
                            <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                                <div className="row">
                                    {showSuccessMsg ?
                                        <div className="col-sm-12">
                                            <div className="customer-save-success-msg">
                                                <p>{showSuccessMsg}</p>
                                            </div>
                                        </div>
                                        : ''}
                                    {regionSaveError ?
                                        <div className="col-sm-12">
                                            <div className="customer-save-error-msg">
                                                <ErrorMsgBlockComponent className="pb-4 m-0" errorObject={regionSaveError} />
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                                <fieldset className="customer-fields reset-password p-0">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Name<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        id="region"
                                                        data-testid="region"
                                                        autoComplete="off"
                                                        className={`form-control ${formErrors && formErrors.region ? 'has-error' : ''}`}
                                                        value={regionObj && regionObj.region ? regionObj.region : ''}
                                                        onChange={(e) => handleInputChange('region', e.target.value)}
                                                        maxLength={nameCharLimit} />
                                                    {formErrors && formErrors.region ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.region}</p>
                                                        </div>
                                                        : <p className="m-0 fs-8-rem error">{errorMessages.fileUpload.charCount(nameCharCount, nameCharLimit)}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Description</label>
                                                <div className="col-sm-8">
                                                    <textarea
                                                        id="description"
                                                        data-testid="description"
                                                        className={`form-control ${formErrors && formErrors.description ? 'has-error' : ''}`}
                                                        maxLength={descCharLimit}
                                                        rows="6"
                                                        onChange={(e) => handleInputChange('description', e.target.value)}
                                                    >
                                                    </textarea>
                                                     {formErrors && formErrors.description ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.description}</p>
                                                        </div>
                                                        : <p className="m-0 fs-8-rem error">{errorMessages.fileUpload.charCount(descCharCount, descCharLimit)}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 text-end">
                                        <button type="button" id="save-btn" className="btn btn-primary case-action-btn" onClick={saveRegion}>Save</button>
                                        <button type="button" id="cancel-btn" className="btn btn-secondary me-0" onClick={this.cancelEdit}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
