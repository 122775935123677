import environment from '../../config/environment';
import { getCognitoIdTokenFromStorage, logoutService } from '../aws/aws-services';
import axios from 'axios';
import {
	errorMessages, successMessages,
	loggerEventOutcome, loggerEventTypes, loggerEventName,
} from '../../helpers/messages';
import { logger } from '../logger/logger-service';

/**
* @description function to call API to get all regions
* @param  {} callback
*/
export const getAllRegions = async (urlParams,callback) => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/region',
		method: 'GET',
		params:urlParams,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all regions as a promise
* @param  {} callback
*/
export const getAllRegionsPromise = (urlParams) => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/region',
		method: 'GET',
		params:urlParams,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	
	return new Promise((resolve, reject) => {
		axiosApiCall(options, (err, res) => {
			if (err) {
				return reject(err);
			}
			if (res) {
				return resolve(res);
			}
		})
	})
}

/**
* @description function to call API to get all regions
* @param  {} callback
*/
export const getAllCustomers = () => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/customer',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	
	return new Promise((resolve, reject) => {
		axiosApiCall(options, (err, res) => {
			if (err) {
				return reject(err);
			}
			if (res) {
				return resolve(res);
			}
		})
	})
}

/**
* @description function to call API to get all asset types
* @param  {} callback
*/
export const getAllAssetTypes = () => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/asset',
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	return new Promise((resolve, reject) => {
		axiosApiCall(options, (err, res) => {
			if (err) {
				return reject(err);
			}
			if (res) {
				return resolve(res);
			}
		})
	})
}

/**
 * @description function to call API to update procedure metadata
 * @param  {} data
 * @param  {} callback
 */
export const updateMetadata = async (data, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/metadata',
		method: 'POST',
		EventType: loggerEventTypes.create,
		EventName: loggerEventName.fileUpload,
		AddLogs: true,
		EventMessage: successMessages.fileUploadSuccess,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: data
	};
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to edit file metadata
 * @param  {} data
 * @param  {} callback
 */
export const updateFileDetails = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/metadata',
		method: 'PUT',
		EventType: loggerEventTypes.update,
		EventName: loggerEventName.fileUpdate,
		AddLogs: params.logsFlag,
		EventMessage: successMessages.fileUpdateSuccess,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: params.data
	};
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to update procedure metadata
 * @param  {} data
 * @param  {} callback
 */
export const fileVersionDuplicateCheck = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/asset/duplicatecheck',
		method: 'POST',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		},
		data: params
	};
	axiosApiCall(options, callback)
}

/**
 * @description function to call API to get all procedure metadata list
 * @param  {} data
 * @param  {} callback
 */
export const procedureMetadataListService = async (urlParams, callback) => {
	/* istanbul ignore next  */
	const fileListOptions = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/metadata',
		params: urlParams,
		method: 'GET',
		EventType: loggerEventTypes.read,
		EventName: loggerEventName.filesList,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(fileListOptions, callback)
}

/**
 * @description function to call API to get file details
 * @param  {} fileName
 * @param  {} callback
 */
export const getFileDetailsService = async (fileID) => {
	/* istanbul ignore next  */
	const options = {
		url: `${environment.apiBaseUrl}procedure-metadata/damp/${fileID}/metadata`,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};

	return new Promise((resolve, reject) => {
		axiosApiCall(options, (err, res) => {
			if (err) {
				return reject(err);
			}
			if (res) {
				return resolve(res);
			}
		})
	})
}

/**
 * @description function to call API to get all logs
 * @param urlParams filter parameters to be passed as query params
 * @param callback Callback function to handle the API response
 */
export const exportLogsDataService = async (urlParams, callback) => {
	/* istanbul ignore next  */
	const LogsDataOptions = {
		url: environment.apiBaseUrl + 'logger-service/export/logger?eventSource=damp',
		method: 'GET',
		params: urlParams,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(LogsDataOptions, callback)
}

/**
* @description function to call API to track the user activities
* @param params POST params to be sent
* @param callback Callback function to handle the response
*/
export const loggerPostAPI = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'logger-service/logger',
		method: 'POST',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get the user activities
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
export const loggerGetAPI = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'logger-service/logger',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to update user details
* @param userName Username of user to be edited
* @param params PUT params to be sent
* @param callback Callback function to handle the response
*/
export const updateUserDetails = async (userName, params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/damp/user/' + userName,
		method: 'PUT',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to update region details
* @param params PUT params to be sent
* @param callback Callback function to handle the response
*/
export const updateRegionDetails = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/region',
		method: 'PUT',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to get all users
* @param params GET params to be sent
* @param callback Callback function to handle the response
*/
export const getAllUsersList = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/damp/userslist',
		method: 'GET',
		params: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}
/**
* @description function to call API to get user details
* @param userName Username of the user to be fetched
* @param callback Callback function to handle the response
*/
export const getUserDetails = async (username, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/damp/user/${username}/details`,
		method: 'GET',
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to update user activity
* @param params PUT params to be sent
* @param callback Callback function to handle the response
*/
export const updateUserActivity = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'users-service/damp/user/useraction',
		method: 'PUT',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to save user in db
* @param params User attributes to be saved
* @param callback Callback function to handle the response
*/
export const saveUserService = async (params, callback) => {
	const options = {
		url: `${environment.apiBaseUrl}users-service/damp/user`,
		method: 'POST',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
* @description function to call API to save region in db
* @param params Region attributes to be saved
* @param callback Callback function to handle the response
*/
export const saveRegion = async (params, callback) => {
	const options = {
		url: environment.apiBaseUrl + 'procedure-metadata/damp/region',
		method: 'POST',
		data: params,
		headers: {
			'Authorization': "Bearer " + getCognitoIdTokenFromStorage(),
			'Content-Type': 'application/json;charset=UTF-8'
		}
	};
	/* istanbul ignore next  */
	axiosApiCall(options, callback)
}

/**
 * @description function to make AJAX call
 * @param  {} data
 * @param  {} callback
 */
const axiosApiCall = async (options, callback) => {
	/* istanbul ignore next  */
	let getContent = {
		"Data": options && options.EventMessage ? options.EventMessage : ''
	}
	const loggerObj = {
		"EventOutcome": loggerEventOutcome.success,
		"EventType": options && options.EventType ? options.EventType : '',
		"EventName": options && options.EventName ? options.EventName : '',
		"Content": getContent
	}
	axios(options)
		.then(response => {
			if (options && options.AddLogs) {
				setTimeout(() => {
					logger(loggerObj);
				}, 0);
			}
			return callback(null, response);
		})
		.catch(error => {
			if (error.response && error.response.status) {
				const fallBackErrorMsg = errorMessages[error.response.status] ? errorMessages[error.response.status] : errorMessages.generic;
				// If API call is unauthorized, force log out
				if (error.response.status === 401) {
					logoutService((err, res) => { });
				}

				// Checking if response has a body
				if (!error.response.data) {
					error = { errorCode: error.response.status, message: fallBackErrorMsg };
				} else {
					let errRes = error.response.data;
					let isErrorObj = errRes.error && typeof errRes.error === 'object';
					// Create error object
					// If error object is sent from API, put that in, else create custom one
					error = isErrorObj ?
						{
							...errRes.error,
							message: errRes.error.errorMessage ? errRes.error.errorMessage : fallBackErrorMsg
						} :
						{
							errorCode: error.response.status,
							message: errRes.message ? errRes.message : fallBackErrorMsg
						};
				}
			} else {
				error = { errorCode: 'Unknown', message: errorMessages.generic };
			}

			if (options && options.EventType) {
				let errorLoggerObj = {
					"EventOutcome": loggerEventOutcome.failure,
					"EventType": options && options.EventType ? options.EventType : '',
					"EventName": options && options.EventName ? options.EventName : '',
					"Content": {
						"Data": error.message,
					}
				}
				setTimeout(() => {
					logger(errorLoggerObj);
				}, 0);
			}

			return callback(error, null);
		});
}
