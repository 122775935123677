import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sidemenu from '../SideMenu/SideMenu';
import AdminDashboard from './Admin';
import { getUserRoleType } from '../../services/aws/aws-services';
import { actions } from '../../helpers/messages'
import UnauthorizedUser from './UnauthorizedUser';
import { RoleNameKeyMap, RolePermission } from '../../helpers/constants';
import EngineerDashboard from './Engineer';

class DashboardComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: props.loading === true,
			showBanner: true
		}
	}

	/**
	* @description Function to toggle loader
	* @param status Show/hide status of the loader
	* @memberof DashboardComponent
	*/
	 /* istanbul ignore next  */
	toggleLoading = (status) => {
		this.setState({ loading: status })
	}

	render() {
		const loggedInUserType = getUserRoleType();
		return (<>
			{this.state.loading ? <div className="loading-overlay"><span>{actions.load}</span></div> : null}
			<div className="bgGray">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-1">
							<Sidemenu loggerObj={this.props.loggerObj} />
						</div>
						<div className="col-md-11" >
							{/* Admin Dashboard */}
							{loggedInUserType === RoleNameKeyMap.admin ? <AdminDashboard /> : null}
							{/* Engineer Dashboard */}
							{loggedInUserType === RoleNameKeyMap.engineer ? <EngineerDashboard /> : null}
							{/* Unauthorized user */}
							{RolePermission.login.indexOf(loggedInUserType) === -1 ? <UnauthorizedUser /> : null}
						</div>
					</div>
				</div>
			</div>
		</>);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
