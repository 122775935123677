import React, { Component } from 'react';
import AdminPasswordResetComponent from './AdminPasswordReset';
import { charset, getPasswordResetEmailTemplate, getUserReactivatedEmailTemplate, sourceEmail, subjectLines } from '../../helpers/Email';
import { loggerEventName, loggerEventOutcome, loggerEventTypes, resetPasswordMessages, userMessages } from '../../helpers/messages';
import { matchExpression, passwordRegexMatch, passwordStrength } from '../../helpers/PasswordValidate';
import { activityUpdater } from '../../services/activity/activity-service';
import { sendEmail, updateUserPassword } from '../../services/aws/aws-services';
import { logger } from '../../services/logger/logger-service';

let startDate;
export default class AdminPasswordResetContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            user: props.user || '',
            newPassword: '',
            confirmPassword: '',
            errors: {},
            successMessage: '',
            pwdStrengthLabel: '',
            pwdStrengthProgress: '',
            pwdStrengthVarient: '',
            expMatch: {},
            showStatusChangeSuccess: false
        };
    }

    componentDidMount() {
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.timeSpentLogger);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
    }

    /**
     * @description function to handle application logs for time spent on the page
     * @param {*}
     * @memberof AdminPasswordResetContainer
     */
    timeSpentLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.updatePasswordAdmin,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description function to log password change event
     * @memberof AdminPasswordResetContainer
     */
    /* istanbul ignore next  */
    applicationLogger = (params) => {
        const loggerObj = {
            "EventOutcome": params.outcome,
            "EventType": loggerEventTypes.update,
            "EventName": loggerEventName.updatePasswordAdmin,
            "Content": {
                "Data": params.message
            }
        }
        logger(loggerObj);
    }

    /**
    * @description Function to handle changes in reset password form
    * @memberof AdminPasswordResetContainer
    */
    handleChange = (value, field) => {
        var errors = { ...this.state.errors };
        const userName = this.state.user ? this.state.user.userName : null;
        errors.cognitoError = ''
        this.setState({ errors, successMessage: '' });
        if (field === 'newPassword') {
            var strengthObj = passwordStrength(value, userName);
            this.setState({ ...strengthObj, expMatch: {} });

            if (value && !passwordRegexMatch(value, userName)) {
                errors.newPassword = '';
                errors.confirmPassword = '';
                errors.newPasswordMatch = true;
            } else {
                errors.newPasswordMatch = '';

            }
            if (value === '') {
                this.setState({ pwdStrengthLabel: '' });
            }
            this.setState({ errors, newPassword: value });

        } else if (field === 'confirmPassword') {
            errors.confirmPassword = '';
            this.setState({ errors, confirmPassword: value });
        }
    }

    /**
    * @description Function to handle blur event in reset password form
    * @memberof AdminPasswordResetContainer
    */
     handleBlur = (value, field) => {
        const userName = this.state.user ? this.state.user.userName : null;
        this.handleChange(value, field);
        setTimeout(() => {
            if (field === 'newPassword') {
                var matchResults = matchExpression(value, this.state.expMatch, userName);
                this.setState({ expMatch: matchResults });
            }
        }, 0);
    }

    /**
    * @description Function to submit reset password form
    * @memberof AdminPasswordResetContainer
    */
    handleResetPassword = (event) => {
        event.preventDefault();
        this.setState({ successMessage: '' });
        if (this.validateResetPassInputs()) {
            const { user, newPassword } = this.state;
            const userName = user && user.userName ? user.userName : '';
            this.resetPassword(userName, newPassword);
        }
    }
    /**
    * @description Function to validate reset password form
    * @memberof AdminPasswordResetContainer
    */
    validateResetPassInputs = () => {
        const { newPassword, confirmPassword, user } = this.state;
        const userName = user && user.userName ? user.userName : '';
        let errors = {};
        let isValid = true;
        if (!newPassword) {
            isValid = false;
            errors["newPassword"] = resetPasswordMessages.error.newPassword;
        } else if (!confirmPassword) {
            isValid = false;
            errors["confirmPassword"] = resetPasswordMessages.error.confirmPassword;
        } else if (typeof newPassword !== "undefined" && typeof confirmPassword !== "undefined") {
            this.handleBlur(newPassword, 'newPassword');
            if (!passwordRegexMatch(newPassword, userName)) {
                isValid = false;
                errors["newPasswordMatch"] = true;
            }
            if (newPassword !== confirmPassword) {
                isValid = false;
                errors["confirmPassword"] = resetPasswordMessages.error.notMatch;
            }
        }
        this.setState({
            errors: errors
        });
        return isValid;
    }

    /**
     * @description function to submit the form on press of enter key
     * @param {*}
     * @memberof AdminPasswordResetContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            this.handleResetPassword(e);
        }
    }

    /**
    * @description Function to call password reset service
    * @memberof AdminPasswordResetContainer
    */
    resetPassword(userName, newPassword) {
        var errors = { ...this.state.errors }
        this.setState({ loading: true, successMessage: '' });
        updateUserPassword(userName, newPassword).then(res => {
            if (res) {
                this.sendEmailToUser(newPassword);
                const params = {
                    outcome: loggerEventOutcome.success,
                    message: resetPasswordMessages.resetMsgWithUsername(userName)
                }
                this.applicationLogger(params);
                this.setState({
                    loading: false,
                    successMessage: this.state.user.isStatusChange ? userMessages.statusChanged(true) : resetPasswordMessages.updatePassword,
                    showStatusChangeSuccess: this.state.user.isStatusChange === true,
                    pwdStrengthLabel: '',
                    newPassword: '',
                    confirmPassword: ''
                });
                this.updateActivity();
            }
        }).catch(err => {
            errors.cognitoError = err && err.message ? err.message : resetPasswordMessages.resetFailed;
            const params = {
                outcome: loggerEventOutcome.failure,
                message: errors.cognitoError
            }
            this.applicationLogger(params);
            this.setState({ errors, loading: false, pwdStrengthLabel: '', showStatusChangeSuccess: false });
        });
    }

    /**
    * @description function to notify user of the password udpate
    * @memberof AdminPasswordResetContainer
    */
    sendEmailToUser = (password) => {
        const user = {
            name: this.state.user.name,
            username: this.state.user.userName,
            password: password
        }
        const params = {
            Destination: {
                ToAddresses: [this.state.user.email]
            },
            Message: {
                Body: {
                    Html: {
                        Charset: charset,
                        Data: this.state.user && this.state.user.isStatusChange ? getUserReactivatedEmailTemplate(user) : getPasswordResetEmailTemplate(user)
                    }
                },
                Subject: {
                    Charset: charset,
                    Data: this.state.user && this.state.user.isStatusChange ? subjectLines.welcome : subjectLines.passwordReset
                }
            },
            Source: sourceEmail
        };
        sendEmail(params, (res) => {
            this.setState({ showEmailSuccessMsg: userMessages.showEmailSuccessMsg });
        })
    }

    /**
     * @description function to handle  password reset activity
     * @memberof AdminPasswordResetContainer
     */
    /* istanbul ignore next  */
    updateActivity = () => {
        if (this.state.user && this.state.user.userName) {
            const activityObj = {
                lastPasswordResettime: new Date().toISOString(),
                userName: this.state.user.userName
            }
            activityUpdater(activityObj);
        }
    }

    render() {
        return (
            <>
                <AdminPasswordResetComponent
                    loading={this.state.loading}
                    user={this.state.user}
                    newPassword={this.state.newPassword}
                    confirmPassword={this.state.confirmPassword}
                    handleResetPassword={this.handleResetPassword}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    formErrors={this.state.errors}
                    successMessage={this.state.successMessage}
                    showStatusChangeSuccess={this.state.showStatusChangeSuccess}
                    onEnterPress={this.onEnterPress}
                    handleCancel={this.props.handleCancel}
                    pwdStrengthLabel={this.state.pwdStrengthLabel}
                    pwdStrengthProgress={this.state.pwdStrengthProgress}
                    pwdStrengthVarient={this.state.pwdStrengthVarient}
                    pwdMatchObj={this.state.expMatch}
                    resetPwdErrMsg={resetPasswordMessages.error.passwordErrors}
                />
            </>
        )
    }
}
