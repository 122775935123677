import React, { Component } from 'react';
import { actions } from '../../../helpers/messages';
import { ProgressBar } from 'react-bootstrap';
import ErrorMsgBlockComponent from '../../Error/ErrorMessageBlock';
import { PasswordCriteria, PasswordHints } from '../../../shared/PasswordCriteria';
import { Roles } from '../../../helpers/constants';

export default class AddUserComponent extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
    }

    /**
     * @description Function to disable edit mode while restting the form
     * @memberof UserDetailsComponent
     */
    /* istanbul ignore next  */
    cancelEdit = () => {
        this.myFormRef.reset();
        this.props.handleCancel();
    }

    render() {
        const { loading, userObj, handleInputChange, handleBlur, onEnterPress, showSuccessMsg, formErrors,
            pwdStrengthLabel, pwdStrengthProgress, pwdStrengthVarient, pwdMatchObj, resetPwdErrMsg, saveUser, userSaveError } = this.props;

        return (
            <>
                {loading ? <div className="loading-overlay-upload add-surgeon-overlay"><span>{actions.load}</span></div> : ''}
                <div className="customer-fields surgeon-details" data-testid="addUser">
                    <div className="add-user-container">
                        <div className="card-body py-0">
                            <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                                <div className="row">
                                    {showSuccessMsg ?
                                        <div className="col-sm-12">
                                            <div className="customer-save-success-msg">
                                                <p>{showSuccessMsg}</p>
                                            </div>
                                        </div>
                                        : ''}
                                    {userSaveError ?
                                        <div className="col-sm-12">
                                            <div className="customer-save-error-msg">
                                                <ErrorMsgBlockComponent className="pb-4 m-0" errorObject={userSaveError} />
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                                <fieldset className="customer-fields reset-password p-0">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="firstName" className="col-sm-4 col-form-label shrink-label">First Name<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        id="firstName"
                                                        data-testid="firstName"
                                                        autoComplete="off"
                                                        maxLength={50}
                                                        className={`form-control ${formErrors && formErrors.firstName ? 'has-error' : ''}`}
                                                        value={userObj && userObj.firstName ? userObj.firstName : ''}
                                                        onChange={(e) => handleInputChange('firstName', e.target.value)}
                                                    />
                                                    {formErrors && formErrors.firstName ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.firstName}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="lastName" className="col-sm-4 col-form-label shrink-label">Last Name<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        id="lastName"
                                                        data-testid="lastName"
                                                        autoComplete="off"
                                                        maxLength={50}
                                                        className={`form-control ${formErrors && formErrors.lastName ? 'has-error' : ''}`}
                                                        value={userObj && userObj.lastName ? userObj.lastName : ''}
                                                        onChange={(e) => handleInputChange('lastName', e.target.value)}
                                                    />
                                                    {formErrors && formErrors.lastName ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.lastName}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="email" className="col-sm-4 col-form-label shrink-label">Email<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="text"
                                                        id="email"
                                                        data-testid="email"
                                                        autoComplete="off"
                                                        className={`form-control ${formErrors && formErrors.email ? 'has-error' : ''}`}
                                                        value={userObj && userObj.email ? userObj.email : ''}
                                                        onChange={(e) => handleInputChange('email', e.target.value)}
                                                    />
                                                    {formErrors && formErrors.email ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.email}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="userName" className="col-sm-4 col-form-label shrink-label">Username<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="userName"
                                                        id="userName"
                                                        data-testid="userName"
                                                        autoComplete="off"
                                                        className={`form-control ${formErrors && formErrors.userName ? 'has-error' : ''}`}
                                                        value={userObj && userObj.userName ? userObj.userName : ''}
                                                        onChange={(e) => handleInputChange('userName', e.target.value)}
                                                    />
                                                    {formErrors && formErrors.userName ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.userName}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="password" className="col-sm-4 col-form-label shrink-label">Password<span className="red">*</span></label>
                                                <div className="col-sm-8 add-user-password-input">
                                                    <div className="reset-password-input-wrap new-password">
                                                        <input
                                                            type="password"
                                                            className={`form-control ${formErrors && formErrors.password ? 'has-error' : ''}`}
                                                            name="newPassword"
                                                            autoComplete="new-password"
                                                            value={userObj && userObj.password ? userObj.password : ''}
                                                            onChange={(e) => handleInputChange('password', e.target.value)}
                                                            onBlur={(e) => handleBlur('password', e.target.value)}
                                                            id="password" 
                                                            data-testid="password"/>
                                                        {/* Password Hint */}
                                                        <PasswordHints containerClass="add-user-password-hint" />
                                                        {formErrors && formErrors.password ?
                                                            <div className="customer-error-msg add-user-password-error-msg mb-2">
                                                                <p className="m-0">{formErrors.password}</p>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    {pwdStrengthLabel ?
                                                        <div className="new-password-errors mt-1">
                                                            <ProgressBar variant={pwdStrengthVarient} now={pwdStrengthProgress} label={pwdStrengthLabel} />
                                                        </div>
                                                        : null}
                                                    {formErrors && formErrors.passwordMatch ?
                                                        <div className="add-user-password-error-msg">
                                                            <PasswordCriteria pwdMatchObj={pwdMatchObj} errorMessages={resetPwdErrMsg} />
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="confirmPassword" className="col-sm-4 col-form-label shrink-label">Confirm Password<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <div className="reset-password-input-wrap confirm-password">
                                                        <input
                                                            type="password"
                                                            className={`form-control ${formErrors && formErrors.confirmPassword ? 'has-error' : ''}`}
                                                            name="confirmPassword"
                                                            autoComplete="confirm-password"
                                                            value={userObj && userObj.confirmPassword ? userObj.confirmPassword : ''}
                                                            disabled={loading}
                                                            onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                                                            onKeyPress={onEnterPress}
                                                            id="confirmPassword" 
                                                            data-testid="confirmPassword"/>
                                                        {formErrors && formErrors.confirmPassword ?
                                                            <div className="customer-error-msg add-user-password-error-msg">
                                                                <p className="m-0">{formErrors.confirmPassword}</p>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group row">
                                                <label htmlFor="role" className="col-sm-4 col-form-label shrink-label">Role<span className="red">*</span></label>
                                                <div className="col-sm-8">
                                                    <select
                                                        id="role"
                                                        data-testid="role"
                                                        defaultValue={userObj && userObj.role ? userObj.role : ''}
                                                        className={`form-select ${formErrors && formErrors.role ? 'has-error' : ''}`}
                                                        onChange={(e) => handleInputChange('role', e.target.value)}>
                                                        <option value="">Select a role</option>
                                                        {Roles.map(role => <option key={`option-${role.userRole}`} value={role.cognitoGroupName}>{role.label}</option>)}
                                                    </select>                                                    
                                                    {formErrors && formErrors.role ?
                                                        <div className="customer-error-msg">
                                                            <p>{formErrors.role}</p>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="row">
                                    <div className="col-sm-12 text-end">
                                        <button type="button" id="save-btn" className="btn btn-primary case-action-btn" onClick={saveUser}>Save</button>
                                        <button type="button" id="cancel-btn" className="btn btn-secondary me-0" onClick={this.cancelEdit}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
