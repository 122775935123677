import React, { Component } from 'react';
import { actions, errorMessages, fileListMessages } from '../../helpers/messages';
import { arrayToString, parseHTML } from '../../helpers/GlobalFunctions';
import { convertUTCToLocalDateTime } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import SideMenu from '../SideMenu/SideMenu';
import { Link } from 'react-router-dom';
import TextEditor from '../../shared/TextEditor';
import MultiSelectComponent from '../../shared/MultiSelectComponent';

export default class FileDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
    }

    /**
     * @description Function to format the customer data for no-edit view
     * @memberof FileDetailsComponent
     */
    getCustomersToShow = () => {
        const { fileDetails, allCustomers, editObj } = this.props;
        if (fileDetails?.customerIds?.length === editObj?.customers?.length) {
            return arrayToString(editObj.customers.map(x => x.label));
        } else {
            return allCustomers?.length ? 'All Customers' : 'N/A';
        }
    }

    render() {
        const { loading, fileDetails, loggerObj, fileErrors, fileUpdateSuccess, onSubmit, handleInputChange, formErrors, editObj, allRegions, allCustomers, descCharLimit,
            descCharCount, releaseCharLimit, isEditMode, toggleEditMode, fileUpdateError } = this.props;

        return (
            <div className="bgGray">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1">
                            <SideMenu loggerObj={loggerObj} />
                        </div>
                        <div className="col-md-11 datalist-style" data-testid="fileDetails">
                            <div className="file-list file-form-wrap">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Link to={{ pathname: "/assets" }} className="btn btn-link px-0 fs-9-rem fw-600 text-dark">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left fw-600" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                            <span className="ms-1">Back to Assets</span>
                                        </Link>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="d-flex align-items-center justify-content-left">
                                            <h1 className="file-upload-header">Asset Details</h1>
                                        </div>
                                    </div>
                                </div>

                                {loading ? <div className="loading-overlay-upload add-surgeon-overlay"><span>{actions.load}</span></div> :
                                    fileDetails ?
                                        <div className="file-details mt-4">
                                            <div className="file-details-container">
                                                <div className="card-body py-0">
                                                    {fileUpdateSuccess ?
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="customer-save-success-msg">
                                                                    <p>{fileListMessages.fileUpdated}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    {fileUpdateError ?
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <ErrorMsgBlockComponent errorObject={fileUpdateError} />
                                                            </div>
                                                        </div>
                                                        : ''}

                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label htmlFor="lastName" className="col-sm-4 col-form-label">Asset Type</label>
                                                                <div className="col-sm-8">
                                                                    <span className="form-control disabled">{fileDetails.assettype ? fileDetails.assettype : 'N/A'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label htmlFor="lastName" className="col-sm-4 col-form-label">File Name</label>
                                                                <div className="col-sm-8">
                                                                    <span className="form-control disabled">{fileDetails.filename ? fileDetails.filename : 'N/A'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label htmlFor="lastName" className="col-sm-4 col-form-label">Version</label>
                                                                <div className="col-sm-8">
                                                                    <span className="form-control disabled">{fileDetails.daversion ? fileDetails.daversion : 'N/A'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label htmlFor="lastName" className="col-sm-4 col-form-label">Part Number</label>
                                                                <div className="col-sm-8">
                                                                    <span className="form-control disabled">{fileDetails.partnumber ? fileDetails.partnumber : 'N/A'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label shrink-label">Date Created</label>
                                                                <div className="col-sm-8">
                                                                    <span className="form-control disabled">{fileDetails.createddate ? convertUTCToLocalDateTime(fileDetails.createddate) : 'N/A'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label shrink-label">Last Updated</label>
                                                                <div className="col-sm-8">
                                                                    <span className="form-control disabled">{fileDetails.updateddate ? convertUTCToLocalDateTime(fileDetails.updateddate) : 'N/A'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label shrink-label">Available Regions{isEditMode ? <span className="red">*</span> : ''}</label>
                                                                <div className="col-sm-8">
                                                                    {!isEditMode ?
                                                                        <span className="form-control disabled">{fileDetails.allregion ? 'All Regions' : fileDetails.regions ? arrayToString(fileDetails.regions) : 'N/A'}</span>
                                                                        :
                                                                        allRegions && allRegions.length ?
                                                                            <MultiSelectComponent
                                                                                id="region"
                                                                                data-testid="region"
                                                                                disabled={!isEditMode}
                                                                                noChipsView={true}
                                                                                hasSelectAll={true}
                                                                                dropdownContent="regions"
                                                                                className={`multiselect-wrap ${formErrors && formErrors.regions ? 'has-error' : ''}`}
                                                                                options={allRegions}
                                                                                selectedValues={editObj.regions}
                                                                                handleSelectChange={(selectedItems) => handleInputChange('regions', selectedItems)}
                                                                                placeholder="Select Region(s)"
                                                                            />
                                                                            : <span className="form-control disabled">{fileDetails.regions ? arrayToString(fileDetails.regions) : 'N/A'}</span>
                                                                    }
                                                                    {formErrors && formErrors.regions ?
                                                                        <div className="error-msg">
                                                                            <p>{formErrors.regions}</p>
                                                                        </div>
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label">Customers{isEditMode ? <span className="red">*</span> : ''}</label>
                                                                <div className="col-sm-8" data-testid="customersToShow">
                                                                    {!isEditMode ?
                                                                        <span className="form-control disabled">{this.getCustomersToShow()}</span>
                                                                        :
                                                                        allCustomers && allCustomers.length ?
                                                                            <MultiSelectComponent
                                                                                id="customer"
                                                                                data-testid="customer"
                                                                                noChipsView={true}
                                                                                hasSelectAll={true}
                                                                                disabled={!isEditMode}
                                                                                dropdownContent="customers"
                                                                                className={`multiselect-wrap ${formErrors && formErrors.customers ? 'has-error' : ''}`}
                                                                                options={allCustomers}
                                                                                selectedValues={editObj.customers}
                                                                                handleSelectChange={(selectedItems) => handleInputChange('customers', selectedItems)}
                                                                                placeholder="Select Customer(s)"
                                                                            />
                                                                            : <span className="form-control disabled">{fileDetails.customers ? this.getCustomersToShow() : 'N/A'}</span>
                                                                    }
                                                                    {formErrors && formErrors.customers ?
                                                                        <div className="error-msg">
                                                                            <p>{formErrors.customers}</p>
                                                                        </div>
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label">Release Notes</label>
                                                                <div className="col-sm-12">
                                                                    {!isEditMode ?
                                                                        <div id="releaseNotes" className="form-control desc-block disabled">
                                                                            {fileDetails.releasenote ? parseHTML(fileDetails.releasenote) : 'N/A'}
                                                                        </div>
                                                                        :
                                                                        <TextEditor
                                                                            id="releaseNotes"
                                                                            readOnly={!isEditMode}
                                                                            data={editObj.releaseNotes ? editObj.releaseNotes : null}
                                                                            data-testid="releaseNotes"
                                                                            className={formErrors && formErrors.releaseNotes ? 'has-error' : ''}
                                                                            charLimit={releaseCharLimit}
                                                                            onChange={(editorObj) => handleInputChange('releaseNotes', editorObj)}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label">Description</label>
                                                                <div className="col-sm-12">
                                                                    {!isEditMode ?
                                                                        <div id="description" className="form-control desc-block disabled">
                                                                            {fileDetails.comments ? parseHTML(fileDetails.comments) : 'N/A'}
                                                                        </div>
                                                                        :
                                                                        <textarea
                                                                            id="description"
                                                                            data-testid="description"
                                                                            disabled={!isEditMode}
                                                                            className={`form-control ${formErrors && formErrors.description ? 'has-error' : ''}`}
                                                                            maxLength={descCharLimit}
                                                                            rows="8"
                                                                            value={editObj.description}
                                                                            onChange={(e) => handleInputChange('description', e.target.value)}
                                                                        />
                                                                    }
                                                                    {isEditMode ?
                                                                        <div className={formErrors && formErrors.description ? "desc-char-count error-msg" : "desc-char-count"}>
                                                                            <p className="m-0 fs-8-rem">{errorMessages.fileUpload.charCount(descCharCount, descCharLimit)}</p>
                                                                        </div>
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="form-group row mt-4">
                                                                <label className="col-sm-4 col-form-label">Status</label>
                                                                <div className="col-sm-8">
                                                                    <div className="device-status-toggle">
                                                                        <div className="fileinputs form-input">
                                                                            <label className="switch">
                                                                                <input onChange={(e) => handleInputChange('status', e.target.checked)} checked={editObj.status} disabled={!isEditMode} type="checkbox" name="file-status" id="file-status" data-testid="fileStatus" />
                                                                                <div className={`slider round ${isEditMode ? '' : 'disabled'}`}>
                                                                                    <span className="on">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
                                                                                            <path d="M7.5 1v7h1V1h-1z" />
                                                                                            <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span className="off">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" className="bi bi-power" viewBox="0 0 16 16">
                                                                                            <path d="M7.5 1v7h1V1h-1z" />
                                                                                            <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {isEditMode ?
                                                                        <div className="hint-block text-muted na-help-text d-flex align-items-start mt-1">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill me-1" viewBox="0 0 16 16">
                                                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                                                            </svg>
                                                                            {parseHTML(fileListMessages.statusWarning(fileDetails.status))}
                                                                        </div>
                                                                        : ''}
                                                                    {formErrors && formErrors.status ?
                                                                        <div className="disclaimer-error-msg">
                                                                            <p>{formErrors.status}</p>
                                                                        </div>
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {isEditMode ?
                                                            <div className="col-sm-6">
                                                                <div className="btns-style text-end">
                                                                    <button className="upload-selected btn btn-primary" id="fileUpdate" data-testid="updateBtn" onClick={onSubmit} disabled={loading}>Update</button>
                                                                    <button className="upload-selected btn btn-secondary ms-2" id="fileUpdateCancel" data-testid="updateCancelBtn" onClick={toggleEditMode} disabled={loading}>Cancel</button>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-sm-6">
                                                                <div className="btns-style text-end">
                                                                    <button className="upload-selected btn btn-primary" id="editToggle" data-testid="toggleEditBtn" onClick={toggleEditMode} disabled={loading}>Edit</button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="customer-fields">
                                            <div className="case-details-container">
                                                <div className="card-body">
                                                    {fileErrors ? <ErrorMsgBlockComponent errorObject={fileErrors} /> : <ErrorMsgBlockComponent noData={true} />}
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
