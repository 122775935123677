import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardComponent from './Dashboard';
import { getUserRoleType } from '../../services/aws/aws-services';
import { loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
let startDate;
class DashboardContainer extends Component {
	constructor() {
		super()
		const loggedInUserType = getUserRoleType();
		let eventName = "Dashboard";
		if (loggedInUserType === 'surgeon') {
			eventName = loggerEventName.surgeondashboard
		} else if (loggedInUserType === 'rar') {
			eventName = loggerEventName.rarDashboard
		} else if (loggedInUserType === 'admin') {
			eventName = loggerEventName.adminDashboard
		} else if (loggedInUserType === 'careteam') {
			eventName = loggerEventName.careteamDashboard
		}
		this.state = {
			eventName: eventName,
			loading: false,
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": eventName,
				"StartDate": new Date()
			}
		}
	}

	componentDidMount() {
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}

	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof CaseListContainer
	 */
	applicationLogger = (key) => {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": this.state.eventName,
			"Content": {
				"TimeSpent": timeSinceLoad
			}
		}
		logger(loggerObj);
	}
	
	render() {
		return (
			<DashboardComponent 
				loading={this.state.loading} 
				downTimeData={this.state.downTimeData} 
				loggerObj={this.state.loggerObj} 
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(DashboardContainer);
