import parse from 'html-react-parser';
import React from 'react';
import { checkLocalStorage } from '../services/storage/storage-service';

/**
 * @description Function to check if a number fall in a range
 * @param num Number to be checked
 * @param min Min value in the range
 * @param max Max value in the range
 * @returns Boolean. True if num lies between min and max
 */
export const between = (num, min, max) => {
	return num >= min && num < max;
}

/**
 * @description Function to check if a number fall in a hard range 
 * @description (at most, equal to both the extremes)
 * @param num Number to be checked
 * @param min Min value in the range
 * @param max Max value in the range
 * @returns Boolean. True if num lies between min and max
 */
export const hardBetween = (num, min, max) => {
	return num >= min && num <= max;
}

/**
 * @description Function to format and breakdown date objects
 * @param date JS Date object
 * @returns Object with date time attributes broken down
 */
export const breakDownDateFormat = (date) => {
	let newDate = date ? new Date(date) : new Date();
	let d = newDate.getDate();
	let m = newDate.getMonth() + 1;
	let yyyy = newDate.getFullYear();
	let dd = d > 9 ? d.toString() : '0' + d.toString();
	let mm = m > 9 ? m.toString() : '0' + m.toString();
	let hr = newDate.getHours() > 9 ? newDate.getHours().toString() : '0' + newDate.getHours().toString();
	let min = newDate.getMinutes() > 9 ? newDate.getMinutes().toString() : '0' + newDate.getMinutes().toString();
	let sec = newDate.getSeconds() > 9 ? newDate.getSeconds().toString() : '0' + newDate.getSeconds().toString();

	return {
		date: dd,
		month: mm,
		fullYear: yyyy,
		hour: hr,
		min: min,
		sec: sec
	}
}

/**
 * @description Function to format date time
 * @param date Date to be formatted
 * @returns Formatted date
 */
export const formatDateTime = (date) => {
	let correctInput = date;
	let newDate = new Date(correctInput);
	let convDateTime = '';
	if (!isNaN(newDate.getTime())) {
		let d = newDate.getUTCDate();
		let m = newDate.getUTCMonth() + 1;
		let yyyy = newDate.getUTCFullYear();
		let dd = d > 9 ? d.toString() : '0' + d.toString();
		let mm = m > 9 ? m.toString() : '0' + m.toString();
		let hr = newDate.getUTCHours() > 9 ? newDate.getUTCHours().toString() : '0' + newDate.getUTCHours().toString();
		let min = newDate.getUTCMinutes() > 9 ? newDate.getUTCMinutes().toString() : '0' + newDate.getUTCMinutes().toString();
		let sec = newDate.getUTCSeconds() > 9 ? newDate.getUTCSeconds().toString() : '0' + newDate.getUTCSeconds().toString();
		convDateTime = yyyy + '-' + mm + '-' + dd + ' ' + hr + ':' + min + ':' + sec;
	}
	return convDateTime;
}

/**
 * @description Function to format date time for CSV filename
 * @returns Formatted date
 */
export const formatDateTimeForCSV = () => {
	let formattedDateObj = breakDownDateFormat();
	const { date, month, fullYear, hour, min, sec } = formattedDateObj;
	return `${month}${date}${fullYear}_${hour}${min}${sec}`;
}

/**
 * @description Function to format date
 * @param dateObj Date to be formatted
 * @returns Formatted date
 */
export const formatDate = (dateObj) => {
	let formattedDateObj = breakDownDateFormat(dateObj);
	const { date, month, fullYear } = formattedDateObj;

	return `${fullYear}-${month}-${date}`;
}

/**
 * @description Function to format date to string
 * @param date Date to be formatted
 * @returns Formatted date
 */
export const formatDateString = (date) => {
	const dateString = new Date(date).toDateString();
	const dateArray = dateString.split(' ');
	var stringValue = '';
	if (dateArray.length) {
		stringValue += `${dateArray[2]} ${dateArray[1]} ${dateArray[3]}`
	}

	return stringValue;
}

/**
 * @description Function to convert UTC to Local time 
 * @param dateObj Date to be formatted
 * @returns Formatted date
 */
export const convertUTCToLocalDateTime = (dateObj) => {
	let formattedDateObj = breakDownDateFormat(dateObj);
	const { date, month, fullYear, hour, min, sec } = formattedDateObj;

	return `${fullYear}-${month}-${date} ${hour}:${min}:${sec}`;
}

/**
 * @description Function to convert date to date-time input format (upto minute)
 * @param dateObj Date to be formatted
 */
export const getTodaysDateTime = (dateObj) => {
	let formattedDateObj = breakDownDateFormat(dateObj);
	const { date, month, fullYear, hour, min } = formattedDateObj;

	return `${fullYear}-${month}-${date} ${hour}:${min}`;
}

/**
 * @description Function to round up decimal values as whole numbers
 * @param num Float value to be rounded
 * @returns Whole number value rounded off
 */
export const wholeNumberRoundOff = (num, isGraph = false) => {
	if (isNaN(parseFloat(num))) {
		return isGraph ? null : 'N/A';
	}

	return Math.round(num);
}

/**
 * @description Function to round up up to certain decimal places
 * @param num Float value to be rounded
 * @returns Floating point value rounded off
 */
export const customToFixed = (num, decimalPlcaes) => {
	const floatNum = parseFloat(num);
	if (isNaN(floatNum)) {
		return 0;
	}

	return parseFloat(floatNum.toFixed(decimalPlcaes));
}

/**
 * @description Function to check if a number is negative or not
 * @param num Float value to be checked
 * @returns Boolean (true if negative, false if not)
 */
export const isNegative = (num) => {
	if (isNaN(parseFloat(num))) {
		return false;
	}

	let sign = Math.sign(num);
	switch (sign) {
		case -1:
			return true;
		case 0:
		case -0:
			return 1 / sign === -Infinity;
		default:
			return false;
	}
}

/**
 * @description Function to parse HTML string to DOM elements
 * @param str String in question
 * @returns String value to be displayed
 */
export const parseHTML = (htmlString) => {
	return parse(htmlString && typeof htmlString === 'string' ? htmlString : '');
}

/**
 * @description Function to disintegrate an array into a string
 * @param array array in question
 * @returns String value to be displayed
 */
export const arrayToString = (array, delimiter) => {
	return array && Array.isArray(array) ? array.join(delimiter ? delimiter : ', ') : array;
}

/**
 * @description Function to convert HTML to text string
 * @param html HTML in question
 * @returns String value to be displayed
 */
export const convertToPlainText = (html) => {
	var tempDivElement = document.createElement("div"); // Create a new div element	
	tempDivElement.innerHTML = html; // Set the HTML content with the given value
	
	return tempDivElement.textContent || tempDivElement.innerText || ""; // Retrieve and return the text property of the element
}

/**
 * @description Function to ensure positive number inputs
 * @param e Keydown event
 * @returns Boolean
 */
export const isPositiveNumber = (e) => {
	// 48-57 => number row numbers, 96-105 => numpad numbers
	// 8 => backspace, 110 => numpad decimal point, 37-40 => arrow keys
	// 190 => decimal point, 46 => delete, 13 => enter
	// 86 => v, 88 => x, 67 => c
	if (!((e.keyCode > 95 && e.keyCode < 106)
		|| (e.keyCode > 47 && e.keyCode < 58)
		|| (e.keyCode > 36 && e.keyCode < 41)
		|| e.keyCode === 8 || e.keyCode === 110
		|| e.keyCode === 190 || e.keyCode === 46
		|| (e.ctrlKey && e.keyCode === 67)
		|| (e.ctrlKey && e.keyCode === 86)
		|| (e.ctrlKey && e.keyCode === 88)
		|| e.keyCode === 13)) {
		return false;
	}

	return true;
}

/**
 * @description Function to convert string to title case
 * @param str String to be converted
 * @returns Converted string
 */
export const toTitleCase = (str) => {
	if (!str || !str.toString().trim()) {
		return 'N/A';
	}
	return str.replace(
		/\w\S*/g,
		(txt) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
}

/**
 * @description Function to get concatenated name if availabled
 * @param firstName First name
 * @param lastName Last name
 * @param midName Middle name
 * @returns Converted string
 */
export const getConcatenatedName = (firstName, lastName, midName = '') => {
	if (!firstName && !lastName && !midName) {
		return 'N/A';
	}

	return `${firstName ? firstName : ''} ${midName ? midName : ''} ${lastName ? lastName : ''}`;
}

/**
 * @description Function to get N/A help text
 * @returns JSX template with said help text
 */
export const NAHelpText = () => {
	return (
		<div className="my-2 na-help-text">
			<p className="m-0">*N/A - Not Available</p>
		</div>
	);
}

/**
 * @description Function to get if the user is logged in
 * @returns Boolean true if yes, false if not
 */
export const isAuthenticated = () => {
	return checkLocalStorage('cognito_data');
}

/**
 * @description Function to get if this is the user's first login
 * @returns Boolean true if yes, false if not
 */
export const isFirstLogin = () => {
	return checkLocalStorage('isFirstLogin');
}

/**
 * @description Function to return the image src as base64 encoded string
 * @returns String
 */
export const getImgSrc = (img) => {
	var defaultExport = require(`../assets/images/${img}`);
	return defaultExport.default ? defaultExport.default : defaultExport;
}

/**
 * @description Function to define template for loading animation
 * @returns Template for loading animation
 */
export const getLoadingTemplate = () => {
	return <div className="loading-img mb-3"><img className="" src={getImgSrc('loading.gif')} alt="loading" /></div>
}

/**
 * @description Function to check for whitespace at the start
 * @param value Input value
 * @returns Regex test result (Boolean)
 */
export const validateWhiteSpace = (value) => {
	return new RegExp(/^\s/g).test(value);
}

/**
 * @description Function to convert bytes to KB or MB
 * @param value Input value
 * @returns Converted value
 */
export const convertFileSize = (value, unit) => {
	if (!value) {
		return null;
	}

	switch (unit && unit.toLowerCase()) {
		case 'kb':
			return Math.ceil(value/1024);
		case 'mb':
			return Math.ceil(value/(1024*1024));
	
		default:
			return Math.ceil(value/1024);
	}
	
}