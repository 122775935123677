import React, { Component } from 'react';
import { connect } from 'react-redux';
import FilesListComponent from './FilesList';
import { loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
let startDate;
class FilesListContainer extends Component {
	constructor() {
		super()
		this.state = {
			refreshFileList: '',
			loading: false,
			searchParams: '',
			searchSubmit: '',
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.filesList,
				"StartDate": new Date()
			}
		}
	}

	componentDidMount() {
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}

	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof FilesListContainer
	 */
	applicationLogger(key) {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.filesList,
			"Content": {
				"TimeSpent": timeSinceLoad
			}
		}
		logger(loggerObj);
	}

	/** 
	 * 	@description Function to handle search submit
	 *  @param searchParams The search object
	 *  @memberof FilesListContainer
	 */
	handleSearchSubmit = (params) => {
		this.setState({ searchParams: params, searchSubmit: true, refreshFileList: '' });
	}

	/** 
	 *  @description Function to handle search reset
	 *  @memberof FilesListContainer
	 */
	handleReset = () => {
		this.setState({ searchParams: '', searchSubmit: '', refreshFileList: '' });
	}
	
	/** 
	 *  @description Function to handle file status update
	 *  @memberof FilesListContainer
	 */
	fileStatusUpdated = (status) => {
		this.setState({ refreshFileList: status, searchSubmit: true });
	}

	/** 
	 *  @description Function to toggle loader from sub components
	 *  @memberof FilesListContainer
	 */
	toggleLoader = (status) => {
		this.setState({ loading: status, refreshFileList: false, searchSubmit: false });
	}


	render() {
		const { loggerObj, refreshFileList, searchParams, searchSubmit } = this.state;
		return (
			<FilesListComponent
				loggerObj={loggerObj}
				searchParams={searchParams}
				searchSubmit={searchSubmit}
				refreshFileList={refreshFileList}
				toggleLoader={this.toggleLoader}
				fileStatusUpdated={this.fileStatusUpdated}
				handleSearchSubmit={this.handleSearchSubmit}
				handleReset={this.handleReset}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}


export default connect(mapStateToProps)(FilesListContainer);
