import React from "react";
import UserDetailsContainer from '../UserDetails';
import RegionDetailsContainer from '../RegionsDetails';
import { actions, errorMessages, fileListMessages, regionMessages, loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes, userMessages } from '../../helpers/messages';
import { useTable, useSortBy, useFilters, useExpanded, usePagination } from "react-table";
import { procedureMetadataListService, loggerGetAPI, getAllUsersList, getAllRegions } from "../../services/java/java-services";
import { downloadObject, newS3, logoutService } from "../../services/aws/aws-services";
import environment from "../../config/environment";
import CustomModal from "../../shared/CustomModal";
import AdminPasswordResetContainer from "../AdminPasswordReset";
import ErrorMsgBlockComponent from "../Error/ErrorMessageBlock";
import UserStatusContainer from "../UserList/UserStatus";
import RegionStatusContainer from "../RegionList/RegionStatus"
import FileStatusContainer from "../FilesList/FileStatus";
import { Link } from "react-router-dom";
import { logger } from "../../services/logger/logger-service";

// Define a default UI for filtering
/* istanbul ignore next  */
function DefaultColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
}) {
	const count = preFilteredRows.length;
	return (
		<input
			value={filterValue || ""}
			onChange={(e) => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${count} records...`}
		/>
	);
}

/** @description function to create UI for Data list
 *  @param  {}
 */
function Table({
	columns,
	data,
	fetchData,
	actionErrorObj,
	loading,
	downloading,
	downloadErrorMsg,
	downloadS3File,
	pageCount: controlledPageCount,
	defaultPageSize,
	totalCount,
	listType,
	showPagination,
	disableColmSort,
	successMsg,
	getHiddenColumns,
	toggleUserDetails,
	selectedUser,
	viewUserDetails,
	toggleResetPassword,
	resetUserPassword,
	userUpdated,
	searchString,
	searchParams,
	errorObj,
	filterObj,
	viewRegionDetails,
	selectedRegion,
	toggleRegionDetails,
	regionUpdated,

}) {
	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		pageCount,
		page,
		state: { pageIndex, pageSize, sortBy },
		gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState: { pageIndex: 0, pageSize: defaultPageSize, sortBy: [], hiddenColumns: getHiddenColumns ? getHiddenColumns : [] },
			manualPagination: true,
			autoResetPage: false,
			manualSortBy: true,
			disableFilters: false,
			disableSortBy: disableColmSort ? disableColmSort : false,
			pageCount: controlledPageCount,
			totalCount: 0,
		},
		useFilters,
		useSortBy,
		useExpanded,
		usePagination
	);

	const showElementsArray = listType === 'audit-logs' ? [50, 100] : [10, 20, 30, 40, 50];
	// Listen for changes in pagination and use the state to fetch our new data
	React.useEffect(() => {
		fetchData({ pageIndex, pageSize, sortBy });
	}, [fetchData, downloadS3File, pageIndex, pageSize, sortBy]);

	React.useMemo(() => {
		return gotoPage(0);
		// eslint-disable-next-line
	}, [searchString, filterObj]);
	// Render the UI for your table
	/* istanbul ignore next  */
	return (
		<>
			{errorObj ? <ErrorMsgBlockComponent errorObject={errorObj} /> :
				<>
					{(data && data.length) || loading ?
						<>
							{downloadErrorMsg ? (
								<div className="file-list-error-msg">
									<span>{downloadErrorMsg}</span>
								</div>
							) : null}

							{downloading ? (
								<div className="loading-overlay-upload download-overlay">
									<span>{actions.download}</span>
								</div>
							) : null}
							{successMsg === 'userStatusInactive' ? (
								<div className="proms-success-msg">
									<p>{userMessages.statusChanged()}</p>
								</div>
							) : null}
							{['fileStatusInactive', 'fileStatusActive'].indexOf(successMsg) > -1 ? (
								<div className="proms-success-msg">
									<p>{fileListMessages.statusChanged(successMsg === 'fileStatusActive')}</p>
								</div>
							) : null}
							{['regionStatusInactive', 'regionStatusActive'].indexOf(successMsg) > -1 ? (
								<div className="proms-success-msg">
									<p>{regionMessages.statusChanged(successMsg === 'regionStatusActive')}</p>
								</div>
							) : null}
							{actionErrorObj ? <ErrorMsgBlockComponent errorObject={actionErrorObj} /> : null}
							<div className="table-contents">
								<table
									{...getTableProps()}
									id={listType}
									className={
										listType
											? listType + " table-bordered table-hover "
											: "table-bordered table-hover"
									}>
									<thead className="rt-thead">
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													// Add the sorting props to control sorting. For this example
													// we can add them into the header props
													<th
														{...column.getHeaderProps(column.getSortByToggleProps())}
														title=""
													>
														{column.render("Header")}
														{/* Add a sort direction indicator */}
														<span>
															{column.isSorted ? (
																column.isSortedDesc ? (
																	<svg
																		className="bi bi-chevron-down"
																		width="1.2em"
																		height="1.2em"
																		viewBox="0 0 16 16"
																		fill="currentColor"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			fillRule="evenodd"
																			d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
																			clipRule="evenodd"
																		/>
																	</svg>
																) : (
																	<svg
																		className="bi bi-chevron-up"
																		width="1.2em"
																		height="1.2em"
																		viewBox="0 0 16 16"
																		fill="currentColor"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			fillRule="evenodd"
																			d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
																			clipRule="evenodd"
																		/>
																	</svg>
																)
															) : (
																""
															)}
														</span>
														<div>
															{column.canFilter ? column.render("Filter") : null}
														</div>
													</th>
												))}
											</tr>
										))}
									</thead>
									{loading ? (
										<tbody className="rt-tbody tr-refresh">
											{[...Array(page && page.length && page.length < pageSize ? page.length : 10)].map((e, i) => (
												<tr key={i}>
													{[...Array(headerGroups.length ? headerGroups[0].headers.length : 1)].map((f, j) => (
														<td className="loading-cell" key={j}>
															<span></span>
														</td>
													))}
												</tr>
											))}
											<tr>
												<td colSpan="10000">{actions.load}</td>
											</tr>
										</tbody>
									) : (
										<tbody {...getTableBodyProps()} className="rt-tbody">
											{page.map((row, i) => {
												prepareRow(row);
												return (
													<React.Fragment key={i}>
														<tr {...row.getRowProps()} className="data-row">
															{row.cells.map((cell) => {
																return (
																	<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
																);
															})}
														</tr>
													</React.Fragment>
												);
											})}
											<tr className="result-count">
												<td colSpan="10000">Showing {page.length} of {totalCount} results</td>
											</tr>
										</tbody>
									)}
								</table>

								{totalCount > 0 && showPagination === true ? (
									<div className="pagination">
										<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
											{"<<"}
										</button>{" "}
										<button onClick={() => previousPage()} disabled={!canPreviousPage}>
											{"<"}
										</button>{" "}
										<button onClick={() => nextPage()} disabled={!canNextPage}>
											{">"}
										</button>{" "}
										<button
											onClick={() => gotoPage(pageCount - 1)}
											disabled={!canNextPage}
										>
											{">>"}
										</button>{" "}
										&nbsp;
										<span className="page-display">
											Page{" "}
											<strong>
												{pageIndex >= pageOptions.length ? pageOptions.length : pageIndex + 1} of {pageOptions.length}
											</strong>{" "}
											&nbsp;
										</span>
										<select
											value={pageSize}
											onChange={(e) => {
												setPageSize(Number(e.target.value));
											}}
											className="form-select"
										>
											{showElementsArray.map((rowsPerPage) => (
												<option key={rowsPerPage} value={rowsPerPage}>
													Show {rowsPerPage}
												</option>
											))}
										</select>
									</div>
								) : null}
							</div>
						</>
						: <ErrorMsgBlockComponent noData={true} />
					}

					{/* Modal to view user details */}
					<CustomModal
						id="user-details-modal"
						show={toggleUserDetails}
						title="User Details"
						isComponent={true}
						dialogClassName="user-details-dialog"
						closeAction={() => viewUserDetails()}>
						<UserDetailsContainer
							userName={selectedUser && selectedUser.userName ? selectedUser.userName : ''}
							userUpdated={status => userUpdated(status, { pageIndex, pageSize, sortBy })}
							handleCancel={() => viewUserDetails()}
						/>
					</CustomModal>

					{/* Modal to reset user password */}
					<CustomModal
						id="reset-password-modal"
						show={toggleResetPassword}
						title="Reset Password"
						isComponent={true}
						hideClose={selectedUser && selectedUser.isStatusChange}
						dialogClassName="reset-password-dialog"
						closeAction={() => resetUserPassword(selectedUser)}>
						<AdminPasswordResetContainer user={selectedUser || ''} handleCancel={() => resetUserPassword()} />
					</CustomModal>

					{/* Modal to view region details */}
					<CustomModal
						id="region-details-modal"
						show={toggleRegionDetails}
						title="Region Details"
						isComponent={true}
						dialogClassName="region-details-dialog"
						closeAction={() => viewRegionDetails()}>
						<RegionDetailsContainer
							regionDetails={selectedRegion}
							regionUpdated={status => regionUpdated(status, { pageIndex, pageSize, sortBy })}
							handleCancel={() => viewRegionDetails()}
						/>
					</CustomModal>
				</>}
		</>
	);
}

/**
 * @description Function to download file from S3 bucket
 * @param  {}
 */
/* istanbul ignore next  */
const downloadFile = ({ filepath, filename }) => {
	downloadObject(filepath, function (err, res) {
		var loggerObj;
		if (err) {
			loggerObj = {
				"EventOutcome": loggerEventOutcome.failure,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.fileDownload,
				"Content": {
					"Data": err.message || loggerEventMessage.fileDownloadFailed(filename)
				}
			}
			logger(loggerObj);

			if (err.message && err.message === "Missing credentials in config") {
				logoutService((error, result) => { });
			}
		} else {
			let element = document.createElement("iframe");
			element.src = res;
			document.body.appendChild(element);
			element.click();
			element.style.display = "none";
			loggerObj = {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.fileDownload,
				"Content": {
					"Data": loggerEventMessage.fileDownloaded(filename)
				}
			}
			logger(loggerObj);
		}
	});
};

/**
 * @description Function to verify file from S3 bucket
 * @param  {}
 */
/* istanbul ignore next  */
const verifyFileNameInS3 = async ({ filepath, filename }, callback) => {
	let bucketName = environment.s3BucketName;
	let s3 = await newS3(bucketName);
	let mediaKey = filepath;
	let param = {
		Key: mediaKey,
	};
	await s3.headObject(param, (err, res) => {
		if (err) {
			callback(err, null);
		} else {
			callback(null, res);
		}
	});
};

function DataTable(props) {
	let getColumnsFromProps = props.columns ? props.columns : ""; // get columns and datalist type as props
	// eslint-disable-next-line
	let columnsList = [];
	// add the button based on the columns input
	getColumnsFromProps &&
		getColumnsFromProps.forEach((element) => {
			/* istanbul ignore next  */
			if (element.download) {
				// add download button
				let download = {
					Header: element.Header,
					Cell: ({ row }) => (
						<span className="action-wrap" title="Download File" onClick={() => downloadS3File(row.original)}>
							<svg width="1.5em" height="1.5em" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
								<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
								<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
							</svg>
						</span>
					),
				};
				columnsList.push(download);
			} else if (element.fileStatus) {
				let fileStatus = {
					Header: element.Header,
					Cell: ({ row }) => (
						<FileStatusContainer file={row.original} toggleLoader={props.toggleLoader} updateError={fileStatusUpdateFailed} fileStatusUpdated={fileStatusUpdated} />
					),
				};
				columnsList.push(fileStatus);
			} else if (element.fileAction) {
				let filesAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div className="files-action">
							<span className="action-wrap" title="View Details">
								<Link id="file-details" to={{ pathname: "/asset-details", state: { fileID: row?.original?.id } }}>
									<svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye" xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
										<path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
									</svg>
								</Link>
							</span>
							{row.original && row.original.status ?
								<span className="action-wrap" title="Download File" onClick={() => downloadS3File(row.original)}>
									<svg width="1.5em" height="1.5em" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
										<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
										<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
									</svg>
								</span>
								: ''}
						</div>
					),
				};
				columnsList.push(filesAction);
			} else if (element.Filter) {
				// add column filter
				let filter = {
					Header: element.Header,
					accessor: element.accessor,
					Filter: DefaultColumnFilter,
				};
				columnsList.push(filter);
			} else if (element.userStatus) {
				let userStatus = {
					Header: element.Header,
					Cell: ({ row }) => (
						<UserStatusContainer user={row.original} toggleLoader={props.toggleLoader} updateError={userStatusUpdateFailed} userStatusUpdated={userStatusUpdated} />
					),
				};
				columnsList.push(userStatus);
			} else if (element.regionStatus) {
				let regionStatus = {
					Header: element.Header,
					Cell: ({ row }) => (
						<RegionStatusContainer region={row.original} toggleLoader={props.toggleLoader} updateError={regionStatusUpdateFailed} regionStatusUpdated={regionStatusUpdated} />
					),
				};
				columnsList.push(regionStatus);
			} else if (element.userAction) {
				let userAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div >
							<svg onClick={() => viewUserDetails(row.original)} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye cursor-pointer" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
								<path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
							</svg>
							<svg onClick={() => resetUserPassword(row.original)} width="1.5em" height="1.5em" className="bi bi-unlock delete-icon cursor-pointer" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
								<path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z" />
							</svg>
						</div>
					),
				};
				columnsList.push(userAction);
			} else if (element.regionAction) {
				let regionAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div >
							<svg onClick={() => viewRegionDetails(row.original)} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye cursor-pointer" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
								<path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
							</svg>
						</div>
					),
				};
				columnsList.push(regionAction);
			} else {
				columnsList.push(element);
			}
		});

	const columns = React.useMemo(() => columnsList, [columnsList]);
	// We'll start our table without any data
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [successMsg, setSuccessMsg] = React.useState('');
	const [downloading, setDownloading] = React.useState(false);
	const [downloadErrorMsg, setDownloadErrorMsg] = React.useState("");
	const [pageCount, setPageCount] = React.useState(0);
	const [totalCount, setTotalCount] = React.useState(0);
	const [toggleUserDetails, setToggleUserDetails] = React.useState(false);
	const [toggleResetPassword, setToggleResetPassword] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState([]);
	const fetchIdRef = React.useRef(0);
	const [errorObj, setErrorObj] = React.useState(null);
	const [actionErrorObj, setActionErrorObj] = React.useState(null);
	const [selectedRegion, setSelectedRegion] = React.useState([]);
	const [toggleRegionDetails, setToggleRegionDetails] = React.useState(false);


	/* istanbul ignore next  */
	// eslint-disable-next-line
	const downloadS3File = React.useCallback(({ filepath, filename }) => {
		setDownloading(true);
		setDownloadErrorMsg("");
		verifyFileNameInS3({ filepath, filename }, function (err, res) {
			if (err) {
				let errorMsg = err.message || err.errorMessage || loggerEventMessage.fileDownloadFailed(filename);
				switch (err.code) {
					case "NotFound":
						errorMsg = errorMessages.fileDownload.notFound(filename);
						break;
					case "Forbidden":
						errorMsg = errorMessages.fileDownload.forbidden(filename);
						break;

					default:
						break;
				}

				setDownloading(false);
				setDownloadErrorMsg(errorMsg);
				let loggerObj = {
					"EventOutcome": loggerEventOutcome.failure,
					"EventType": loggerEventTypes.read,
					"EventName": loggerEventName.fileDownload,
					"Content": {
						"Data": errorMsg
					}
				}
				logger(loggerObj);
			} else {
				setDownloading(false);
				setDownloadErrorMsg(false);
				downloadFile({ filepath, filename });
			}
		});
	});

	/**
	 * @description Function to get audit logs from API
	 * @param params Object with page number, sort order, sort by column and page size
	*/
	/* istanbul ignore next  */
	const getAuditLogs = (params) => {
		var urlParams = {
			pageno: params.pageIndex + 1,
			sortorder: params.sortOrder || 'desc',
			column: params.columnSortBy || 'EventDate',
			limit: params.pageSize || 100,
			eventSource: 'damp'
		}

		for (const key in props.filterObj) {
			if (props.filterObj.hasOwnProperty(key) && (props.filterObj[key] || props.filterObj[key] === 0)) {
				urlParams[key] = props.filterObj[key];
			}
		}

		setLoading(true);
		loggerGetAPI(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.logger;
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / params.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setLoading(false);
					props.totalCount(getTotalCount);
				}
			}
		);
	}
	/**
	 * @description Function to show region details in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const viewRegionDetails = React.useCallback((region = null) => {
		if (region) {
			let regionDetails = {
				id: region.id ? region.id : '',
				region: region.region ? region.region : '',
				description: region.description ? region.description : '',

			}
			setSelectedRegion(regionDetails);
		}

		toggleRegionDetails ? setToggleRegionDetails(false) : setToggleRegionDetails(true);
	});

	/**
* @description Function to show region status change failed
* @param  {}
*/
	/* istanbul ignore next  */
	const regionStatusUpdateFailed = (error) => {
		setSuccessMsg('');
		setActionErrorObj(error);
	}

	/**
	 * @description Function to show region status change succeeded
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const regionStatusUpdated = (region) => {
		setActionErrorObj(null);
		props.regionStatusUpdated(true);
		setSuccessMsg(region && region.status ? 'regionStatusActive' : 'regionStatusInactive');
		// setTimeout(() => {
		// 	setSuccessMsg('');
		// }, 10000);
	}

	/**
	 * @description Function to show user details in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const viewUserDetails = React.useCallback((user = null) => {
		if (user) {
			let userDetails = {
				email: user.email ? user.email : '',
				name: user.name ? user.name : '',
				userName: user.userName ? user.userName : '',
				group: user.group ? user.group : ''
			}
			setSelectedUser(userDetails);
		}

		toggleUserDetails ? setToggleUserDetails(false) : setToggleUserDetails(true);
	});

	/**
	 * @description Function to show user status change failed
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const userStatusUpdateFailed = (error) => {
		setSuccessMsg('');
		setActionErrorObj(error);
	}

	/**
	 * @description Function to show user status change succeeded
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const userStatusUpdated = (user) => {
		setActionErrorObj(null);
		props.userStatusUpdated(true);
		if (user.enabled) {
			setSelectedUser(user);
			setToggleResetPassword(true);
		} else {
			setSuccessMsg('userStatusInactive');
		}
		// setTimeout(() => {
		// 	setSuccessMsg('');
		// }, 10000);
	}

	/**
	 * @description Function to show user details in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const userUpdated = React.useCallback((status, params) => {
		if (status) {
			getAllUsers(params);
		}
	});

	/**
	 * @description Function to show region details in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const regionUpdated = React.useCallback((status, params) => {
		if (status) {
			getAllRegionsList(params);
		}
	});

	/**
	 * @description Function to show user details in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const resetUserPassword = React.useCallback((user = null) => {
		if (user) {
			let userDetails = {
				email: user.email ? user.email : '',
				name: user.name ? user.name : '',
				userName: user.userName ? user.userName : '',
				group: user.group ? user.group : ''
			}
			setSelectedUser(userDetails);
		}
		toggleResetPassword ? setToggleResetPassword(false) : setToggleResetPassword(true);
	});

	/**
	 * @description Function to get customers associated with a surgeon
	 * @param params Object with page number, sort order, sort by column and page size
	*/
	/* istanbul ignore next  */
	const getAllUsers = (params) => {
		var urlParams = {
			sortorder: params.sortOrder || 'asc',
			column: params.columnSortBy || 'name',
			pageno: params.pageIndex + 1,
			limit: params.pageSize || 10
		}
		if (props.searchString) {
			urlParams['searchString'] = props.searchString;
		}

		setLoading(true);
		getAllUsersList(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.userList;
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / params.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setActionErrorObj(null);
					setLoading(false);
				}
			}
		);
	}

	/**
	 * @description Function to get Region List 
	 * @param params {}
	*/
	/* istanbul ignore next  */
	const getAllRegionsList = (params) => {
		var urlParams = {
			sortorder: params.sortOrder || 'asc',
			column: params.columnSortBy || 'region',
		}
		if (props.searchString) {
			urlParams['searchString'] = props.searchString;
		}

		setLoading(true);
		getAllRegions(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.regionlist;
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					// setPageCount(Math.ceil(getTotalCount / params.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setActionErrorObj(null);
					setLoading(false);
				}
			}
		);
	}

	/**
	 * @description Function to get the DAs uploaded
	 * @param params Object with page number, sort order, sort by column and page size
	*/
	/* istanbul ignore next  */
	const getDAList = (params) => {
		var urlParams = {
			sortorder: params.sortOrder || 'desc',
			column: params.columnSortBy ? params.columnSortBy.toLowerCase() : 'createddate',
			pageno: params.pageIndex + 1,
			limit: params.pageSize || 10
		}
		for (const key in props.filterObj) {
			if (props.filterObj.hasOwnProperty(key) && (props.filterObj[key] || props.filterObj[key] === 0)) {
				urlParams[key] = props.filterObj[key];
			}
		}
		procedureMetadataListService(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data && result.data.damp_metadata ? result.data.damp_metadata : [];
					let getTotalCount = result.data && result.data.damp_metadata && result.data.response_metadata.total ? result.data.response_metadata.total : 0;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / params.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setLoading(false);
				}
			}
		);
	}

	/**
	 * @description Function to show file status change failed
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const fileStatusUpdateFailed = (error) => {
		setSuccessMsg('');
		setActionErrorObj(error);
	}

	/**
	 * @description Function to show file status change succeeded
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const fileStatusUpdated = (file) => {
		setActionErrorObj(null);
		props.fileStatusUpdated(true);
		setSuccessMsg(file && file.status ? 'fileStatusActive' : 'fileStatusInactive');
		// setTimeout(() => {
		// 	setSuccessMsg('');
		// }, 10000);
	}

	const fetchData = React.useCallback(
		({ pageSize, pageIndex, sortBy }) => {
			let columnSortBy = ""; // default
			let sortOrder = ""; // default
			let keyMapping = {};
			/* istanbul ignore next  */
			if (props.listType === "file-list") {
				columnSortBy = "createddate"; // default
				sortOrder = "desc"; // default
			} else if (props.listType === "audit-logs") {
				columnSortBy = "EventDate"; // default
				sortOrder = "desc"; // defaul
			} else if (props.listType === "region-list") {
				columnSortBy = "region"; // default
				sortOrder = "asc"; // defaul
			} else if (props.listType === "user-list") {
				columnSortBy = "name"; // default
				sortOrder = "asc"; // default
				keyMapping = {
					userName: "username",
					name: "name",
					email: "email",
					group: "group"
				};
			}
			/* istanbul ignore next  */
			if (sortBy[0]) {
				columnSortBy = Object.keys(keyMapping).length ? keyMapping[sortBy[0].id] : sortBy[0].id;
				sortOrder = sortBy[0].desc ? "desc" : "asc";
			}

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Set the loading state
			/* istanbul ignore next  */

			if (props.refreshDataList === false) {
				setLoading(false);
				return true;
			}
			setLoading(true);
			setDownloadErrorMsg("");

			// Only update the data if this is the latest fetch
			/* istanbul ignore next  */
			if (fetchId === fetchIdRef.current) {
				if (props.listType === "file-list" && (props.refreshDataList === '' || props.refreshDataList === true)) {
					getDAList({ pageIndex, columnSortBy, sortOrder, pageSize })
				} else if (props.listType === "audit-logs") {
					getAuditLogs({ pageIndex, columnSortBy, sortOrder, pageSize });
				} else if (props.listType === "user-list" && (props.refreshDataList === '' || props.refreshDataList === true)) {
					getAllUsers({ pageIndex, columnSortBy, sortOrder, pageSize });
				} else if (props.listType === "region-list" && (props.refreshDataList === '' || props.refreshDataList === true)) {
					getAllRegionsList({ columnSortBy, sortOrder });
				} else {
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line
		[props]
	);

	return (
		<Table
			columns={columns}
			data={data}
			fetchData={fetchData}
			actionErrorObj={actionErrorObj}
			loading={loading}
			downloading={downloading}
			downloadErrorMsg={downloadErrorMsg}
			pageCount={pageCount}
			totalCount={totalCount}
			listType={props.listType}
			defaultPageSize={props.listType === 'audit-logs' ? 100 : 10}
			showPagination={props.showPagination}
			disableColmSort={props.disableColmSort}
			filterObj={props.filterObj}
			successMsg={successMsg}
			getHiddenColumns={props.hiddenColumns}
			toggleUserDetails={toggleUserDetails}
			selectedUser={selectedUser}
			viewUserDetails={viewUserDetails}
			userUpdated={userUpdated}
			regionUpdated={regionUpdated}
			searchString={props.searchString ? props.searchString : ''}
			searchParams={props.searchParams ? props.searchParams : ''}
			toggleResetPassword={toggleResetPassword}
			resetUserPassword={resetUserPassword}
			errorObj={errorObj}
			viewRegionDetails={viewRegionDetails}
			selectedRegion={selectedRegion}
			toggleRegionDetails={toggleRegionDetails}
		/>
	);
}
export { DataTable, Table };
