import React, { Component } from 'react'
import { logoutService } from '../../../services/aws/aws-services';
import UnauthorizedUserComponent from './UnauthorizedUser'

export default class UnauthorizedUser extends Component {
	/**
	 * @description Function to handle logout
	 * @memberof UnauthorizedUser
	 */
	handleLogout = () => {
		logoutService((err, res) => { });
	}

	render() {
		return (
			<UnauthorizedUserComponent handleLogout={this.handleLogout} />
		)
	}
}
