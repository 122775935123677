import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { PasswordCriteria, PasswordHints } from '../../shared/PasswordCriteria';

export default class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.resetFormRef = React.createRef();
    }
    /* istanbul ignore next  */
    resetForm = () => {
        this.resetFormRef.reset();
    }
    /* istanbul ignore next  */
    componentDidUpdate() {
        if (this.props.successMessage || this.props.formErrors.cognitoError) {
            this.resetForm();
        }
    }
    render() {
        const { handleResetPassword, handleChange, handleBlur, formErrors, loading, successMessage, onEnterPress,
            pwdStrengthLabel, pwdStrengthProgress, pwdStrengthVarient, pwdMatchObj, resetPwdErrMsg } = this.props;
        return (
            <div data-testid="resetPassword">
                <form ref={(el) => this.resetFormRef = el}>
                    <div className="reset-password">
                        {successMessage ?
                            <div className="reset-pwd-success-msg" data-testid="successMessage">
                                <p>{successMessage}</p>
                            </div>
                            : ''}
                        {formErrors && formErrors.cognitoError ?
                            <div className="reset-pwd-error-msg">
                                <p>{formErrors.cognitoError}</p>
                            </div>
                            : ''}
                        <div className="d-flex align-items-center justify-content-left">
                            <div className="reset-form w-40">
                                <div className="reset-password-input-wrap old-password">
                                    <input placeholder="Enter old password"
                                        type="password"
                                        className="form-control"
                                        name="oldPassword"
                                        value={this.props.oldPassword}
                                        onChange={(e) => handleChange(e.target.value, 'oldPassword')}
                                        onKeyPress={onEnterPress}
                                        data-testid="oldPassword"
                                        id="oldPassword" />
                                    {formErrors && formErrors.oldPassword ?
                                        <div className="error-block">
                                            <p>{formErrors.oldPassword}</p>
                                        </div>
                                        : ''}
                                </div>

                                <div className="reset-password-input-wrap new-password">
                                    <input placeholder="Enter new password"
                                        type="password"
                                        className="form-control"
                                        name="newPassword"
                                        value={this.props.newPassword}
                                        onChange={(e) => handleChange(e.target.value, 'newPassword')}
                                        onBlur={(e) => handleBlur(e.target.value, 'newPassword')}
                                        onKeyPress={onEnterPress}
                                        id="newPassword"
                                        data-testid="newPassword" />
                                    {/* Password Hint */}
                                    <PasswordHints />
                                    {formErrors && formErrors.newPassword ?
                                        <div className="error-block">
                                            <p>{formErrors.newPassword}</p>
                                        </div>
                                        : ''}
                                    <div className="new-password-errors" data-testid="passwordError">
                                        {pwdStrengthLabel ? <ProgressBar variant={pwdStrengthVarient} now={pwdStrengthProgress} label={pwdStrengthLabel} /> : null}
                                        {formErrors && formErrors.newPasswordMatch ?
                                            <PasswordCriteria pwdMatchObj={pwdMatchObj} errorMessages={resetPwdErrMsg} />
                                            : ''}
                                    </div>
                                </div>
                                <div className="reset-password-input-wrap confirm-password">
                                    <input placeholder="Confirm new password"
                                        type="password"
                                        className="form-control"
                                        name="confirmPassword"
                                        value={this.props.confirmPassword}
                                        onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
                                        onKeyPress={onEnterPress}
                                        id="confirmPassword" 
                                        data-testid="confirmPassword"/>
                                    {formErrors && formErrors.confirmPassword ?
                                        <div className="error-block">
                                            <p>{formErrors.confirmPassword}</p>
                                        </div>
                                        : ''}
                                </div>
                                {loading ?
                                    <button className="btn btn-primary float-end disabled">
                                        <span className="spinner-border spinner-border-sm"></span>&nbsp;Updating Password...
                                    </button>
                                    : <button className="btn btn-primary proms-btn-color float-end" id="saveChanges" onClick={handleResetPassword}>Save Changes</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
