import environment from '../config/environment';
export const charset = "UTF-8";
export const sourceEmail = environment.sourceEmail;
export const supportEmail = environment.supportEmail;
export const subjectLines = {
    welcome: "Welcome to Digital Asset Portal!",
    passwordReset: "Smith+Nephew Password Reset.",
    emailChange: "Smith+Nephew Email Change Request!"
}

/**
 * @description Function to create anchor tag to redirect to current env home page
 * @returns HTML anchor tag
 */
const getLinkBackToSite = (text) => {
    return `<a href=${environment.baseUrl}>${text ? text : 'Digital Assets Portal'}</a>`
}

/**
 * @description Function to create header of the email body
 * @returns HTML
 */
const getHeader = () => {
    const headerImg = `${environment.baseUrl}/logo.png`;
    return (`
        <html>
            <body style="text-align: center; font-size: 18px;">
                <header>
                    <img src="${headerImg}" height="300px">
                </header>
    `);
}

/**
 * @description Function to create footer of the email body
 * @returns HTML
 */
const getFooter = () => {
    return (`
                <footer>
                    <div>
                        <p>Click here to login-> ${getLinkBackToSite('Digital Asset Portal Login')}</p>
                    </div>
                </footer>
            </body>
        </html>
    `)
}

/**
 * @description Function to create template for password reset email
 * @param user User who will receive the email
 * @returns Template for password reset
 */
export const getPasswordResetEmailTemplate = (user) => {
    return (`
        ${getHeader()}
        <h3 style="margin-top: 15px; font-size: 1.7rem;">Hello!</h3>
        <div style="margin: 15px 0;">
            <p>We received a request to reset the password for your Digital Asset Portal account.</p>
            <p style="margin: 15px 0 0 0;">Your new password is:</p> 
            <p style="margin: 0 0 15px 0;"><strong>New Password:</strong> ${user.password}</p>
            <p>Note: The temporary password is set to expire in 30 days.</p> 
        </div>
        ${getFooter()}
    `)
}

/**
 * @description Function to create template for reactivating a user
 * @param user User who will receive the email
 * @returns Template for password reset
 */
export const getUserReactivatedEmailTemplate = (user) => {
    return (`
        ${getHeader()}
        <h3 style="margin-top: 15px; font-size: 1.7rem;">Hello!</h3>
        <div style="margin: 15px 0;">
            <p>We received a request to reactivate your DAP account.</p>
            <p style="margin: 15px 0 15px 0;">Your new password is:</p> 
            <p style="margin: 0;"><strong>Username:</strong> ${user.username}</p>
            <p style="margin: 0 0 15px 0;"><strong>New Password:</strong> ${user.password}</p>
        </div>
        ${getFooter()}
    `)
}

/**
 * @description Function to create template for email when admin updates details
 * @param user User who will receive the email
 * @returns Template for email change
 */
export const getUserEmailChangedTemplate = (user) => {
    return (`
        ${getHeader()}
        <h3 style="margin-top: 15px; font-size: 1.7rem;">Hello!</h3>
        <div style="margin: 15px 0;">
            <p>We received a request to reset the Email for your Digital Asset Portal account.</p>
            <p>Your new email on the account is <i>"${user.email}"</i>. </p>
            <p>If this request was made in error, please contact your administrator</p>
        </div>
        ${getFooter()}
    `)
}

/**
 * @description Function to create template for email when admin creates new user
 * @param user User who will receive the email
 * @returns Template for email change
 */
export const getUserCreatedTemplate = (user) => {
    return (`
        ${getHeader()}
        <h3 style="margin-top: 15px; font-size: 1.7rem;">Welcome, ${user.firstName} ${user.lastName}!</h3>
        <div style="margin: 15px 0;">
            <p>Here are your credentials to access the Digital Asset Portal</p>
            <p>Please sign-in to confirm the account with the information below:</p>
            <p style="margin: 15px 0 0 0;"><strong>Username:</strong> ${user.userName}. </p>
            <p style="margin: 0 0 15px 0;"><strong>Temporary password:</strong> ${user.password} </p>
            <p>Note: The temporary password is set to expire in 30 days.</p>
        </div>
        ${getFooter()}
    `)
}
