import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ErrorComponent from '../components/Error/Error';
import { definedRoutesForRoles } from '../constants/definedRoutes/definedRoutes';
import { isAuthenticated, isFirstLogin } from '../helpers/GlobalFunctions';
import { errorMessages } from '../helpers/messages';
import { getUserRoleType } from '../services/aws/aws-services';

function isRouteAllowed(routeProps) {
	const userRole = getUserRoleType();
	const isCommonPath = definedRoutesForRoles.common.indexOf(routeProps.path) > -1;
	const isRolePath = userRole && definedRoutesForRoles[userRole] && definedRoutesForRoles[userRole].indexOf(routeProps.path) > -1;
	
	return isCommonPath || isRolePath;
}

function PrivateRoute({ component: Component, authentication, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => authentication === isAuthenticated()
				? isFirstLogin() && rest.path !== '/reset-password'
					? <Redirect to={{ pathname: '/reset-password', state: { from: props.location } }} /> 
					: isRouteAllowed(rest) 
						? <Component {...props} /> 
						: <ErrorComponent errorCode="403" message={errorMessages.noPermission} />
				: <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
		/>
	)
}

export default PrivateRoute;