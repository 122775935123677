import React, { Component } from 'react'
import { getAllAssetTypes } from '../../../services/java/java-services';
import FileSearchComponent from './FileSearch'

export default class FileSearchContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            assets: null,
            assetsError: null
        }
    }

    componentDidMount() {
        this.getAllAssetTypes();
    }

	/**
	* @description Function to fetch asset types
	* @memberof FilesListContainer
	*/
	getAllAssetTypes = () => {
		this.setState({ loading: true, assets: null, assetsError: null });
		getAllAssetTypes().then(res => {
			const assets = res.data && res.data.assetlist && res.data.assetlist.length ? res.data.assetlist : [];
			var assetSelectFormat = [];
			if (assets && assets.length) {
				assetSelectFormat = assets.map(x => {
					return {
						label: x.assettype,
						value: x.assetid
					}
				})
			}
			this.setState({
				loading: false,
				assetsError: null,
				assets: assetSelectFormat
			});
		}).catch(err => {
			this.setState({ loading: false, assetsError: err });
		})
	}

    render() {
        const { handleSearchSubmit } = this.props;
        const { assets } = this.state;
        return (
            <FileSearchComponent assets={assets} handleSearchSubmit={handleSearchSubmit} handleReset={handleSearchSubmit} />
        )
    }
}
