import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleLogin, loginSuccess } from '../../actions/loginAction';
import ResetTempPasswordComponent from './ResetTempPassword';
import { cognitoConstants } from '../../helpers/constants';
import { resetPasswordMessages } from '../../helpers/messages';
import { matchExpression, passwordRegexMatch, passwordStrength } from '../../helpers/PasswordValidate';
import { activityUpdater } from '../../services/activity/activity-service';
import { resetTempPassword, loginService, getAuthUser, getUserRoleType } from '../../services/aws/aws-services';
import { checkLocalStorage, getLocalStorage, removeLocalStorage } from '../../services/storage/storage-service';
import { RoleDefaultRedirect } from '../../constants/definedRoutes/definedRoutes';

class ResetTempPasswordContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            password: '',
            confirmPassword: '',
            errors: {},
            successMessage: '',
            pwdStrengthLabel: '',
            pwdStrengthProgress: '',
            pwdStrengthVarient: '',
            expMatch: {},
            userCreds: checkLocalStorage('userCreds') ? JSON.parse(getLocalStorage('userCreds')) : { username: '', password: '' },
            cognitoUser: props.state.rootReducer && props.state.rootReducer.authReducer && props.state.rootReducer.authReducer.userAuth ? props.state.rootReducer.authReducer.userAuth : ''
        };
    }

    componentDidMount() {
        this.redirectToDashboard();
    }

    componentDidUpdate() {
        this.redirectToDashboard();
    }

    /**
    * @description Function to handle redirection to dashboard
    * @memberof ResetTempPasswordContainer
    */
    redirectToDashboard() {
        if (checkLocalStorage('cognito_data') && !checkLocalStorage('isFirstLogin')) {
            this.props.history.push(RoleDefaultRedirect[getUserRoleType()]);
        }
    }

    /**
    * @description Function to handle changes in reset password form
    * @memberof ResetTempPasswordContainer
    */
    handleChange = (value, field) => {
        var errors = { ...this.state.errors }
        errors.cognitoError = ''
        this.setState({ errors, successMessage: '' });
        const username = this.state.userCreds ? this.state.userCreds.username : '';
        if (field === 'password') {
            var strengthObj = passwordStrength(value, username);
            this.setState({ ...strengthObj, expMatch: {} });

            if (value && !passwordRegexMatch(value, username)) {
                errors.password = '';
                errors.confirmPassword = '';
                errors.newPasswordMatch = true;
            } else {
                errors.newPasswordMatch = '';

            }
            if (value === '') {
                this.setState({ pwdStrengthLabel: '' });
            }
            this.setState({ errors })
            this.setState({ password: value });

        } else if (field === 'confirmPassword') {
            errors.confirmPassword = '';
            this.setState({ errors })
            this.setState({ confirmPassword: value });
        }
    }

    /**
    * @description Function to handle blur event in reset password form
    * @memberof ResetTempPasswordContainer
    */
     handleBlur = (value, field) => {
        const username = this.state.userCreds ? this.state.userCreds.username : '';
        this.handleChange(value, field);
        setTimeout(() => {
            if (field === 'password') {
                var matchResults = matchExpression(value, this.state.expMatch, username);
                this.setState({ expMatch: matchResults });
            }
        }, 0);
    }

    /**
    * @description Function to submit reset password form
    * @memberof ResetTempPasswordContainer
    */
    handleResetPassword = (event) => {
        event.preventDefault();
        this.setState({ successMessage: '' });
        if (this.validateResetPassInputs()) {
            this.resetPassword();
        }
    }

    /**
    * @description Function to validate reset password form
    * @memberof ResetTempPasswordContainer
    */
    validateResetPassInputs = () => {
        const { password, confirmPassword, userCreds } = this.state;
        const username = userCreds ? userCreds.username : '';
        let { errors } = this.state;
        let isValid = true;
        if (!password) {
            isValid = false;
            errors["password"] = resetPasswordMessages.error.newPassword;
        } else if (!confirmPassword) {
            isValid = false;
            errors["confirmPassword"] = resetPasswordMessages.error.confirmPassword;
        } else if (typeof password !== "undefined" && typeof confirmPassword !== "undefined") {
            this.handleBlur(password, 'password');
            if (!passwordRegexMatch(password, username)) {
                isValid = false;
                errors["passwordMatch"] = true;
            }
            if (password !== confirmPassword) {
                isValid = false;
                errors["confirmPassword"] = resetPasswordMessages.error.notMatch;
            }
        }

        this.setState({ errors: errors });

        return isValid;
    }

    /**
     * @description function to submit the form on press of enter key
     * @param e Keypress event
     * @memberof ResetTempPasswordContainer
     */
    /* istanbul ignore next  */
    onEnterPress = (e) => {
        if (e.which === 13) {
            this.handleResetPassword(e);
        }
    }

    /**
    * @description Function to call password reset service
    * @memberof ResetTempPasswordContainer
    */
    resetPassword() {
        const { cognitoUser, password } = this.state;
        var { errors } = this.state;
        this.setState({ loading: true });

        // To complete the new password challenge, AWS Amplify needs an untouched CognitoUser object.
        // Local/Session storage will not work since stringifying and parsing removes the necessary object functions

        // Check if the user has come straight from the login page or reloaded this page, ie, if the user object is available in redux store
        if (cognitoUser) {
            // This block is for the scenario when the user has come from the login page. 
            // The CognitoUser object will be used from the redux store
            this.completeCognitoPasswordChallenge(cognitoUser, password)
        } else {
            // This block is for the scenario when the user has reloaded the page and lost the CognitoUser object saved in redux store.
            // Since the user is not authenticated yet, the only way to get a new object is to call the login API once again and use the response.
            const { userCreds } = this.state;
            loginService(userCreds.username, userCreds.password).then(user => {
                this.setState({ cognitoUser: user });
                this.completeCognitoPasswordChallenge(user, password)
            }).catch(err => {
                errors.cognitoError = err.message || resetPasswordMessages.resetFailed;
                this.setState({ errors, loading: false, pwdStrengthLabel: '' });
            })
        }
    }

    /**
    * @description function to notify user of the password udpate
    * @memberof ResetTempPasswordContainer
    */
    completeCognitoPasswordChallenge = (user, password) => {
        var { errors } = this.state;
        resetTempPassword(user, password)
            .then(res => {
                this.getAuthenticatedUser();
            })
            .catch(err => {
                errors.cognitoError = resetPasswordMessages.resetFailed;
                this.setState({ errors, loading: false, pwdStrengthLabel: '' });
            });
    }

    /**
     * @description function to fetch authenticated user from cognito
     * @memberof ResetTempPasswordContainer
     */
    getAuthenticatedUser = () => {
        var { errors } = this.state;
        getAuthUser().then(cognitoUser => {
            this.setState({
                loading: false,
                successMessage: resetPasswordMessages.updatePassword,
                pwdStrengthLabel: '',
                password: '',
                confirmPassword: ''
            });
            removeLocalStorage('userCreds');
            removeLocalStorage('isFirstLogin');
            this.updateActivity(cognitoUser);
            handleLogin(cognitoUser, false);
            this.props.dispatch(loginSuccess(cognitoUser));
        })
            .catch(err => {
                errors.cognitoError = resetPasswordMessages.resetButAuthFailed;
                this.setState({ errors, loading: false, pwdStrengthLabel: '' });
            });
    }

    /**
     * @description function to handle  password reset activity
     * @memberof ResetTempPasswordContainer
     */
    updateActivity = (cognitoUser) => {
        if (cognitoUser) {
            const currentDate = new Date().toISOString();
            const activityObj = {
                lastPasswordResettime: currentDate,
                lastLoginTime: currentDate,
                userName: cognitoUser.username,
                userStatus: cognitoConstants.userStatus.CONFIRMED
            }
            activityUpdater(activityObj);
        }
    }

    render() {
        return (
            <ResetTempPasswordComponent
                loading={this.state.loading}
                password={this.state.password}
                confirmPassword={this.state.confirmPassword}
                handleResetPassword={this.handleResetPassword}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                formErrors={this.state.errors}
                successMessage={this.state.successMessage}
                onEnterPress={this.onEnterPress}
                handleCancel={this.props.handleCancel}
                pwdStrengthLabel={this.state.pwdStrengthLabel}
                pwdStrengthProgress={this.state.pwdStrengthProgress}
                pwdStrengthVarient={this.state.pwdStrengthVarient}
                pwdMatchObj={this.state.expMatch}
                resetPwdErrMsg={resetPasswordMessages.error.passwordErrors}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}


export default connect(mapStateToProps)(ResetTempPasswordContainer);