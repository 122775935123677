export const graphContextMenuItems = ["downloadPNG", "downloadJPEG", "downloadCSV"];
export const forbiddenPasswordStrings = ['password', 'qwerty'];
export const RegularExpressions = {
    alphabetNoSpace: new RegExp(/^[a-zA-Z]+$/),
    alphabetWithSpace: new RegExp(/^[a-zA-Z ]+$/), 
    alphanumeric: new RegExp(/^[a-zA-Z0-9- ]+$/),
    alphanumericNoSpace: new RegExp(/^[a-zA-Z0-9-]+$/),
    alphanumericNewLine: new RegExp(/^[0-9a-zA-Z\s\r\n-]+$/),
    alphanumericNoSpaceDot: new RegExp(/^[a-zA-Z0-9-.]+$/),
    email: new RegExp(/^[A-Za-z0-9_]+([.-]?[A-Za-z0-9_]+)*@[A-Za-z0-9_]+([.-]?[A-Za-z0-9_]+)*(\.[A-Za-z_]{2,4})+$/),
    surgeonID: new RegExp(/^[0-9]{0,10}$/),
    passwords: {
        format: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/),
        strength: {
            strong: new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$"),
            medium: new RegExp("^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$")
        },
        matchCriteria: {
            containsNumber: new RegExp(/[0-9]/),
            containsLowerCase: new RegExp(/[a-z]/),
            containsUpperCase: new RegExp(/[A-Z]/),
            numberRepeat: new RegExp(/(\d)(\1{2,})+/),
            numberSequence: new RegExp(/((01)|(12)|(23)|(34)|(45)|(56)|(67)|(78)|(89)|(90))/),
            numberSequenceReverse: new RegExp(/((21)|(32)|(43)|(54)|(65)|(76)|(87)|(98)|(09))/),
            stringLength: new RegExp(/^.{8,16}$/),
            containsSpecialChr: new RegExp(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/),
            containsString: (string) => new RegExp(string)
        }
    }
}

export const cognitoConstants = {
    userStatus: {
        CONFIRMED: 'CONFIRMED'
    },
    messageActions: {
        SUPPRESS: 'SUPPRESS'
    },
    exceptions: {
        UsernameExistsException: 'UsernameExistsException',
        ResourceNotFoundException: 'ResourceNotFoundException'
    }
}

export const CustomErrorCodes = {
    duplicateSurgeonID: 2028
}

export const RoleNameKeyMap = {
    admin: 'damp-admin',
    engineer: 'damp-engineer'
}

export const Roles = [
    { userRole: RoleNameKeyMap.admin, cognitoGroupName: RoleNameKeyMap.admin, label: 'Admin', specialLabel: 'Admin' },
    { userRole: RoleNameKeyMap.engineer, cognitoGroupName: RoleNameKeyMap.engineer, label: 'Engineer', specialLabel: 'Engineer' }
]

export const RolePermission = {
    fileUpload: [RoleNameKeyMap.engineer],
    region: [RoleNameKeyMap.engineer],
    auditLogs: [RoleNameKeyMap.admin],
    dashboard: [RoleNameKeyMap.admin],
    userManagement: [RoleNameKeyMap.admin],
    login: [RoleNameKeyMap.admin, RoleNameKeyMap.engineer]
}

export const BasicTextEditorToolbarConfig = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline']
    },
    blockType: {
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
    },
    list: {
        options: ['unordered', 'ordered', 'indent', 'outdent']
    },
    textAlign: {
        options: ['left', 'center', 'right', 'justify']
    },
    history: {
        options: ['undo', 'redo']
    },
}

export const DAConfig = {
    releaseNoteCharLimit: 5000,
    descCharLimit: 1024,
    fileExtensions: ['zip', 'json', 'secure', 'secure32']
}