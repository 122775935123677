import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginComponent from './Login';
import { authUser } from '../../actions/loginAction';
import { errorMessages } from '../../helpers/messages';
import { checkLocalStorage } from '../../services/storage/storage-service';
import { getUserRoleType } from '../../services/aws/aws-services';
import { RoleDefaultRedirect } from '../../constants/definedRoutes/definedRoutes';

class LoginContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			isShowForgotPwdModal: false
		};
	}
	componentDidMount() {
		if (checkLocalStorage('cognito_data')) {
			const userRole = getUserRoleType();
			this.props.history.push(RoleDefaultRedirect[userRole] || RoleDefaultRedirect.default);
		}
	}
	componentDidUpdate() {
		if (checkLocalStorage('cognito_data')) {
			const userRole = getUserRoleType();
			this.props.history.push(RoleDefaultRedirect[userRole] || RoleDefaultRedirect.default);
		}
	}
	/**
	 * @description function to handle login form validation and authentication
	 * @param {*}
	 * @memberof LoginContainer
	 */
	handleSubmit = () => {
		const userName = this.state.username;
		const password = this.state.password;
		const { dispatch } = this.props;
		if (!userName && !password) {
			this.setState({
				isError: errorMessages.login.bothEmpty
			});
		} else if (!userName) {
			this.setState({
				isError: errorMessages.login.username
			});
		} else if (!password) {
			this.setState({
				isError: errorMessages.login.password
			});
		} else {
			this.setState({
				isLoading: true
			});
			dispatch(authUser(userName, password));
		}
	}
	/**
	 * @description function to handle get and set for username and password value
	 * @param {*}
	 * @memberof LoginContainer
	 */
	onInputChange = (e, field) => {
		const { state } = this.props;
		if (field === 'username') {
			this.setState({ 'username': e.target.value });
		} else if (field === 'password') {
			this.setState({ 'password': e.target.value });
		}
		this.setState({ isError: '', isLoading: false });

		if (state.rootReducer && state.rootReducer.authReducer.message) {
			state.rootReducer.authReducer.message.text = '';
		}
	}
	/**
	 * @description function to submit the form on press of enter key
	 * @param {*}
	 * @memberof LoginContainer
	 */
	/* istanbul ignore next  */
	onEnterPress = (e) => {
		if (e.which === 13) {
			this.handleSubmit();
		}
	}
	/**
	* @description function to show forgot password modal
	* @param {*}
	* @memberof LoginContainer
	*/
	forgotPasswordModal = () => {
		this.setState({ isShowForgotPwdModal: this.state.isShowForgotPwdModal ? false : true });
	}
	/**
	* @description function to close forgot password modal
	* @param {*}
	* @memberof LoginContainer
	*/
	closeModal = (event) => {
		if (event) {
			event.preventDefault();
		}
		this.forgotPasswordModal();
	}

	render() {
		const { state } = this.props;
		let isLoading;
		let msg;
		if (this.state.isLoading) {
			isLoading = this.state.isLoading;
			msg = '';
		}
		if (this.state.isError) {
			msg = this.state.isError
		} else if (state.rootReducer && state.rootReducer.authReducer.message) {
			if (state.rootReducer.authReducer.message.text) {
				msg = state.rootReducer.authReducer.message.text;
				isLoading = false;
			}
		} else {
			msg = '';
		}
		return (
			<div >
				<LoginComponent onSubmit={this.handleSubmit}
					message={msg} onInputChange={this.onInputChange}
					isLoading={isLoading} onEnterPress={this.onEnterPress}
					isShowForgotPwdModal={this.state.isShowForgotPwdModal}
					forgotPasswordModal={this.forgotPasswordModal}
					closeModal={this.closeModal}
				/>
			</div>
		);
	}
}
LoginContainer.propTypes = {
	dispatch: PropTypes.func.isRequired,
	state: PropTypes.object.isRequired
};
function mapStateToProps(state) {
	return {
		state,
	};
}
export default connect(mapStateToProps)(LoginContainer);
