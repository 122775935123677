import { RegularExpressions } from "../../helpers/constants";
import { validateWhiteSpace } from "../../helpers/GlobalFunctions";
import { errorMessages } from "../../helpers/messages";

/**
 * @description function for validate each input field
 * @param stateObj Current state object
 * @returns Object with error messages
 */
export const validateDAFields = (stateObj, fields = ['assettype', 'fileName', 'version', 'partNo', 'regions', 'disclaimerCheckBox']) => {
    var { errors, editObj } = stateObj;
    var errorFlag = false;
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const reWhiteSpace = new RegExp(/^\s+$/);
        if (Array.isArray(editObj[field]) && !editObj[field].length) {
            errorFlag = true;
            errors[field] = errorMessages.fileUpload[field];
        } else {
            if (!editObj[field] || reWhiteSpace.test(editObj[field]) === true) {
                errorFlag = true;
                errors[field] = errorMessages.fileUpload[field];
            }
        }
    }

    return { errorFlag, errors };
}

/**
 * @description function for validate Alphanumeric
 * @param value Alphanumeric fields
 * @returns Object with error messages
 */
export const validateAlphanumeric = (value) => {
    if (validateWhiteSpace(value)) {
        return { errorFlag: true, error: errorMessages.fileUpload.whiteSpace }
    }

    var errorFlag = !RegularExpressions.alphanumeric.test(value);
    return { errorFlag: errorFlag ? true : false, error: errorFlag ? errorMessages.fileUpload.alphaNumericRegex : '' }
}

/**
 * @description function for validate Alphanumeric with dot
 * @param value Alphanumeric fields
 * @returns Object with error messages
 */
export const validateAlphanumericWithDot = (value) => {
    if (validateWhiteSpace(value)) {
        return { errorFlag: true, error: errorMessages.fileUpload.whiteSpace }
    }

    var errorFlag = !RegularExpressions.alphanumericNoSpaceDot.test(value);
    return { errorFlag: errorFlag ? true : false, error: errorFlag ? errorMessages.fileUpload.alphaNumericRegex : '' }
}

/**
 * @description function for validate DA Version
 * @param value Alphanumeric fields
 * @returns Object with error messages
 */
export const validateDAVersion = (value) => {
    if (validateWhiteSpace(value)) {
        return { errorFlag: true, error: errorMessages.fileUpload.whiteSpace }
    }

    if (value && value.length > 15) {
        return { errorFlag: true, error: errorMessages.fileUpload.versionLength }
    }

    var errorFlag = !RegularExpressions.alphanumericNoSpaceDot.test(value);
    return { errorFlag: errorFlag ? true : false, error: errorFlag ? errorMessages.fileUpload.alphaNumericRegex : '' }
}
