import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation, withRouter, Switch } from 'react-router-dom';
import PrivateRoute from './privateroute';
import { Provider } from 'react-redux';
import store from '../store/store';
import FileUpload from '../components/FileUpload';
import Login from '../components/Login';
import Dashboard from '../components/Dashboard'
import FilesList from '../components/FilesList';
import Settings from '../components/Settings/Settings';
import UserListContainer from '../components/UserList';
import Logs from '../components/Logs/Logs';
import ResetTempPasswordContainer from '../components/ResetTempPassword';
import FileDetails from '../components/FileDetails';
import ErrorComponent from '../components/Error/Error';
import RegionsListContainer from '../components/RegionList';
/**
* @description function to move the scroll bar to top after each transition
* @param {*}
* @memberof routes
*/
function ResetWindowScroll(props) {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return props.children
}
const ScrollToTop = withRouter(ResetWindowScroll)


const routes = (
	<Provider store={store}>
		<Router>
			<ScrollToTop>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/login" component={Login} />
					<PrivateRoute authentication={true} exact path="/dashboard" component={Dashboard} />
					<PrivateRoute authentication={true} exact path="/upload" component={FileUpload} />
					<PrivateRoute authentication={true} exact path="/assets" component={FilesList} />
					<PrivateRoute authentication={true} exact path="/asset-details" component={FileDetails} />
					<PrivateRoute authentication={true} exact path="/regions" component={RegionsListContainer} />
					<PrivateRoute authentication={true} exact path="/settings" component={Settings} />
					<PrivateRoute authentication={true} exact path="/users" component={UserListContainer} />
					<PrivateRoute authentication={true} exact path="/logs" component={Logs} />
					<PrivateRoute authentication={true} exact path="/reset-password" component={ResetTempPasswordContainer} />
					<Route path="*" component={ErrorComponent} />
				</Switch>
			</ScrollToTop>
		</Router>
	</Provider>
);
export default routes;