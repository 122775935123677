import React, { Component } from 'react';
import { connect } from 'react-redux';
import AlertMessage from '../../AlertMessage/AlertMessage';

class LogsFilterComponent extends Component {
    constructor(props) {
        super(props);
        this.filterRef = React.createRef();
    }

    /**
     * @description function to reset filter form
     * @param {*}
     * @memberof LogsFilterComponent
     */
    resetForm = () => {
        this.filterRef.reset();
        this.props.resetLogsFilter();
    }
    render() {
        const { showFilter, showFilterBlock, todayDate, eventFromDate, eventToDate, filterLogs,
            onInputChange, errorMessage, disableFilter, disableReset, username, eventname, coriserialno } = this.props;
        return (
            <div>
                <div className="logs-list-top-btn">
                    {showFilterBlock ? <button className="btn btn-primary" onClick={showFilter}>Hide Filters</button> :
                        <button data-testid="showFilter" className="btn btn-primary" onClick={showFilter}>Show Filters</button>}
                </div>
                {showFilterBlock ?
                    <div className="case-list-filter">
                        {errorMessage ? <AlertMessage errorMessage={errorMessage} /> : null}
                        <form id="logsfilter" ref={(el) => this.filterRef = el}>
                            <div className="d-flex align-items-center justify-content-between mx-3 mb-3">
                                <div className="logger-filter-input-wrap">
                                    <div className="form-label"><label>Event From Date</label></div>
                                    <div className="fileinputs form-input">
                                        <input className="form-control" type="date" data-testid="eventFromDate" id="eventFromDate"
                                            value={eventFromDate || ''} max={todayDate}
                                            onChange={(e) => onInputChange(e, 'eventFromDate')}
                                        />
                                    </div>
                                </div>
                                <div className="logger-filter-input-wrap">
                                    <div className="form-label"><label>Event To Date</label></div>
                                    <div className="fileinputs form-input">
                                        <input className="form-control" type="date" data-testid="eventToDate" id="eventToDate"
                                            value={eventToDate || ''} max={todayDate}
                                            onChange={(e) => onInputChange(e, 'eventToDate')}
                                        />
                                    </div>
                                </div>
                                <div className="logger-filter-input-wrap">
                                    <div className="form-label"><label>Username</label></div>
                                    <div className="fileinputs form-input">
                                        <input className="form-control" type="text" data-testid="username" id="username"
                                            value={username} onChange={(e) => onInputChange(e, 'username')}
                                        />
                                    </div>
                                </div>
                                <div className="logger-filter-input-wrap">
                                    <div className="form-label"><label>Event Name</label></div>
                                    <div className="fileinputs form-input">
                                        <input className="form-control" type="text" data-testid="eventname" id="eventname"
                                            value={eventname} onChange={(e) => onInputChange(e, 'eventname')}
                                        />
                                    </div>
                                </div>
                                <div className="logger-filter-input-wrap">
                                    <div className="form-label"><label>CORI SN</label></div>
                                    <div className="fileinputs form-input">
                                        <input className="form-control" type="text" data-testid="coriserialno" id="coriserialno"
                                            value={coriserialno} onChange={(e) => onInputChange(e, 'coriserialno')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-end mx-3">
                                <div className="filter-btn-wrap">
                                    <button className="btn btn-primary me-2" type="button" data-testid="logsFilter" id="logsFilter" disabled={disableFilter} onClick={filterLogs}>Filter</button>
                                    <button className="btn btn-secondary" type="button" data-testid="logsReset" id="logsReset" disabled={disableReset} onClick={this.resetForm}>Reset</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    : null}
            </div>
        );
    }
}
const mapStateToProps = (state) => (
    {
        state
    }
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(LogsFilterComponent);
