import { loggerPostAPI } from '../java/java-services';
import { getUserName } from '../aws/aws-services';

export const logger = (changedObj) => {
	const defaultObj = {
		"EventSource": "damp",
		"EventUniqueIdentifier": "V1.0",
		"UserID": getUserName(),
	}
	let loggerObj = Object.assign({}, defaultObj, changedObj);
	loggerPostAPI(loggerObj, (err, result) => {
		/* istanbul ignore next  */
		if (err) {
			return false;
		} else {
			return true;
		}
	})
}
