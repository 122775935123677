import React, { Component } from 'react';
import RegionStatusComponent from './RegionStatus';
import { activityUpdaterWithPromise } from '../../../services/activity/activity-service';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../../helpers/messages';
import { logger } from '../../../services/logger/logger-service';
import { updateRegionDetails } from '../../../services/java/java-services'


export default class RegionStatusContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			region: props.region ? props.region : null,
			error: null
		}
	}

	/**
	 * @description Function to change region's status
	 * @memberof RegionStatusContainer
	 */
	changeRegionStatus = (isEnable) => {
		const { region } = this.state;
		if (region) {
			let params = {
				id: region.id,
				status: isEnable
			}

			this.props.toggleLoader(true);
			updateRegionDetails(params, (err, res) => {
				if (err) {
					this.handleError(err);
				} else {
					this.props.toggleLoader(false);
					const updatedRegionStatus = res && res.data ? res.data : region;
					this.regionStatusUpdateLogger({ outcome: loggerEventOutcome.success, message: loggerEventMessage.regionStatusUpdated(updatedRegionStatus) });
					setTimeout(() => {
						this.props.regionStatusUpdated(updatedRegionStatus);
					}, 0);
				}

			})
		}
	}

	/**
	 * @description Function to handle errors
	 * @param error Error object with error code and message
	 * @memberof RegionStatusContainer
	 */
	handleError = (error) => {
		this.props.toggleLoader(false);
		this.setState({ error: error });
		this.regionStatusUpdateLogger({ outcome: loggerEventOutcome.failure, message: error.message });
		this.props.updateError(error);
	}

	/**
	 * @description Function to update region's status in backend DB
	 * @memberof RegionStatusContainer
	 */
	updateRegionStatus = (region) => {
		const activityObj = {
			enabled: region.enabled,
		}
		activityUpdaterWithPromise(activityObj).then(res => {

		}).catch(err => {
			this.props.toggleLoader(false);
			this.handleError(err);
		});
	}

	/**
 * @description function to log region status update event
 * @param params Params with outcome and message
 * @memberof RegionStatusContainer
 */
	regionStatusUpdateLogger = (params) => {
		const loggerObj = {
			"EventOutcome": params.outcome,
			"EventType": loggerEventTypes.update,
			"EventName": loggerEventName.regionStatus,
			"Content": {
				"Data": params.message
			}
		}
		logger(loggerObj);
	}

	render() {
		const { region } = this.state;
		return (
			<RegionStatusComponent
				region={region}
				changeRegionStatus={this.changeRegionStatus}
			/>
		)
	}
}
