import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Sidemenu from '../SideMenu/SideMenu';
import { actions, errorMessages, fileUpload } from '../../helpers/messages';
import CustomModal from '../../shared/CustomModal';
import MultiSelectComponent from '../../shared/MultiSelectComponent';
import TextEditor from '../../shared/TextEditor';
import { Link } from 'react-router-dom';

class FileUploadComponent extends Component {
	constructor(props) {
		super(props)
		this.inputOpenFileRef = React.createRef();
		this.state = {
			showConfirmModal: false
		}
	}

	/**
	* @description Function to open file dialog 
	* @memberof FileUploadComponent
	*/
	/* istanbul ignore next  */
	showOpenFileDlg = () => {
		this.inputOpenFileRef.current.click()
	}

	/**
	* @description Function to open modal for confirmation for file upload cancel
	* @memberof FileUploadComponent
	*/
	/* istanbul ignore next  */
	cancelUploadConfirmation = () => {
		this.setState({ showConfirmModal: true })
	}

	/**
	* @description Function to handle the action on upload confirmation modal
	* @memberof FileUploadComponent
	*/
	/* istanbul ignore next  */
	cancelUploadAction = (action) => {
		if (action) {
			this.props.onFileUploadCancel();
		}
		this.setState({ showConfirmModal: false });
	}

	render() {
		const { onSubmit, onInputChange, isLoading, resetInput, fileName, uploadError, fileExtentionError, uploadProgress, fileExistInS3, selectedFileVersionExists, disclaimerCheckBox, maxFileSizeError,
			metaDataUpdateStatus, metaDataUpdateError, handleInputChange, formErrors, successMsgs, enableUploadBtn, enableCancelBtn, showProgress, loggerObj, cancelFileUploadStatus, allRegions, assets,
			regions, descCharLimit, descCharCount, releaseCharLimit, status, allCustomers, customers } = this.props;
		return (
			<>
				<div className="bgGray">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-1">
								<Sidemenu loggerObj={loggerObj} uploadProgress={uploadProgress} />
							</div>
							<div className="col-md-11">
								<div className="file-upload" data-testid="file-upload">
									<div className="file-form-wrap">
										<div className="row">
											<div className="col-sm-12">
												<Link to={{ pathname: "/assets" }} className="btn btn-link px-0 fs-9-rem fw-600 text-dark">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left fw-600" viewBox="0 0 16 16">
														<path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
													</svg>
													<span className="ms-1">Back to Assets</span>
												</Link>
											</div>
											<div className="col-sm-12">
												<div className="d-flex align-items-center justify-content-between mb-2">
													<h5 className="file-upload-header">Add New Asset</h5>
												</div>
											</div>
										</div>
										{metaDataUpdateStatus === 'success' && successMsgs && !cancelFileUploadStatus ?
											<div className="row">
												<div className="col-sm-12">
													<div className="success-msg">
														<p>{successMsgs.fileUploadSuccess}</p>
													</div>
												</div>
											</div>
											: ''}
										{uploadError && cancelFileUploadStatus ?
											<div className="row">
												<div className="col-sm-12">
													<div className="top-error-msg">
														<p>{uploadError}</p>
													</div>
												</div>
											</div>
											: ''}
										{metaDataUpdateStatus === 'fail' ?
											<div className="row">
												<div className="col-sm-12">
													<div className="top-error-msg">
														<p>{metaDataUpdateError}</p>
													</div>
												</div>
											</div>
											: ''}
										<div className="row">
											<div className="col-sm-6">
												{/* Asset Type */}
												<div className="form-container">
													<div className="form-label"><label>Asset Type<span className="red">*</span>: </label></div>
													<div className="fileinputs form-input">
														<select
															id="asset-type"
															data-testid="assetType"
															className={`form-select ${(formErrors && formErrors.assettype) || selectedFileVersionExists ? 'has-error' : ''}`}
															onChange={(e) => handleInputChange('assettype', e.target.value)}>
															<option value="">Select asset type</option>
															{assets && assets.length ? assets.map(asset => <option key={`option-${asset.assetid}`} value={asset.assetid}>{asset.assettype}</option>) : ''}
														</select>
														{formErrors && formErrors.assettype ?
															<div className="error-msg">
																<p>{formErrors.assettype}</p>
															</div>
															: ''}
														{selectedFileVersionExists ?
															<div className="error-msg">
																<p>{errorMessages.fileUpload.duplicateVersion} </p>
															</div>
															: ''}

													</div>
												</div>
											</div>
											<div className="col-sm-6">
												{/* File */}
												<div className="form-container">
													<div className="form-label"><label>Select File<span className="red">*</span>: </label></div>
													<div className="fileinputs form-input">
														<input type="file" name="fileName" ref={this.inputOpenFileRef} id="inputFile" data-testid="inputFile" onChange={(e) => onInputChange(e.target.files)} onClick={resetInput} className="form-control file input-file" />
														<div className="sudofile d-flex justify-content-between">
															<input
																placeholder="Select File"
																type="text"
																name="fileNames"
																disabled
																defaultValue={fileName}
																className={`form-control input-file ${(formErrors && formErrors.fileName) || fileExtentionError || maxFileSizeError || fileExistInS3 ? 'has-error' : ''}`}
																size="60"
															/>
															<button className="ms-2 btn btn-secondary btn-browse-file" onClick={this.showOpenFileDlg}>Browse</button>
														</div>
														{formErrors && formErrors.fileName ?
															<div className="error-msg">
																<p>{formErrors.fileName}</p>
															</div>
															: ''}
														{fileExtentionError ?
															<div className="error-msg">
																<p>{errorMessages.fileUpload.extension}</p>
															</div>
															: ''}
														{maxFileSizeError ?
															<div className="error-msg">
																<p>{errorMessages.fileUpload.maxSizeAllowed}</p>
															</div>
															: ''}
														{fileExistInS3 ?
															<div className="error-msg">
																<p>{errorMessages.fileUpload.duplicateFileName} </p>
															</div>
															: ''}
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-6">
												{/* Version */}
												<div className="form-container">
													<div className="form-label"><label>Version<span className="red">*</span>: </label></div>
													<div className="fileinputs form-input">
														<input
															type="text"
															id="version"
															data-testid="version"
															autoComplete="off"
															maxLength={15}
															className={`form-control ${(formErrors && formErrors.version) || selectedFileVersionExists ? 'has-error' : ''}`}
															onChange={(e) => handleInputChange('version', e.target.value)}
														/>
														{formErrors && formErrors.version ?
															<div className="error-msg">
																<p>{formErrors.version}</p>
															</div>
															: ''}
														{selectedFileVersionExists ?
															<div className="error-msg">
																<p>{errorMessages.fileUpload.duplicateVersion} </p>
															</div>
															: ''}
													</div>
												</div>
											</div>
											<div className="col-sm-6">
												{/* Part Number */}
												<div className="form-container">
													<div className="form-label"><label>Part Number<span className="red">*</span>: </label></div>
													<div className="fileinputs form-input">
														<input
															type="text"
															id="partNo"
															data-testid="partNo"
															autoComplete="off"
															maxLength={20}
															className={`form-control ${formErrors && formErrors.partNo ? 'has-error' : ''}`}
															onChange={(e) => handleInputChange('partNo', e.target.value)}
														/>
														{formErrors && formErrors.partNo ?
															<div className="error-msg">
																<p>{formErrors.partNo}</p>
															</div>
															: ''}
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-6">
												{/* Regions */}
												<div className="form-container">
													<div className="form-label"><label>Region<span className="red">*</span>: </label></div>
													<div className="fileinputs form-input">
														<MultiSelectComponent
															id="region"
															data-testid="region"
															noChipsView={true}
															hasSelectAll={true}
															dropdownContent="regions"
															className={`multiselect-wrap ${formErrors && formErrors.regions ? 'has-error' : ''}`}
															options={allRegions || []}
															selectedValues={regions || []}
															handleSelectChange={(selectedItems) => handleInputChange('regions', selectedItems)}
															placeholder="Select Region(s)"
														/>
														{formErrors && formErrors.regions ?
															<div className="error-msg">
																<p>{formErrors.regions}</p>
															</div>
															: ''}
													</div>
												</div>
											</div>
											<div className="col-sm-6">
												{/* Customer */}
												<div className="form-container">
													<div className="form-label"><label>Customer<span className="red">*</span>: </label></div>
													<div className="fileinputs form-input">
														<MultiSelectComponent
															id="customer"
															data-testid="customer"
															noChipsView={true}
															hasSelectAll={true}
															dropdownContent="customers"
															className={`multiselect-wrap ${formErrors && formErrors.customers ? 'has-error' : ''}`}
															options={allCustomers || []}
															selectedValues={customers || []}
															handleSelectChange={(selectedItems) => handleInputChange('customers', selectedItems)}
															placeholder="Select Customer(s)"
														/>
														{formErrors && formErrors.customers ?
															<div className="error-msg">
																<p>{formErrors.customers}</p>
															</div>
															: ''}
													</div>
												</div>

											</div>
										</div>
										<div className="row">
											<div className="col-sm-6">
												{/* Release Notes */}
												<div className="form-container">
													<div className="form-label"><label>Release Notes: </label></div>
													<div className="fileinputs form-input">
														<TextEditor
															id="releaseNotes"
															data-testid="releaseNotes"
															className={formErrors && formErrors.releaseNotes ? 'has-error' : ''}
															charLimit={releaseCharLimit}
															onChange={(editorObj) => handleInputChange('releaseNotes', editorObj)}
														/>
													</div>
												</div>
											</div>
											<div className="col-sm-6">
												{/* Description */}
												<div className="form-container">
													<div className="form-label"><label>Description: </label></div>
													<div className="fileinputs form-input">
														<textarea
															id="description"
															data-testid="description"
															className={`form-control ${formErrors && formErrors.description ? 'has-error' : ''}`}
															maxLength={descCharLimit}
															rows="6"
															onChange={(e) => handleInputChange('description', e.target.value)}
														/>
														<div className={formErrors && formErrors.description ? "desc-char-count error-msg" : "desc-char-count"}>
															<p className="m-0 fs-8-rem">{errorMessages.fileUpload.charCount(descCharCount, descCharLimit)}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-6">
												<div className="form-container">
													<div className="form-label"></div>
													<div className="fileinputs form-input">
														<div className="mrgtop-20">
															<label className="chk-label"><input type="checkbox" id="disclaimerchk" data-testid="disclaimerchk" className="chk-input" onChange={(e) => handleInputChange('disclaimerCheckBox', e.target.checked)} checked={disclaimerCheckBox === true} />
																&nbsp;<span className="red">*</span>&nbsp;{errorMessages.fileUpload.disclaimerCheckBoxText}
															</label>
														</div>
														{formErrors && formErrors.disclaimerCheckBox ?
															<div className="disclaimer-error-msg">
																<p>{formErrors.disclaimerCheckBox}</p>
															</div>
															: ''}
													</div>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="form-container">
													<div className="row">
														<div className="col-sm-6">
															<div className="form-label"><label>Status: </label></div>
															<div className="device-status-toggle">
																<div className="fileinputs form-input">
																	<label className="switch">
																		<input onChange={(e) => handleInputChange('status', e.target.checked)} defaultChecked={status} type="checkbox" name="file-status" id="file-status" data-testid="fileStatus" />
																		<div className="slider round">
																			<span className="on">
																				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
																					<path d="M7.5 1v7h1V1h-1z" />
																					<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
																				</svg>
																			</span>
																			<span className="off">
																				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" className="bi bi-power" viewBox="0 0 16 16">
																					<path d="M7.5 1v7h1V1h-1z" />
																					<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
																				</svg>
																			</span>
																		</div>
																	</label>
																</div>
															</div>
															{formErrors && formErrors.status ?
																<div className="disclaimer-error-msg">
																	<p>{formErrors.status}</p>
																</div>
																: ''}
														</div>
														<div className="col-sm-6">
															<div className="btns-style text-end">
																<button className="upload-selected btn btn-primary" id="fileUpload" data-testid="uploadBtn" onClick={onSubmit} disabled={enableUploadBtn ? false : true}>Upload</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* MODALS */}
									<div className="upload-status">
										{/* Modal to show upload progress */}
										{(!cancelFileUploadStatus) && <CustomModal
											id="upload-progress-modal"
											show={showProgress}
											title={actions.upload}
											isComponent={true}
											isAlert={uploadProgress > 0 && enableCancelBtn}
											alertText="Cancel Upload"
											closeAction={this.cancelUploadConfirmation}>
											{(uploadProgress > 0 && enableCancelBtn) || (isLoading && metaDataUpdateStatus === '') ?
												<ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated />
												: ''}
										</CustomModal>}

										{/* Modal to confirm upload cancel */}
										<CustomModal
											id="upload-cancel-modal"
											data-testid="upload-cancel-modal"
											show={this.state.showConfirmModal}
											title="Alert"
											isConfirm={true}
											body={fileUpload.cancelConfirmationText}
											closeAction={() => this.cancelUploadAction(true)}
											rejectAction={() => this.cancelUploadAction(false)}
											confirmAction={() => this.cancelUploadAction(true)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(FileUploadComponent);
