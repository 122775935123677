import React, { Component } from 'react'
import SideMenu from '../SideMenu/SideMenu'
import { DataTable } from '../DataTable/DataTable';
import { convertUTCToLocalDateTime } from '../../helpers/GlobalFunctions';
import AddRegionModalComponent from './AddRegionModal'
export default class RegionsList extends Component {
    render() {
        const { loggerObj, fromDashboard, regionUpdated, refreshRegionList,regionStatusUpdated,toggleLoader} = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Name',
                accessor: 'region',
                Cell: ({ row }) => (<span>{row.original.region ? row.original.region : 'N/A'}</span>),
                Filter: ''
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: ({ row }) => (<span>{row.original.description ? row.original.description : 'N/A'}</span>),
                Filter: ''
            },

            {
                Header: 'Created Date',
                accessor: 'createddate',
                Cell: ({ row }) => (<span>{row.original.createddate ? convertUTCToLocalDateTime(row.original.createddate) : 'N/A'}</span>),
                Filter: ''
            },

            {
                Header: 'Status',
                regionStatus: true
            },
            {
                Header: ' ',
                regionAction: true
            }

        ];
        return (
            <div className="bgGray">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1">
                            <SideMenu loggerObj={loggerObj} />
                        </div>
                        <div className="col-md-11 datalist-style">
                            <div className="row">
                                <div className="col-md-6">
                                    <h1 className="file-list-heading">Region List</h1>
                                </div>
                                <div className="col-md-6 text-end">
                                    <AddRegionModalComponent fromDashboard={fromDashboard} regionUpdated={regionUpdated} />
                                </div>
                            </div>
                            <div className="file-list">
                                <DataTable
                                    listType="region-list"
                                    showPagination={false}
                                    columns={columns}
                                    refreshDataList={refreshRegionList}
                                    regionStatusUpdated={regionStatusUpdated}
                                    toggleLoader={toggleLoader}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
