import React, { Component } from 'react'
import EngineerComponent from './Engineer'

export default class EngineerDashboard extends Component {
    render() {
        return (
            <EngineerComponent />
        )
    }
}
