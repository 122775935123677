import { RoleNameKeyMap } from "../../helpers/constants";

export const RoutesPerModule = {
	auth: ['/', '/login', '/reset-password'],
	dashboard: ['/dashboard'],
	assets: ['/upload', '/assets', '/asset-details'],
	regions: ['/regions'],
	users: ['/users'],
	logs: ['/logs'],
	settings: ['/settings']
}

export const definedRoutesForRoles = {
	common: [
		...RoutesPerModule.auth,
		...RoutesPerModule.dashboard,
		...RoutesPerModule.settings
	],
	[RoleNameKeyMap.engineer]: [
		...RoutesPerModule.assets,
		...RoutesPerModule.regions
	],
	[RoleNameKeyMap.admin]: [
		...RoutesPerModule.users,
		...RoutesPerModule.logs
	],

}

export const RoleDefaultRedirect = {
	default: '/dashboard',
	[RoleNameKeyMap.engineer]: '/assets',
	[RoleNameKeyMap.admin]: '/dashboard'

}