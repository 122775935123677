import React, { Component } from 'react';
import { connect } from 'react-redux';
import Sidemenu from '../SideMenu/SideMenu';
import { DataTable } from '../DataTable/DataTable';
import { arrayToString, convertFileSize, convertUTCToLocalDateTime, NAHelpText } from '../../helpers/GlobalFunctions';
import { Link } from 'react-router-dom';
import FileSearchContainer from './FileSearch';

class FilesListComponent extends Component {
	render() {
		const { loggerObj, searchParams, toggleLoader, fileStatusUpdated, searchSubmit, refreshFileList, handleSearchSubmit, handleReset } = this.props;
		/* istanbul ignore next  */
		const columns = [
			{
				Header: 'Name',
				accessor: 'filename',
				Cell: ({ row }) => (<span>{row.original.filename ? row.original.filename : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Asset Type',
				accessor: 'assettype',
				Cell: ({ row }) => (<span>{row.original.assettype ? row.original.assettype : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Version',
				accessor: 'daversion',
				Cell: ({ row }) => (<span>{row.original.daversion ? row.original.daversion : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Part Number',
				accessor: 'partnumber',
				Cell: ({ row }) => (<span>{row.original.partnumber ? row.original.partnumber : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Available Regions',
				accessor: 'regions',
				Cell: ({ row }) => (<span>{row.original.allregion ? 'All Regions' : row.original.regions ? arrayToString(row.original.regions) : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Filesize (kB)',
				accessor: 'filesize',
				Cell: ({ row }) => (<span>{row.original.filesize ? convertFileSize(row.original.filesize) : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Created Date',
				accessor: 'createdDate',
				Cell: ({ row }) => (<span>{row.original.createddate ? convertUTCToLocalDateTime(row.original.createddate) : 'N/A'}</span>),
				Filter: ''
			},
			{
				Header: 'Status',
				fileStatus: true
			},
			{
				Header: ' ',
				fileAction: true
			}
		]
		return (
			<div className="bgGray">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-1">
							<Sidemenu loggerObj={loggerObj} />
						</div>
						<div className="col-md-11 datalist-style" data-testid="fileList">
							<div className="file-list">
								<div className="row">
									<div className="col-md-12">
										<div className="d-flex align-items-center justify-content-between">
											<h1 className="file-list-heading ms-0">Uploaded Assets</h1>
											{NAHelpText()}
										</div>
									</div>
								</div>
								<div className="d-flex justify-content-between align-items-center my-3">
									<FileSearchContainer handleSearchSubmit={handleSearchSubmit} handleReset={handleReset} />
									<Link className="btn btn-primary" id="new-file" to={{ pathname: "/upload", state: { fromListPage: true } }}>Add New</Link>
								</div>
								<DataTable
									listType="file-list"
									showPagination={true}
									columns={columns}
									filterObj={searchParams}
									toggleLoader={toggleLoader}
									fileStatusUpdated={fileStatusUpdated}
									refreshDataList={searchSubmit || refreshFileList}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(FilesListComponent);
