import React, { Component } from 'react';
import { connect } from 'react-redux';
import LogsFilterComponent from './LogsFilter';
import { logFilterErrorMessages } from '../../../helpers/messages';

class LogsFilterContainer extends Component {
	constructor() {
		super()
		this.state = {
			showFilterBlock: true,
			todayDate: this.getTodaysDate(),
			eventFromDate: '',
			eventToDate: '',
			username: '',
			coriserialno: '',
			eventname: '',
			errorMessage: '',
			disableFilter: true,
			disableReset: true,
			isFiltered: false,
			noInputSelected: true,
		}
	}

	/**
	 * @description function to set input filter values
	 * @param {*}
	 * @memberof LogsFilterComponent
	 */
	onInputChange = (e, field) => {
		if (field === 'username' || field === 'coriserialno') {
			this.setState({ [field]: e.target.value.trim() });
		} else {
			this.setState({ [field]: e.target.value })
		}
		if (this.state.eventFromDate && (Date.parse(this.state.eventFromDate) >= this.state.eventToDate && Date.parse(this.state.eventToDate))) {
			this.setState({ 'errorMessage': logFilterErrorMessages.toLessThanFrom });
		} else {
			this.setState({ 'errorMessage': "" });
		}
		this.toggleFilterDisable();
	}

	/**
	* @description function to get current date
	* @param {*}
	* @memberof LogsFilterComponent
	*/
	getTodaysDate = () => {
		let newDate = new Date();
		let d = newDate.getDate();
		let m = newDate.getMonth() + 1;
		let yyyy = newDate.getFullYear();
		let dd = d > 9 ? d.toString() : '0' + d.toString();
		let mm = m > 9 ? m.toString() : '0' + m.toString();
		var date = yyyy + '-' + mm + '-' + dd;
		return date;
	}

	/**
	 * @description function to validate filter form and submit
	 * @param {*}
	 * @memberof LogsFilterComponent
	 */
	filterLogs = (e) => {
		let filterObj;
		var getCurrentYear = new Date().getFullYear();
		var getFromDateYear = this.state.eventFromDate ? new Date(this.state.eventFromDate).getFullYear() : '';
		var getToDateYear = this.state.eventToDate ? new Date(this.state.eventToDate).getFullYear() : '';
		const alphanumeric = new RegExp(/^[a-zA-Z0-9]+$/);
		const alphaNumericWithSpace = new RegExp(/^[a-zA-Z0-9\s]+$/);

		if (((Date.parse(getFromDateYear)) > (Date.parse(getCurrentYear)))) {
			this.setState({ 'errorMessage': logFilterErrorMessages.fromGreaterThanCurrentYear });
		} else if (((Date.parse(getToDateYear)) > (Date.parse(getCurrentYear)))) {
			this.setState({ 'errorMessage': logFilterErrorMessages.toGreaterThanCurrentYear });
		} else if (((Date.parse(this.state.eventFromDate)) > (Date.parse(this.state.eventToDate)))) {
			this.setState({ 'errorMessage': logFilterErrorMessages.toLessThanFrom });
		} else if ((this.state.eventFromDate && !this.state.eventToDate) || (!this.state.eventFromDate && this.state.eventToDate)) {
			this.setState({ 'errorMessage': logFilterErrorMessages.selectBoth });
		} else if (this.state.eventname && !alphaNumericWithSpace.test(this.state.eventname) && this.state.username && !alphanumeric.test(this.state.username)) {
			this.setState({ 'errorMessage': logFilterErrorMessages.userNameAndEventName });
		} else if (this.state.eventname && !alphaNumericWithSpace.test(this.state.eventname)) {
			this.setState({ 'errorMessage': logFilterErrorMessages.eventName });
		} else if (this.state.username && !alphanumeric.test(this.state.username)) {
			this.setState({ 'errorMessage': logFilterErrorMessages.userName });
		} else {
			this.setState({ 'errorMessage': "" });
			if (this.state.errorMessage === '') {
				filterObj = {
					StartEventDate: this.state.eventFromDate,
					EndEventDate: this.state.eventToDate,
					eventName: this.state.eventname,
					coriserialno: this.state.coriserialno,
					userID: this.state.username
				}
				this.props.setLogsFilterObj(filterObj);
				this.setState(prevState => {
					return {
						disableReset: prevState.isFiltered && prevState.noInputSelected,
						isFiltered: !prevState.noInputSelected,
						disableFilter: prevState.isFiltered && prevState.noInputSelected
					}
				});
			}
		}
		e.preventDefault();
	}

	/**
		 * @description function to set the filter button to enabled or disabled
		 * @param {*}
		 * @memberof LogsFilterComponent
		 */
	toggleFilterDisable() {
		setTimeout(() => {
			const noInputSelected = !this.state.eventFromDate && !this.state.eventToDate && !this.state.username && !this.state.eventname && !this.state.coriserialno;
			const dateRangeNotSelected = (this.state.eventFromDate && !this.state.eventToDate) || (!this.state.eventFromDate && this.state.eventToDate)
			const disableFilter = (noInputSelected || dateRangeNotSelected) && !this.state.isFiltered;
			this.setState({ noInputSelected: noInputSelected, disableFilter: disableFilter, disableReset: noInputSelected && !this.state.isFiltered });
		}, 0)
	}


	/**
	 * @description function to reset filter form
	 * @param {*}
	 * @memberof LogsFilterComponent
	 */
	resetLogsFilter = () => {
		this.setState({
			eventFromDate: '',
			eventToDate: '',
			username: '',
			coriserialno: '',
			eventname: '',
			errorMessage: '',
			disableReset: true,
			isFiltered: false
		})
		let filterObj = {
			StartEventDate: '',
			EndEventDate: '',
			eventName: '',
			coriserialno: '',
			userID: ''
		}

		if (!this.state.disableFilter) {
			this.setState({ disableFilter: true })
			this.props.setLogsFilterObj(filterObj);
		}
	}


	/**
	 * @description function to show / hide filter block
	 * @param {*}
	 * @memberof LogsFilterComponent
	 */
	showFilter = () => {
		this.state.showFilterBlock ? this.setState({ showFilterBlock: false }) : this.setState({ showFilterBlock: true });
	}

	render() {
		return (
			<div>
				<LogsFilterComponent
					showFilter={this.showFilter}
					showFilterBlock={this.state.showFilterBlock}
					todayDate={this.state.todayDate}
					onInputChange={this.onInputChange}
					filterLogs={this.filterLogs}
					resetLogsFilter={this.resetLogsFilter}
					errorMessage={this.state.errorMessage}
					eventFromDate={this.state.eventFromDate}
					eventToDate={this.state.eventToDate}
					username={this.state.username}
					eventname={this.state.eventname}
					coriserialno={this.state.coriserialno}
					disableFilter={this.state.disableFilter}
					disableReset={this.state.disableReset}
				/>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		state,
	};
}
export default connect(mapStateToProps)(LogsFilterContainer);
