import React, { Component } from 'react';
import { actions } from '../../helpers/messages';
import { convertUTCToLocalDateTime } from '../../helpers/GlobalFunctions';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock';
import { Roles } from '../../helpers/constants';

export default class UserDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.myFormRef = React.createRef();
    }

    /**
     * @description Function to disable edit mode while restting the form
     * @memberof UserDetailsComponent
     */
    /* istanbul ignore next  */
    cancelEdit = () => {
        this.myFormRef.reset();
        this.props.toggleEditMode();
    }

    /**
	 * @description Function to get user role
	 * @param user Row element for each user
 	*/
  /* istanbul ignore next  */
    getUserRole(userGroup) {
		if (userGroup) {
			const userRole = Roles.find(x => x.cognitoGroupName === userGroup);
			return userRole ? userRole.label : userGroup;
		}
		
		return 'N/A';
	}

    render() {
        const { user, loading, userError, editMode, toggleEditMode, editObj, handleInputChange, onEnterPress, showSuccessMsg, showErrorMsg, formErrors, saveUser, handleCancel } = this.props;
        const userRole = user && user.group ? this.getUserRole(user.group) : 'N/A';

        return (
            <>
                {loading ? <div className="loading-overlay-upload add-surgeon-overlay"><span>{actions.load}</span></div> :
                    user ?
                        <div className="customer-fields surgeon-details">
                            <div className="case-details-container" data-testid="userDetails">
                                <div className="card-body py-0">
                                    <form onSubmit={onEnterPress} noValidate ref={(el) => this.myFormRef = el}>
                                        <div className="row">
                                            {showSuccessMsg ?
                                                <div className="col-sm-12">
                                                    <div className="customer-save-success-msg">
                                                        <p>{showSuccessMsg}</p>
                                                    </div>
                                                </div>
                                                : ''}
                                            {showErrorMsg ?
                                                <div className="col-sm-12">
                                                    <div className="customer-save-error-msg">
                                                        <p>{showErrorMsg}</p>
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                        <fieldset className="customer-fields" disabled={!editMode}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="firstName" className="col-sm-4 col-form-label shrink-label">First Name{editMode ? <span className="red">*</span> : ''}</label>
                                                        <div className="col-sm-8">
                                                            <input
                                                                type="text"
                                                                id="firstName"
                                                                data-testid="firstName"
                                                                autoComplete="off"
                                                                maxLength={50}
                                                                className={`form-control ${formErrors && formErrors.firstName ? 'has-error' : ''}`}
                                                                value={editObj && editObj.firstName ? editObj.firstName : ''}
                                                                onChange={(e) => handleInputChange('firstName', e.target.value)}
                                                            />
                                                            {formErrors && formErrors.firstName ?
                                                                <div className="customer-error-msg">
                                                                    <p>{formErrors.firstName}</p>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="lastName" className="col-sm-4 col-form-label">Last Name{editMode ? <span className="red">*</span> : ''}</label>
                                                        <div className="col-sm-8">
                                                            <input
                                                                type="text"
                                                                id="lastName"
                                                                data-testid="lastName"
                                                                maxLength={50}
                                                                autoComplete="off"
                                                                className={`form-control ${formErrors && formErrors.lastName ? 'has-error' : ''}`}
                                                                value={editObj && editObj.lastName ? editObj.lastName : ''}
                                                                onChange={(e) => handleInputChange('lastName', e.target.value)}
                                                            />
                                                            {formErrors && formErrors.lastName ?
                                                                <div className="customer-error-msg">
                                                                    <p>{formErrors.lastName}</p>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group row">
                                                        <label htmlFor="email" className="col-sm-4 col-form-label shrink-label">Email{editMode ? <span className="red">*</span> : ''}</label>
                                                        <div className="col-sm-8">
                                                            <input
                                                                type="email"
                                                                id="email"
                                                                data-testid="email"
                                                                autoComplete="off"
                                                                className={`form-control ${formErrors && formErrors.email ? 'has-error' : ''}`}
                                                                value={editObj && editObj.email ? editObj.email : ''}
                                                                onChange={(e) => handleInputChange('email', e.target.value)}
                                                            />
                                                            {formErrors && formErrors.email ?
                                                                <div className="customer-error-msg">
                                                                    <p>{formErrors.email}</p>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Role</label>
                                                        <div className="col-sm-8">
                                                            <span className="form-control disabled">{userRole}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label shrink-label">Username</label>
                                                    <div className="col-sm-8">
                                                        <span className="form-control disabled">{user.userName ? user.userName : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label">Last Seen</label>
                                                    <div className="col-sm-8">
                                                        <span className="form-control disabled">{user.lastLogin ? convertUTCToLocalDateTime(user.lastLogin) : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label shrink-label">Date Created</label>
                                                    <div className="col-sm-8">
                                                        <span className="form-control disabled">{user.userCreatedDate ? convertUTCToLocalDateTime(user.userCreatedDate) : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-4 col-form-label">Last Password Reset</label>
                                                    <div className="col-sm-8">
                                                        <span className="form-control disabled">{user.lastPasswordModified ? convertUTCToLocalDateTime(user.lastPasswordModified) : 'N/A'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 text-end">
                                                {!editMode ?
                                                    <>
                                                        <button type="button" id="edit-btn" className="btn btn-primary case-action-btn me-0" onClick={toggleEditMode}>Edit</button>
                                                        <button type="button" className="btn btn-secondary ms-2" id="cancel" onClick={handleCancel}>Close</button>
                                                    </>
                                                    : ''}
                                                {editMode ?
                                                    <>
                                                        <button type="button" id="save-btn" className="btn btn-primary case-action-btn" onClick={saveUser}>Save</button>
                                                        <button type="button" id="cancel-btn" className="btn btn-secondary me-0" onClick={this.cancelEdit}>Cancel</button>
                                                    </>
                                                    : ''}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="customer-fields">
                            <div className="case-details-container">
                                <div className="card-body">
                                    {userError ? <ErrorMsgBlockComponent errorObject={userError} /> : <ErrorMsgBlockComponent noData={true} />}
                                </div>
                            </div>
                        </div>
                }
            </>
        );
    }
}
