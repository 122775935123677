import React, { Component } from 'react';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { BasicTextEditorToolbarConfig } from '../helpers/constants';
import htmlToDraft from 'html-to-draftjs';

export default class TextEditor extends Component {
	constructor(props) {
		super(props);
		var editorState;
		var maxCharCountError = false;
		var stateObj = {
			charCount: 0,
			wordCount: 0,
			charsLeft: props.charLimit,
			charsExceedBy: 0,
			plainTextData: ''
		}

		if (props.data) {
			const blocksFromHtml = htmlToDraft(props.data);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentDataState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			editorState = EditorState.createWithContent(contentDataState);
			stateObj = { ...this.getContentDetails(contentDataState) };
			maxCharCountError = stateObj.charCount > props.charLimit;
		} else {
			editorState = EditorState.createEmpty();
		}
		this.state = {
			...stateObj,
			editorState: editorState,
			error: {
				maxCharCountError
			}
		};
	}

	/** 
	 * @description function to break down the content and get the details
	 *  @param  {currentContent} // content state
	 *  @memberof TextEditor
	 */
	/* istanbul ignore next  */
	getContentDetails = (currentContent) => {
		const { charLimit } = this.props;
		if (!currentContent) {
			return { plainTextData: '', charCount: 0, wordCount: 0, charsLeft: charLimit, charsExceedBy: 0 };
		}
		var plainTextData = currentContent.getPlainText();
		var charCount = plainTextData ? plainTextData.length : 0;
		var wordCount = plainTextData.split(' ').length;
		var charsLeft = charLimit - charCount;
		var charsExceedBy = charCount - charLimit;

		return { plainTextData, charCount, wordCount, charsLeft, charsExceedBy };
	}

	/** 
	 * @description function to handle every input change in the editor and calculate number of characters typed
	 *  @param  {editorState} //editor instance bound to this component instance
	 *  @memberof TextEditor
	 */
	/* istanbul ignore next  */
	onEditorStateChange = (editorState) => {
		const currentContent = editorState.getCurrentContent();
		var contentDetails = this.getContentDetails(currentContent);
		var editorData = contentDetails && contentDetails.plainTextData ?
		 draftToHtml(convertToRaw(currentContent)) : '';
		this.setState({
			...contentDetails,
			editorState,
			editorData,
			error: {
				maxCharCountError: contentDetails.charCount > this.props.charLimit
			}
		});
		this.props.onChange({ editorData, plainTextData: contentDetails.plainTextData });
	}

	render() {
		const { editorState, charCount, error } = this.state;
		const { className, charLimit, readOnly } = this.props;
		return (
			<>
				<Editor
					readOnly={readOnly}
					editorState={editorState}
					wrapperClassName={`editor-wrapper ${className}`}
					editorClassName="editor-body"
					toolbarClassName={`editor-toolbar ${readOnly ? 'disabled-toolbar' : ''}`}
					toolbar={BasicTextEditorToolbarConfig}
					onEditorStateChange={this.onEditorStateChange}
				/>
				{!readOnly && charLimit ?
					<div className={error && error.maxCharCountError ? "editor-char-count error-msg" : "editor-char-count"}>
						<p className="m-0 fs-8-rem">{`${charCount}/${charLimit} characters`}</p>
					</div>
					: ''}
			</>
		)
	}
}