import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegionsDetailsComponent from './RegionDetails';
import { validateRegionFields, validateAlphabetic, validateAlphanumericNewLine } from './ValidateRegion';
import { errorMessages, regionMessages, loggerEventName, loggerEventTypes, loggerEventOutcome } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { updateRegionDetails } from '../../services/java/java-services';

let startDate;
class RegionDetailsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionDetails: props.regionDetails ? {...props.regionDetails} : null,
            editMode: false,
            descCharCount:props?.regionDetails?.description?.length ? props.regionDetails.description.length : 0,
            descCharLimit: 45,
            nameCharCount: props?.regionDetails?.region?.length ? props.regionDetails.region.length : 0,
            nameCharLimit: 20,
            loading: false,
            showSuccessMsg: props?.regionDetails?.newlyAdded ? regionMessages.regionCreated : '',
            showErrorMsg: '',
            successMessage: '',
            regionError: null,
            errors: {
                region: '',
                description: ''

            },
            editObj: {
                id: props?.regionDetails?.id,
                region: props?.regionDetails?.region,
                description: props?.regionDetails?.description
            }
        }
    }
    componentDidMount() {
        startDate = new Date();
        window.addEventListener('beforeunload', this.timeSpentLogger);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
    }

    /**
    * @description function to handle application logs for time spent on the page
    * @param {*}
    * @memberof RegionDetailsContainer
    */
    timeSpentLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.regionDetails,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description Function to handle input changes
     * @param  value Value of the input
     * @param  field Input field region
     * @memberof RegionDetailsContainer
     */
    handleInputChange = (field, value) => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '', regionSaveError: null });
        var errorMessage = !value ? regionMessages[field] : '';
        var descCharCount = this.state.descCharCount;
        var nameCharCount = this.state.nameCharCount;
        var stateObj = { [field]: value };

        if (field === 'region' && value) {
            const regionNameCheck = validateAlphabetic(value);
            errorMessage = regionNameCheck.error;
            nameCharCount = value ? value.length : 0;
            if (nameCharCount > this.state.nameCharLimit) {
                errorMessage = errorMessages.fileUpload.charCount(nameCharCount, this.state.nameCharLimit);
            }
        } else if (field === 'region' && !value) {
            nameCharCount = 0;
        }
        if (field === 'description' && value) {
            const userDescriptionCheck = validateAlphanumericNewLine(value);
            errorMessage = userDescriptionCheck.error;
            descCharCount = value ? value.length : 0;
            if (descCharCount > this.state.descCharLimit) {
                errorMessage = errorMessages.fileUpload.charCount(descCharCount, this.state.descCharLimit);
            }
        } else if (field === 'description' && !value) {
            descCharCount = 0;
        }

        stateObj['nameCharCount'] = nameCharCount;
        stateObj['descCharCount'] = descCharCount;
        this.setState(prevState => {
            return {
                ...stateObj,
                [field]: value,
                editObj: {
                    ...prevState.editObj,
                    [field]: value
                },
                errors: {
                    ...prevState.errors,
                    [field]: errorMessage
                }
            }
        });
    };

    /** 
     * @description Function submit form by hitting enter key
     *  @param e Keypress event
     *  @memberof RegionDetailsContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveRegion();
        }
    }

    /**
     * @description Function to validate fields at the time of submit
     * @returns Boolean value if there are any errors
     * @memberof RegionDetailsContainer
     */
    validateFields = () => {
        const { editObj } = this.state;
        const regionField = ['region'];
        let { errorFlag, errors } = validateRegionFields({ editObj: editObj, errors: this.state.errors }, regionField);

        // Name Validation
        if (!errorFlag && editObj.region) {
            let region = validateAlphabetic(editObj.region);
            errorFlag = region.errorFlag;
            errors['region'] = region.error;
        }
        // Description Validation
        if (this.state.editObj.description && this.state.descCharCount > this.state.descCharLimit) {
            errors.description = errorMessages.fileUpload.charCount(this.state.descCharCount, this.state.descCharLimit);
            this.setState({ errors })
            return errors;
        } else if (!errorFlag && editObj.description) {
            let description = validateAlphanumericNewLine(editObj.description);
            errorFlag = description.errorFlag;
            errors['description'] = description.error;
            this.setState({ errors })
        }
        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }
        return errorFlag;
    }
    /**
     * @description Function to handle state when edit is cancelled
     * @param region Region Details
     * @memberof RegionDetailsContainer
     */
    getEditObject(region) {
        const editObj = {
            id: region?.id,
            region: region?.region,
            description: region?.description
        };
        const nameCharCount = editObj?.region?.length ? editObj.region.length : 0;
        const descCharCount = editObj?.description?.length ? editObj.description.length : 0;
        return {
            editObj,
            nameCharCount,
            descCharCount,
            errors: {
                region: '',
                description: '',
            }
        };
    }

    /**
    * @description Function to enable/disable edit mode
    * @memberof RegionDetailsContainer
    */
    toggleEditMode = (state = true) => {
        this.setState(prevState => {
            return {
                ...this.getEditObject(prevState.regionDetails),
                editMode: !prevState.editMode
            };
        });
    }

    /**
     * @description function to validate and save user details
     * @param {*}
     * @memberof RegionDetailsContainer
     */
    saveRegion = () => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '' });
        const formHasErrors = this.validateFields();
        if (!formHasErrors) {
            this.updateRegionAPI();
        }
    }

    /**
     * @description function to update region
     * @param {*}
     * @memberof RegionDetailsContainer
     */
    updateRegionAPI = () => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '', loading: true });
        const { regionDetails, editObj } = this.state;

        // Check if editObj has changed
        if (editObj.region === regionDetails.region && editObj.description === regionDetails.description) {
            this.setState({ showErrorMsg: regionMessages.noChanges, loading: false, regionDetails: editObj });
            return;
        }

        updateRegionDetails(editObj, (err, res) => {
            if (err) {
                const errMsg = err && err.message ? err.message : regionMessages.saveError;
                this.setState({ loading: false, showErrorMsg: errMsg });
                this.applicationLogger({ outcome: loggerEventOutcome.failure, message: `${errMsg} (For ${regionDetails.region})` });
                this.props.regionUpdated(false);
            } else {
                this.setState({
                    showSuccessMsg: regionMessages.regionEdited,
                    showErrorMsg: '',
                    loading: false,
                    editMode: false,
                    regionDetails: res.data ? { ...res.data } : null,
                    ...this.getEditObject(res?.data)
                });
                this.applicationLogger({ outcome: loggerEventOutcome.success, message: regionMessages.regionEdited });
                this.props.regionUpdated(true);
            }
        });
    }
    /**
     * @description function to log region update event
     * @memberof RegionDetailsContainer
     */
    /* istanbul ignore next  */
    applicationLogger = (params) => {
        const loggerObj = {
            "EventOutcome": params.outcome,
            "EventType": loggerEventTypes.update,
            "EventName": loggerEventName.regionUpdate,
            "Content": {
                "Data": params.message
            }
        }
        logger(loggerObj);
    }

    render() {
        return (
            <RegionsDetailsComponent
                loading={this.state.loading}
                regionError={this.state.regionError}
                editMode={this.state.editMode}
                toggleEditMode={this.toggleEditMode}
                editObj={this.state.editObj}
                handleInputChange={this.handleInputChange}
                onEnterPress={this.onEnterPress}
                showSuccessMsg={this.state.showSuccessMsg}
                showEmailSuccessMsg={this.state.showEmailSuccessMsg}
                showErrorMsg={this.state.showErrorMsg}
                formErrors={this.state.errors}
                saveRegion={this.saveRegion}
                handleCancel={this.props.handleCancel}
                description={this.state.description}
                descCharCount={this.state.descCharCount}
                descCharLimit={this.state.descCharLimit}
                nameCharCount={this.state.nameCharCount}
                nameCharLimit={this.state.nameCharLimit}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(RegionDetailsContainer);