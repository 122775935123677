import React, { Component } from 'react';
import CustomModal from '../../shared/CustomModal';
import AddRegionContainer from '../RegionsDetails/AddRegion/index';
import RegionDetailsContainer from '../RegionsDetails';
export default class AddRegionComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showNewRegionModal: false,
			showRegionDetailsModal: false,
			region: null,
			fromDashboard: props.fromDashboard,
			successMessage: ''
		}
	}
	/* istanbul ignore next */
	componentDidMount() {
		if (this.props.fromDashboard) {
			this.toggleNewRegionModal();
		}
	}

	/**
	 * @description Funtion to toggle new region modal
	 * @memberof AddRegionModalComponent
	 */
	toggleNewRegionModal = () => {
		this.setState(prevState => {
			return { showNewRegionModal: !prevState.showNewRegionModal }
		});
	}

	/**
	 * @description Funtion to toggle region details modal
	 * @memberof AddRegionModalComponent
	 */
	/* istanbul ignore next  */
	toggleRegionDetailsModal = (region) => {
		this.setState(prevState => {
			return { region: region, showRegionDetailsModal: !prevState.showRegionDetailsModal }
		});
	}

	/**
	 * @description Funtion to explicitly close new region modal
	 * @memberof AddRegionModalComponent
	 */
	closeNewRegionModal = () => {
		this.setState({ showNewRegionModal: false })
	}

	/**
	 * @description Funtion to explicitly close region details modal
	 * @memberof AddRegionModalComponent
	 */
	/* istanbul ignore next  */
	closeRegionDetailsModal = () => {
		this.setState({ showRegionDetailsModal: false })
	}

	/**
	 * @description Funtion to handle situation when region added
	 * @memberof AddRegionModalComponent
	 */
	/* istanbul ignore next  */
	regionAdded = (region) => {
		this.closeNewRegionModal();
		setTimeout(() => {
			this.toggleRegionDetailsModal({
				...region,
				newlyAdded: true
			});
			this.props.regionUpdated(true);
		}, 0);
	}

	render() {
		const { showNewRegionModal, showRegionDetailsModal, region } = this.state;
		return (
			<>
				<button type="button" data-testid="addRegionBtn" id="addRegionBtn" className="btn btn-primary mt-3" onClick={(e) => { e.preventDefault(); this.toggleNewRegionModal(); }}>Add New</button>
				{/* Modal to add new region */}
				<CustomModal
					id="add-region-modal"
					show={showNewRegionModal}
					title="Add New Region"
					isComponent={true}
					dialogClassName="region-details-dialog"
					closeAction={this.closeNewRegionModal}>
					<AddRegionContainer id="add-region-container" handleCancel={this.closeNewRegionModal} regionAdded={this.regionAdded} />
				</CustomModal>
				{/* Modal to show region details */}
				<CustomModal
					id="region-details-modal"
					show={showRegionDetailsModal}
					title="Region Details"
					isComponent={true}
					dialogClassName="region-details-dialog"
					closeAction={this.closeRegionDetailsModal}>
					<RegionDetailsContainer
						regionDetails={region}
						regionUpdated={this.props.regionUpdated}
						handleCancel={this.closeRegionDetailsModal}
					/>
				</CustomModal>
			</>
		);
	}
}
