import React, { Component } from 'react';
import { connect } from 'react-redux';
import AWS from 'aws-sdk';
import { getUserRoleType, newS3 } from '../../services/aws/aws-services';
import environment from '../../config/environment';
import FileUploadComponent from './FileUpload';
import { updateFileMetadata } from '../../actions/uploadAction';
import { logoutService, getUserName } from '../../services/aws/aws-services';
import { errorMessages, successMessages, loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { DAConfig, RolePermission } from '../../helpers/constants';
import { fileVersionDuplicateCheck, getAllAssetTypes, getAllCustomers, getAllRegionsPromise } from '../../services/java/java-services';
import { validateAlphanumericWithDot, validateDAFields, validateDAVersion } from './ValidateFile';
let startDate;

class FileUploadContainer extends Component {
	constructor(props) {
		super(props);
		if (!(props.location && props.location.state && props.location.state.fromListPage === true)) {
			props.history.push('/assets');
		}
		this.state = {
			uploadError: '',
			fetchError: null,
			isLoading: false,
			uploadSuccess: false,
			showProgress: false,
			selectedFiles: '',
			fileExtentionError: false,
			maxFileSizeError: false,
			uploadProgress: 0,
			metaDataUpdateFailError: '',
			fileName: '',
			selectedFileExistInS3: false,
			selectedFileVersionExists: false,
			disclaimerCheckBox: false,
			cancelFileUploadStatus: false,
			enableUploadBtn: true,
			enableCancelBtn: false,
			fileUploadedId: '',
			errors: {
				fileName: ''
			},
			loggerObj: {
				"EventOutcome": loggerEventOutcome.success,
				"EventType": loggerEventTypes.read,
				"EventName": loggerEventName.fileUpload,
				"StartDate": new Date()
			},
			allRegions: null,
			allCustomers: null,
			assets: null,
			assettype: '',
			regions: [],
			customers: [],
			md5Checksum: '',
			version: '',
			partNo: '',
			status: true,
			description: '',
			descCharCount: 0,
			descCharLimit: DAConfig.descCharLimit,
			releaseNotes: '',
			releaseCharCount: 0,
			releaseCharLimit: DAConfig.releaseNoteCharLimit,
			regionError: null,
			customerError: null,
			assetsError: null,
			duplicateError: null,
			fileMetadataObj: {
				assettype: '',
				version: '',
				partNo: '',
				regions: '',
				customers: '',
				releaseNotes: '',
				description: '',
				status: true,
				disclaimerCheckBox: false,
			}
		}
		this.resetStoreValue();
	}

	componentDidMount() {
		// initialize the start date on page load
		startDate = new Date();
		window.addEventListener('beforeunload', this.applicationLogger);
		this.getAllData();
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.applicationLogger);
		this.applicationLogger();
	}

	/**
	 * @description Function to fetch data for dropdowns
	 * @memberof FileUploadContainer
	 */
	getAllData = () => {
		var regionParams = { sortorder: 'asc', column: 'region', page: 'upload' };
		var assetTypesRes = {};
		var regionRes = {};
		var customerRes = {};
		this.setState({ isLoading: true });
		Promise.allSettled([
			getAllAssetTypes(),
			getAllRegionsPromise(regionParams),
			getAllCustomers()
		]).then(res => {
			// ASSET TYPES RESPONSE
			if (res[0] && res[0].status === 'fulfilled') {
				assetTypesRes = this.handleAssetTypesResponse(res[0].value);
			} else {
				assetTypesRes = { assetsError: res[0].reason };
			}

			// REGIONS RESPONSE
			if (res[1] && res[1].status === 'fulfilled') {
				regionRes = this.handleRegionResponse(res[1].value);
			} else {
				regionRes = { regionError: res[1].reason };
			}

			// CUSTOMERS RESPONSE
			if (res[2] && res[2].status === 'fulfilled') {
				customerRes = this.handleCustomersResponse(res[2].value);
			} else {
				customerRes = { customerError: res[2].reason };
			}

			this.setState({
				isLoading: false,
				...assetTypesRes,
				...regionRes,
				...customerRes
			});
		}).catch(err => {
			this.setState({ isLoading: false, fetchError: err });
		})
	}

	/**
	 * @description Function to fetch regions
	 * @memberof FileUploadContainer
	 */
	handleRegionResponse = (res) => {
		const regions = res?.data?.regionlist?.length ? res.data.regionlist : [];
		var regionSelectFormat = [];
		if (regions?.length) {
			regionSelectFormat = regions.map(x => {
				return {
					label: x.region,
					value: x.id
				}
			})
		}
		return {
			regionError: null,
			allRegions: regionSelectFormat
		}
	}

	/**
	 * @description Function to fetch customers
	 * @memberof FileUploadContainer
	 */
	handleCustomersResponse = (res) => {
		const customers = res?.data?.length ? res.data : [];
		var customerSelectFormat = [];
		if (customers?.length) {
			customerSelectFormat = customers.map(x => {
				return {
					label: `${x.customername} (${x.customerid})`,
					value: x.customerid
				}
			})
		}
		return {
			customerError: null,
			customers: customerSelectFormat,
			allCustomers: customerSelectFormat,
			fileMetadataObj: {
				...this.state.fileMetadataObj,
				customers: customerSelectFormat
			}
		}
	}

	/**
	 * @description Function to fetch asset types
	 * @memberof FileUploadContainer
	 */
	handleAssetTypesResponse = (res) => {
		const assets = res && res.data && res.data.assetlist && res.data.assetlist.length ? res.data.assetlist : [];
		return { assetsError: null, assets };
	}

	/**
	 * @description function to handle application logs
	 * @param {*}
	 * @memberof FileUploadContainer
	 */
	applicationLogger(key) {
		// calculate the time since we loaded this page
		const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.fileUpload,
			"Content": {
				"TimeSpent": timeSinceLoad
			}
		}
		logger(loggerObj);
	}

	/**
	 * @description function for file upload form 
	 * @param {*}
	 * @memberof FileUploadContainer
	 */
	formValidation() {
		var initialErrors = { ...this.state.errors }
		const { maxFileSizeError,fileExtentionError, fileMetadataObj, selectedFiles, releaseCharCount, releaseCharLimit, descCharCount, descCharLimit } = this.state;
		const mandatoryFields = ['assettype', 'fileName', 'version', 'partNo', 'regions', 'customers', 'disclaimerCheckBox'];
		let { errorFlag, errors } = validateDAFields({ editObj: fileMetadataObj, errors: initialErrors }, mandatoryFields);

		// File validation
		if (!selectedFiles) {
			errorFlag = true;
		}
		errors['fileName'] = selectedFiles ? '' : errorMessages.fileUpload.fileName;

		// Version validation
		if (!errorFlag && fileMetadataObj.version) {
			let version = validateDAVersion(fileMetadataObj.version);
			errorFlag = version.errorFlag;
			errors['version'] = version.error;
		}

		// Part No validation
		if (!errorFlag && fileMetadataObj.partNo) {
			let partNo = validateAlphanumericWithDot(fileMetadataObj.partNo);
			errorFlag = partNo.errorFlag;
			errors['partNo'] = partNo.error;
		}

		// Release Notes Validation
		if (!errorFlag && fileMetadataObj.releaseNotes?.editorData) {
			errorFlag = releaseCharCount > releaseCharLimit;
			errors['releaseNotes'] = errorFlag ? errorMessages.fileUpload.charCount(releaseCharCount, releaseCharLimit) : '';
		}

		// Description Validation
		if (!errorFlag && fileMetadataObj.description) {
			errorFlag = fileMetadataObj.description && descCharCount > descCharLimit;
			errors['description'] = errorFlag ? errorMessages.fileUpload.charCount(descCharCount, descCharLimit) : '';
		}

		if (errorFlag) {
			this.setState(prevState => {
				return {
					fileExtentionError: !selectedFiles ? false : fileExtentionError,
					maxFileSizeError: !selectedFiles ? false : maxFileSizeError,
					errors: {
						...prevState.errors,
						...errors
					}
				}
			})
		}

		return errorFlag;
	}

	/**
	 * @description function to verify file properties like size, name etc..
	 * @param  {} selectedFile
	 * @memberof FileUploadContainer
	 */
	handleChange = async (selectedFile) => {
		if (!selectedFile[0]) return;
		var errors = { ...this.state.errors }
		var stateObj = {};
		let getFileName = selectedFile[0]['name'];
		let getExtension = getFileName.split('.').pop() || '';
		let getFileSize = selectedFile[0]['size'];
		let maxFileSize = 5368709120; // 5GB size (1GB = 1073741824)
		stateObj = {
			fileName: getFileName,
			enableUploadBtn: true,
			uploadError: '',
			uploadProgress: 0
		};
		// to reset validation
		errors.fileName = '';
		if (getExtension && DAConfig.fileExtensions.indexOf(getExtension.toString().toLowerCase()) > -1) {
			stateObj['selectedFiles'] = selectedFile;
			stateObj['uploadSuccess'] = false;
			stateObj['fileExtentionError'] = false;
			stateObj['maxFileSizeError'] = false;
			stateObj['selectedFileExistInS3'] = false;
		} else {
			stateObj['fileExtentionError'] = true;
		}
		if (getFileSize > maxFileSize && !stateObj.fileExtentionError) {
			stateObj['maxFileSizeError'] = true;
		}
		this.setState(prevState => {
			return {
				...stateObj,
				fileMetadataObj: {
					...prevState.fileMetadataObj,
					fileName: getFileName
				},
				errors: {
					...prevState.errors,
					...errors
				}
			}
		})
		this.resetStoreValue();
	}

	/**
	 * @description Function to handle input changes
	 * @param  value Value of the input
	 * @param  field Input field name
	 * @memberof FileUploadContainer
	 */
	handleInputChange = (field, value) => {
		var errorMessage = !value ? errorMessages.fileUpload[field] : '';
		var descCharCount = 0;
		var releaseCharCount = 0;
		var stateObj = { [field]: value };
		switch (field) {
			case 'description':
				descCharCount = value ? value.length : 0;
				stateObj['descCharCount'] = descCharCount;
				if (descCharCount > this.state.descCharLimit) {
					errorMessage = errorMessages.fileUpload.charCount(descCharCount, this.state.descCharLimit)
				}
				break;
			case 'releaseNotes':
				releaseCharCount = value?.plainTextData?.length ? value.plainTextData.length : 0;
				stateObj['releaseCharCount'] = releaseCharCount;
				if (releaseCharCount > this.state.releaseCharLimit) {
					errorMessage = errorMessages.fileUpload.charCount(releaseCharCount, this.state.releaseCharLimit)
				}
				break;
			case 'regions':
				errorMessage = value && value.length ? '' : errorMessages.fileUpload.regions;
				break;
			case 'customers':
				errorMessage = value && value.length ? '' : errorMessages.fileUpload.customers;
				break;
			case 'version':
				let versionCheck = validateDAVersion(value);
				errorMessage = versionCheck.error;
				break;
			case 'partNo':
				let partNoCheck = validateAlphanumericWithDot(value);
				errorMessage = partNoCheck.error;
				break;

			default:
				break;
		}
		this.setState(prevState => {
			return {
				...stateObj,
				selectedFileVersionExists: false,
				fileMetadataObj: {
					...prevState.fileMetadataObj,
					...stateObj
				},
				errors: {
					...prevState.errors,
					[field]: errorMessage
				}
			}
		});
	}

	/** 
	 * 	@description function to handle manage file upload
	 *  @param  {} event
	 *  @memberof FileUploadContainer
	 */
	createManagedUpload = async () => {
		const { dispatch } = this.props;
		let bucketName = environment.s3BucketName;
		let file = this.state.selectedFiles;
		let userName = getUserName();
		let filepath = file[0]['name'];
		let fileSize = file[0]['size'];

		var uploadParam = {
			partSize: 5 * 1024 * 1024, queueSize: 4,
			params: {
				Bucket: bucketName,
				Key: filepath,
				Body: file[0],
				Metadata: {
					userid: userName
				}
			}
		}

		var upload = new AWS.S3.ManagedUpload(uploadParam);
		upload.send((err, data) => {
			if (err) {
				let errorMessage = 'User has cancelled the file upload, please try again.';
				this.setState({ 'uploadError': errorMessage, isLoading: false, uploadProgress: 0, enableCancelBtn: false, showProgress: false });
				this.deleteFileNameInS3();
			} else {
				if (!this.state.cancelFileUploadStatus) {
					this.setState({ cancelFileUploadStatus: false, uploadError: '' });
					const { fileMetadataObj, allCustomers, allRegions } = this.state;

					var fileMetadata = {
						filename: filepath,
						filepath: filepath,
						uploadStatus: 'Uploaded',
						filesize: fileSize,
						assetid: fileMetadataObj.assettype ? parseInt(fileMetadataObj.assettype) : '',
						daversion: fileMetadataObj.version,
						partNumber: fileMetadataObj.partNo,
						releasenote: fileMetadataObj.releaseNotes?.editorData ? fileMetadataObj.releaseNotes.editorData : '',
						comments: fileMetadataObj.description,
						status: fileMetadataObj.status,
						isConsent: fileMetadataObj.disclaimerCheckBox
					}

					if (fileMetadataObj.regions?.length && allRegions && allRegions.length && fileMetadataObj.regions?.length !== allRegions.length) {
						fileMetadata['regionIds'] = fileMetadataObj.regions.map(x => x.value);
					} else {
						fileMetadata['allregion'] = true;
					}

					if (fileMetadataObj.customers?.length && allCustomers && allCustomers.length && fileMetadataObj.customers?.length !== allCustomers.length) {
						fileMetadata['customerIds'] = fileMetadataObj.customers.map(x => x.value);
					} else {
						fileMetadata['allcustomer'] = true;
					}

					// update metadata to DB
					dispatch(updateFileMetadata(fileMetadata));
					this.setState({
						selectedFiles: '',
						fileName: '',
						disclaimerCheckBox: false,
						isLoading: false,
						uploadProgress: 0,
						uploadError: '',
						showProgress: false,
						fileMetadataObj: {
							...fileMetadataObj,
							disclaimerCheckBox: false
						}
					});
				} else {
					this.deleteFileNameInS3();
				}
			}
		})
		upload.on('httpUploadProgress', (progress) => {
			var uploaded = parseInt((progress.loaded * 100) / progress.total);
			this.setState({ enableCancelBtn: true });
			if (!this.state.cancelFileUploadStatus) {
				this.setState({ uploadProgress: uploaded });
			} else {
				upload.abort(upload)
			}
		});
	}

	/**
	 * @description function to cancel file upload
	 * @param  {} selectedFile
	 * @memberof FileUploadContainer
	 */
	cancelFileUpload = async () => {
		this.setState({ cancelFileUploadStatus: true, uploadProgress: 0, enableCancelBtn: false, enableUploadBtn: true, uploadError: 'User has cancelled the file upload, please try again.' });
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.read,
			"EventName": loggerEventName.fileUpload,
			"Content": {
				"Data": successMessages.fileUploadCanceled
			}
		}
		logger(loggerObj);
	}

	/** 
	 * @description function to verify selected file name exits in s3 bucket
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	verifyFileNameInS3 = async () => {
		let bucketName = environment.s3BucketName;
		let s3 = await newS3(bucketName);
		let file = this.state.selectedFiles;
		let filepath = file[0]['name'];
		let mediaKey = filepath;
		let param = {
			Key: mediaKey
		};
		await s3.headObject(param, (err, res) => {
			if (err) {
				let errorMessage;
				if (err.message && err.message === 'Missing credentials in config') {
					errorMessage = errorMessages.sessionTimeOut;
					this.sessionTimeOut();
					this.setState({ uploadError: errorMessage, selectedFileExistInS3: false, enableUploadBtn: false });
				} else {
					this.createManagedUpload();
				}
			} else {
				this.setState({ selectedFileExistInS3: true, isLoading: false, enableUploadBtn: true, showProgress: false })
			}
		});
	}

	/** 
	 * @description function to verify selected file is unique
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	checkVersionDuplicate = () => {
		const { assettype, version } = this.state;
		this.setState({ isLoading: true, enableUploadBtn: false, showProgress: true });
		const params = {
			assetid: parseFloat(assettype),
			daversion: version
		}

		fileVersionDuplicateCheck(params, (err, res) => {
			if (err) {
				this.setState({ uploadError: err.message || errorMessages.generic, enableUploadBtn: true, selectedFileVersionExists: false });
			} else {
				if (res && res.data && res.data.isDuplicate === true) {
					this.setState({ selectedFileVersionExists: true, uploadError: errorMessages.fileUpload.duplicateVersion, isLoading: false, enableUploadBtn: true, showProgress: false });
				} else {
					this.setState({ selectedFileVersionExists: false, uploadError: '' });
					this.verifyFileNameInS3();
				}
			}
		})
	}

	/** 
	 * 	@description function to delete file in s3 bucket
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	deleteFileNameInS3 = async () => {
		let bucketName = environment.s3BucketName;
		let s3 = await newS3(bucketName);
		let file = this.state.selectedFiles;
		let filepath = file[0]['name'];
		let mediaKey = filepath;
		let param = {
			Key: mediaKey
		};
		await s3.deleteObject(param, (err, res) => { });
	}

	/** 
	 * 	@description function to handle session time out  
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	sessionTimeOut = () => {
		setTimeout(
			function () {
				this.logout();
			}
				.bind(this),
			3000);
	}

	/** 
	 * 	@description function to handle submit action 
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	uploadSubmit = () => {
		if (RolePermission.fileUpload.indexOf(getUserRoleType()) === -1) {
			this.setState({ cancelFileUploadStatus: true, uploadError: errorMessages.fileUpload.permissionDenied });
			return;
		}
		this.setState({ cancelFileUploadStatus: false, uploadError: '' });
		const formHasErrors = this.formValidation();
		this.resetStoreValue();
		if (!formHasErrors) {
			this.checkVersionDuplicate();
		}
	}

	/** 
	 * 	@description function to handle reset Input value after form submit 
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	resetInput = (selectedFile) => {
		this.resetStoreValue();
		selectedFile.target.value = null;
		this.setState({ uploadSuccess: false, fileName: '', selectedFiles: '' })
	}

	/** @description function to handle reset store value after form submit 
	 *  @param  {}
	 *  @memberof FileUploadContainer
	 */
	resetStoreValue() {
		const { state } = this.props;
		if (state.rootReducer && state.rootReducer.uploadReducer.res) {
			state.rootReducer.uploadReducer.res.update = '';
			this.setState({ isLoading: false, showProgress: false });
		}
	}

	/**
	 * @description function to handle logout functionality
	 * @param {*}
	 * @memberof FileUploadContainer
	 */
	logout = async () => {
		logoutService(function (err, res) { });
	};

	render() {
		const { state } = this.props;
		return (
			<FileUploadComponent
				onSubmit={this.uploadSubmit}
				onInputChange={this.handleChange}
				onFileUploadCancel={this.cancelFileUpload}
				enableCancelBtn={this.state.enableCancelBtn}
				cancelFileUploadStatus={this.state.cancelFileUploadStatus}
				onFileUploadResume={this.resumeFileUpload}
				enableUploadBtn={this.state.enableUploadBtn}
				fileExistInS3={this.state.selectedFileExistInS3}
				selectedFileVersionExists={this.state.selectedFileVersionExists}
				disclaimerCheckBox={this.state.disclaimerCheckBox}
				successMsgs={successMessages}
				isLoading={this.state.isLoading}
				showProgress={this.state.showProgress}
				uploadError={this.state.uploadError}
				formErrors={this.state.errors}
				fileName={this.state.fileName}
				metaDataUpdateStatus={state.rootReducer && state.rootReducer.uploadReducer.res ? state.rootReducer.uploadReducer.res.update : ''}
				metaDataUpdateError={state.rootReducer && state.rootReducer.uploadReducer.res ? state.rootReducer.uploadReducer.res.text : ''}
				fileExtentionError={this.state.fileExtentionError}
				maxFileSizeError={this.state.maxFileSizeError}
				uploadSuccess={this.state.uploadSuccess}
				uploadProgress={this.state.uploadProgress}
				downloadFile={this.downloadFile}
				resetInput={this.resetInput}
				onKeyDown={this.onKeyDown}
				loggerObj={this.state.loggerObj}
				assets={this.state.assets}
				allRegions={this.state.allRegions}
				regions={this.state.regions}
				allCustomers={this.state.allCustomers}
				customers={this.state.customers}
				status={this.state.status}
				descCharLimit={this.state.descCharLimit}
				descCharCount={this.state.descCharCount}
				releaseCharCount={this.state.releaseCharCount}
				releaseCharLimit={this.state.releaseCharLimit}
				handleInputChange={this.handleInputChange}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(FileUploadContainer);
