import React, { Component } from 'react';
import PasswordReset from './PasswordReset';
import { resetPasswordMessages, loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { matchExpression, passwordRegexMatch, passwordStrength } from '../../helpers/PasswordValidate';
import { activityUpdater } from '../../services/activity/activity-service';
import { changePassword, getUserName } from '../../services/aws/aws-services';
import { logger } from '../../services/logger/logger-service';

export default class PasswordResetContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            username: getUserName(),
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            errors: {},
            successMessage: '',
            pwdStrengthLabel: '',
            pwdStrengthProgress: '',
            pwdStrengthVarient: '',
            expMatch: {}
        };
    }

    /**
    * @description Function to handle changes in reset password form
    * @memberof PasswordResetContainer
    */
    handleChange = (value, field) => {
        var errors = { ...this.state.errors };
        errors.cognitoError = ''
        this.setState({ errors, successMessage: '' });
        if (field === 'oldPassword') {
            errors.oldPassword = '';
            this.setState({ errors, oldPassword: value });
        } else if (field === 'newPassword') {
            var strengthObj = passwordStrength(value, this.state.username);
            this.setState({ ...strengthObj, expMatch: {} });

            if (value && !passwordRegexMatch(value, this.state.username)) {
                errors.newPassword = '';
                errors.confirmPassword = '';
                errors.newPasswordMatch = true;
            } else {
                errors.newPasswordMatch = '';
            }
            if (value === '') {
                this.setState({ pwdStrengthLabel: '' });
            }
            this.setState({ errors, newPassword: value });
        } else if (field === 'confirmPassword') {
            errors.confirmPassword = '';
            this.setState({ errors, confirmPassword: value });
        }
    }

    /**
    * @description Function to handle blur event in reset password form
    * @memberof PasswordResetContainer
    */
    handleBlur = (value, field) => {
        this.handleChange(value, field);
        setTimeout(() => {
            if (value && field === 'newPassword') {
                var matchResults = matchExpression(value, this.state.expMatch, this.state.username);
                this.setState({ expMatch: matchResults });
            }
        }, 0);
    }

    /**
    * @description Function to submit reset password form
    * @memberof PasswordResetContainer
    */
    handleResetPassword = (event) => {
        event.preventDefault();
        this.setState({ successMessage: '' });
        if (this.validateResetPassInputs()) {
            const { oldPassword, newPassword } = this.state;
            this.resetPassword(oldPassword, newPassword);
        }
    }

    /**
    * @description Function to validate reset password form
    * @memberof PasswordResetContainer
    */
    validateResetPassInputs = () => {
        const { oldPassword, newPassword, confirmPassword, username } = this.state;
        let errors = {};
        let isValid = true;
        if (!oldPassword) {
            isValid = false;
            errors["oldPassword"] = resetPasswordMessages.error.oldPassword;
        } else if (!newPassword) {
            isValid = false;
            errors["newPassword"] = resetPasswordMessages.error.newPassword;
        } else if (!confirmPassword) {
            isValid = false;
            errors["confirmPassword"] = resetPasswordMessages.error.confirmPassword;
        } else if (typeof newPassword !== "undefined" && typeof confirmPassword !== "undefined") {
            this.handleBlur(newPassword, 'newPassword');
            if (!passwordRegexMatch(newPassword, username)) {
                isValid = false;
                errors["newPasswordMatch"] = true;
            }
            if (newPassword !== confirmPassword) {
                isValid = false;
                errors["confirmPassword"] = resetPasswordMessages.error.notMatch;
            }
        }
        this.setState({ errors });
        return isValid;
    }

    /**
     * @description function to submit the form on press of enter key
     * @param {*}
     * @memberof PasswordResetContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            this.handleResetPassword(e);
        }
    }

    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof PasswordResetContainer
     */
    applicationLogger(EventOutcome, message) {
        const loggerObj = {
            "EventOutcome": EventOutcome,
            "EventType": loggerEventTypes.update,
            "EventName": loggerEventName.passwordReset,
            "Content": {
                "Data": message
            }
        }
        logger(loggerObj);
    }

    /**
    * @description Function to call password reset service
    * @memberof PasswordResetContainer
    */
    resetPassword(oldPassword, newPassword) {
        var errors = { ...this.state.errors }
        this.setState({ loading: true, successMessage: '' });
        changePassword(oldPassword, newPassword)
            .then(result => {
                errors.cognitoError = '';
                errors.confirmPassword = '';
                this.applicationLogger(loggerEventOutcome.success, resetPasswordMessages.updatePassword);
                this.updateActivity();
                this.setState({
                    errors,
                    loading: false,
                    successMessage: resetPasswordMessages.updatePassword,
                    pwdStrengthLabel: '',
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });
            })
            .catch(error => {
                if (error.code === "NotAuthorizedException") {
                    errors.cognitoError = resetPasswordMessages.error.inValidPass;
                } else {
                    errors.cognitoError = error.message;
                }
                errors.confirmPassword = '';
                this.applicationLogger(loggerEventOutcome.failure, errors.cognitoError);
                this.setState({ loading: false, pwdStrengthLabel: '', errors: errors, oldPassword: '', newPassword: '', confirmPassword: '' });
            })
    }

    /**
     * @description function to handle user password reset activity
     * @memberof PasswordResetContainer
     */
    /* istanbul ignore next  */
    updateActivity = () => {
        if (this.state.username) {
            const activityObj = {
                lastPasswordResettime: new Date().toISOString(),
                userName: this.state.username
            }
            activityUpdater(activityObj);
        }
    }

    render() {
        return (
            <PasswordReset
                loading={this.state.loading}
                handleResetPassword={this.handleResetPassword}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                formErrors={this.state.errors}
                successMessage={this.state.successMessage}
                onEnterPress={this.onEnterPress}
                pwdStrengthLabel={this.state.pwdStrengthLabel}
                pwdStrengthProgress={this.state.pwdStrengthProgress}
                pwdStrengthVarient={this.state.pwdStrengthVarient}
                pwdMatchObj={this.state.expMatch}
                resetPwdErrMsg={resetPasswordMessages.error.passwordErrors}
            />
        )
    }
}
