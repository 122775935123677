import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { logoutService, getUserDetails, getUserRoleType, getUserName } from "../../services/aws/aws-services";
import IdleTimer from "react-idle-timer";
import { checkSessionStorage, removeSessionStorage } from '../../services/storage/storage-service';
import { logger } from '../../services/logger/logger-service';
import { loggerEventOutcome, loggerEventTypes, loggerEventName, loggerEventMessage } from '../../helpers/messages';
import { getImgSrc } from '../../helpers/GlobalFunctions';
import environment from '../../config/environment';
import { RolePermission } from '../../helpers/constants';
import { RoutesPerModule } from '../../constants/definedRoutes/definedRoutes';
class Sidemenu extends Component {
	constructor(props) {
		super(props);
		const { pathname } = this.props.location;
		const getUserDetail = getUserDetails();
		const userName = getUserName();
		this.state = {
			firstName: getUserDetail ? getUserDetail.given_name : "",
			lastName: getUserDetail ? getUserDetail.family_name : "",
			userName: userName,
			remaining: this.timeout,
			isIdle: false,
			lastActive: new Date(),
			elapsed: 0,
			navSelected: pathname,
			loggingOut: false
		};

		this.idleTimer = null;
		this.timeout = 1000 * 60 * 10; // 10 minute timeout

		this.handleOnAction = this.handleOnAction.bind(this);
		this.handleOnActive = this.handleOnActive.bind(this);
		this.handleOnIdle = this.handleOnIdle.bind(this);
	}

	// clear session on page reopen using ctrl+shfit+t
	/* istanbul ignore next */
	resetSessionOnTabReopen() {
		if (this.state.firstName && performance && performance.getEntriesByType) {
			var perfEntries = performance.getEntriesByType("navigation");
			for (var i = 0; i < perfEntries.length; i++) {
				var p = perfEntries[i];
				if (p.type === "back_forward") {
					removeSessionStorage('useractivestatus');
				}
			}
		}
	}

	componentDidMount() {
		this.resetSessionOnTabReopen();
		/* istanbul ignore next */
		if (!checkSessionStorage("useractivestatus") && !this.state.loggingOut) {
			this.logout();
		}

		/* istanbul ignore next */
		this.setState({
			remaining: this.idleTimer.getRemainingTime(),
			lastActive: this.idleTimer.getLastActiveTime(),
			elapsed: this.idleTimer.getElapsedTime(),
		});

		/* istanbul ignore next  */
		this.timer = setInterval(() => {
			this.setState({
				remaining: this.idleTimer.getRemainingTime(),
				lastActive: this.idleTimer.getLastActiveTime(),
				elapsed: this.idleTimer.getElapsedTime(),
			});
		}, 1000);
	}

	componentDidUpdate() {
		/* istanbul ignore next */
		if (this.props.uploadProgress && !isNaN(parseInt(this.props.uploadProgress)) && this.props.uploadProgress < 100) {
			this.idleTimer.pause();
		} else {
			this.idleTimer.resume();
		}
	}

	async applicationLogger(key) {
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.logout,
			"EventName": loggerEventName.logout,
			"Content": {
				"Data": loggerEventMessage.logout
			}
		}
		await logger(loggerObj);
	}

	// logs are tracked in logout if logs not tracked in component will unmount
	/* istanbul ignore next  */
	async currentPageLogger() {
		if (this.props.loggerObj) {
			const timeSinceLoad = (new Date().getTime() - this.props.loggerObj.StartDate.getTime()) / 1000;
			const loggerObj = {
				"EventOutcome": this.props.loggerObj.EventOutcome,
				"EventType": this.props.loggerObj.EventType,
				"EventName": this.props.loggerObj.EventName,
				"Content": {
					"TimeSpent": timeSinceLoad
				}
			}
			await logger(loggerObj);
		}
	}

	// What to do when there is user interaction
	/* istanbul ignore next */
	handleOnAction(event) {
		if (!localStorage.getItem('cognito_data') && !this.state.loggingOut) {
			this.logout();
		} else {
			/* istanbul ignore next */
			const userName = getUserName();
			if (this.state.userName !== userName) {
				const getUserDetail = getUserDetails();
				this.setState({
					firstName: getUserDetail ? getUserDetail.given_name : "",
					lastName: getUserDetail ? getUserDetail.family_name : "",
					userName: userName
				})
			}
		}
	}

	// What to do when the user hasn't done anything in 10 min
	/* istanbul ignore next */
	handleOnIdle(event) {
		if (!this.state.loggingOut) {
			this.logout();
		}
	}

	// What to do when the user go from idle to active; doesn't apply here
	/* istanbul ignore next */
	handleOnActive(event) { }

	/* istanbul ignore next  */
	componentWillUnmount() {
		clearInterval(this.timer);
	}

	/**
	 * @description function to handle logout functionality
	 * @param {*}
	 * @memberof Header
	 */
	/* istanbul ignore next  */
	logout = async (e) => {
		this.setState({ loggingOut: true });
		if (e) e.preventDefault();

		Promise.all([
			this.currentPageLogger(),
			this.applicationLogger()
		]).then((res) => {
			setTimeout(() => {
				logoutService(function (err, res) { });
			}, 500);
		});
	};

	/**
	 * @description function to handle user's name display
	 * @param {*}
	 * @memberof Header
	 */
	/* istanbul ignore next  */
	getUserName = (name) => {
		return name?.length > 20 ? `${name.substring(0, 20)}...` : name;
	}

	render() {
		const { hideMenu } = this.props;
		const { firstName, lastName, navSelected } = this.state;
		const loggedInUserType = getUserRoleType();
		return (
			<div className="sidemenu-wrap" data-testid="sidemenu-wrap">
				<IdleTimer
					ref={(ref) => {
						this.idleTimer = ref;
					}}
					timeout={this.timeout}
					onActive={this.handleOnActive}
					onIdle={this.handleOnIdle}
					onAction={this.handleOnAction}
				/>
				<div className="sidenav-profile">
					<div className="row application-logo">
						<div className="col-md-4">
							<img src={getImgSrc('Logo.png')} alt="Logo" />
						</div>
						<div className="col-md-8 application-text">
							DA Portal
						</div>
					</div>
					<div className="surgeon-info" title={`${firstName} ${lastName}`}>{this.getUserName(`${firstName} ${lastName}`)}</div>
					<div className="row logout-profile">
						<div className="col-md-6 logout">
							<a href="/login" id="logout" onClick={this.logout} title="">
								<img
									src={getImgSrc('Logout.png')}
									alt="logout"
								/> Logout </a>
						</div>
						<div className="col-md-6 profile">
							<img src={getImgSrc('no-profile-image.png')} alt="Profile" />
						</div>
					</div>
				</div>
				<div id="mySidenav" className="sidenav">
					{hideMenu ? '' :
						<nav>
							<ul>
								{/* Dashboard */}
								{RolePermission.dashboard.indexOf(loggedInUserType) > -1 ?
									<li className={navSelected === '/dashboard' ? 'slctd' : ''}>
										<img src={navSelected === '/dashboard' ? getImgSrc('dashboard-white.png') : getImgSrc('dashboard-black.png')} alt="Dashboard" />
										<Link to="/dashboard" style={{ margin: 'auto', height: 60 }}>Dashboard</Link>
									</li>
									: null}

								{/* Digital Assets List */}
								{RolePermission.fileUpload.indexOf(loggedInUserType) > -1 ?
									<li className={RoutesPerModule.assets.indexOf(navSelected) > -1 ? 'slctd' : ''}>
										<img src={RoutesPerModule.assets.indexOf(navSelected) > -1 ? getImgSrc('files-list-white.png') : getImgSrc('files-list-black.png')} alt="DigitalAssets" />
										<Link to="/assets" style={{ margin: 'auto', height: 60 }}>Digital Assets</Link>
									</li>
									: null}

								{/* Regions */}
								{RolePermission.region.indexOf(loggedInUserType) > -1 ?
									<li className={RoutesPerModule.regions.indexOf(navSelected) > -1 ? 'slctd' : ''}>
										<img src={RoutesPerModule.regions.indexOf(navSelected) > -1 ? getImgSrc('dashboard-white.png') : getImgSrc('dashboard-black.png')} alt="Regions" />
										<Link to="/regions" style={{ margin: 'auto', height: 60 }}>Regions</Link>
									</li>
									: null}

								{/* User Management */}
								{RolePermission.userManagement.indexOf(loggedInUserType) > -1 ?
									<li className={RoutesPerModule.users.indexOf(navSelected) > -1 ? 'slctd' : ''}>
										<img src={RoutesPerModule.users.indexOf(navSelected) > -1 ? getImgSrc('caselist-white.png') : getImgSrc('caselist-black.png')} alt="Users" />
										<Link to="/users" style={{ margin: 'auto', height: 60 }}>Users</Link>
									</li> : ''}

								{/* Audit Logs */}
								{RolePermission.auditLogs.indexOf(loggedInUserType) > -1 ?
									<li className={RoutesPerModule.logs.indexOf(navSelected) > -1 ? 'slctd' : ''}>
										<img src={RoutesPerModule.logs.indexOf(navSelected) > -1 ? getImgSrc('files-list-white.png') : getImgSrc('files-list-black.png')} alt="AuditLogs" />
										<Link to="/logs" style={{ margin: 'auto', height: 60 }}>Audit Logs</Link>
									</li>
									: ''}

								{/* Settings */}
								{RolePermission.login.indexOf(loggedInUserType) > -1 ?
									<li className={RoutesPerModule.settings.indexOf(navSelected) > -1 ? 'slctd' : ''}>
										<img src={RoutesPerModule.settings.indexOf(navSelected) > -1 ? getImgSrc('setting-white.png') : getImgSrc('setting-black.png')} alt="Settings" />
										<Link to="/settings" style={{ margin: 'auto', height: 'auto' }}>Settings</Link>
									</li>
									: ''}
							</ul>
						</nav>
					}
				</div>
				<div className="sn-bottom-logo">
					<img src={getImgSrc('sn-bottom-logo.png')} alt="sn-bottom-logo" />
					<p className="text-muted mt-2 fs-8-rem">App Version: {environment.appVersion}</p>
				</div>
			</div>
		)
	}
}

export default withRouter(props => <Sidemenu {...props} />);
