import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddRegionComponent from './AddRegion';
import { validateRegionFields, validateAlphabetic, validateAlphanumericNewLine } from '../ValidateRegion';
import { errorMessages, regionMessages, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../../helpers/messages';
import { saveRegion } from '../../../services/java/java-services';
import { logger } from '../../../services/logger/logger-service';

class AddRegionContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: '',
            descCharCount: 0,
            descCharLimit: 45,
            nameCharCount: 0,
            nameCharLimit: 20,
            loading: false,
            showSuccessMsg: null,
            showErrorMsg: '',
            successMessage: '',
            regionSaveError: null,
            errors: {
                region: '',
                description: ''

            },
            regionObj: {
                region: '',
                description: ''
            },

        }
    }
    /**
        * @description Function to handle input changes
        * @param  value Value of the input
        * @param  field Input field region
        * @memberof AddRegionContainer
        */

    handleInputChange = (field, value) => {
        this.setState({ showSuccessMsg: null, showErrorMsg: '', regionSaveError: null });
        var { errors } = this.state;
        var errorMessage = !value ? errorMessages.fileUpload[field] : '';
        var descCharCount = this.state.descCharCount;
        var nameCharCount = this.state.nameCharCount;
        var stateObj = { [field]: value };

        if (field === 'region' && value) {
            const regionNameCheck = validateAlphabetic(value);
            errorMessage = regionNameCheck.error;
            nameCharCount = value ? value.length : 0;
            if (nameCharCount > this.state.nameCharLimit) {
                errorMessage = errorMessages.fileUpload.charCount(nameCharCount, this.state.nameCharLimit);
            }
        } else if (field === 'region' && !value) {
            nameCharCount = 0;
        }
        if (field === 'description' && value) {
            const regionDescriptionCheck = validateAlphanumericNewLine(value);
            errorMessage = regionDescriptionCheck.error;
            descCharCount = value ? value.length : 0;
            if (descCharCount > this.state.descCharLimit) {
                errorMessage = errorMessages.fileUpload.charCount(descCharCount, this.state.descCharLimit);
            }
        } else if (field === 'description' && !value) {
            descCharCount = 0;
        }

        stateObj['nameCharCount'] = nameCharCount;
        stateObj['descCharCount'] = descCharCount;

        this.setState(prevState => {
            return {
                [field]: value,
                ...stateObj,
                regionObj: {
                    ...prevState.regionObj,
                    [field]: value
                },
                errors: {
                    ...errors,
                    [field]: errorMessage
                }
            }
        });
    };

    /** 
     * @description Function submit form by hitting enter key
     *  @param e Keypress event
     *  @memberof AddRegionContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveRegion();
        }
    }

    /**
     * @description Function to validate fields at the time of submit
     * @returns Boolean value if there are any errors
     * @memberof AddRegionContainer
     */
    validateFields = () => {
        const { regionObj } = this.state;
        const regionField = ['region'];
        let { errorFlag, errors } = validateRegionFields({ editObj: regionObj, errors: this.state.errors }, regionField);

        // Name Validation
        if (!errorFlag && regionObj.region) {
            let region = validateAlphabetic(regionObj.region);
            errorFlag = region.errorFlag;
            errors['region'] = region.error;
        }
        // Description Validation
        if (this.state.description && this.state.descCharCount > this.state.descCharLimit) {
            errors.description = errorMessages.fileUpload.charCount(this.state.descCharCount, this.state.descCharLimit);
            this.setState({ errors })
            return errors;
        } else if (!errorFlag && regionObj.description) {
            let description = validateAlphanumericNewLine(regionObj.description);
            errorFlag = description.errorFlag;
            errors['description'] = description.error;
            this.setState({ errors })
        }
        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }

        return errorFlag;
    }

    /**
     * @description function to add region in db
     * @param {*}
     * @memberof AddRegionContainer
     */
    addRegion = () => {
        const { regionObj } = this.state;
        var params = {
            region: regionObj.region,
            description: regionObj.description,
            status: true
        }
        saveRegion(params, (err, res) => {
            if (err) {
                this.handleErrorWithLogs(err);
            } else {
                const loggerParams = {
                    outcome: loggerEventOutcome.success,
                    message: regionMessages.regionCreated
                }
                
                this.applicationLogger(loggerParams);
                this.props.regionAdded(res.data);

            }
        });
    }

    /*** @description function to handle errors and log them
     * @memberof AddRegionContainer
     */
    handleErrorWithLogs = (err) => {
        const errMsg = err && err.message ? err.message : regionMessages.saveError;
        const params = {
            outcome: loggerEventOutcome.failure,
            message: errMsg
        }
        this.applicationLogger(params);
        this.setState({ loading: false, showErrorMsg: errMsg, regionSaveError: err });
    }

    /**
     * @description function to log region creation event
     * @memberof AddRegionContainer
     */
    /* istanbul ignore next  */
    applicationLogger = (params) => {
        const loggerObj = {
            "EventOutcome": params.outcome,
            "EventType": loggerEventTypes.create,
            "EventName": loggerEventName.regionCreate,
            "Content": {
                "Data": params.message
            }
        }
        logger(loggerObj);
    }

    /**
     * @description function to validate and save user details
     * @param {*}
     * @memberof AddRegionContainer
     */
    saveRegion = () => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '', regionSaveError: null });
        const formHasErrors = this.validateFields();
        if (!formHasErrors) {
            this.setState({ loading: true });
            this.addRegion()
        }
    }

    render() {
        return (
            <AddRegionComponent
                loading={this.state.loading}
                regionObj={this.state.regionObj}
                regionSaveError={this.state.regionSaveError}
                handleInputChange={this.handleInputChange}
                onEnterPress={this.onEnterPress}
                showSuccessMsg={this.state.showSuccessMsg}
                showErrorMsg={this.state.showErrorMsg}
                formErrors={this.state.errors}
                saveRegion={this.saveRegion}
                handleCancel={this.props.handleCancel}
                description={this.state.description}
                descCharCount={this.state.descCharCount}
                descCharLimit={this.state.descCharLimit}
                nameCharCount={this.state.nameCharCount}
                nameCharLimit={this.state.nameCharLimit}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(AddRegionContainer);