import React, { Component } from 'react';
import { Multiselect as MultiselectDropdown } from "multiselect-react-dropdown";
import { MultiSelect as MultiselectNoChips } from 'react-multi-select-component';

export default class MultiSelectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {
                chips: {
                    background: "#73bc51"
                },
                searchBox: {
                    "maxHeight": "80px",
                    "overflow": "auto",
                    "padding": "4px 5px"
                },
                option: {
                    "fontWeight": "normal"
                }
            }
        }
    }

    /**     * 
     * @description Display custom selected values or placeholder
     * @param selected Selected options
     * @param _options Available options
     * @returns String to show in the display area
     */
    /* istanbul ignore next  */
    customValueRenderer = (selected, _options) => {
        const { placeholder, dropdownContent, options } = this.props;
        if (selected && selected.length) {
            var labelArray = selected.map(x => x.label);
            var returnVal = labelArray.join(', ');
            switch (dropdownContent) {
                case 'customers':
                    return selected.length === options.length ? 'All Customers' : returnVal;
                case 'regions':
                    return selected.length === options.length ? 'All Regions' : returnVal;
            
                default:
                    return returnVal;
            }
        }
        return placeholder;
    };

    render() {
        const { id, disabled, options, singleSelect, className, placeholder, displayValue, selectedValues, handleSelectChange, handleSelectRemove, noChipsView, hasSelectAll } = this.props;
        const dataTestId = this.props['data-testid']; 
        return (
            <div className="custom-multiselect" data-testid={dataTestId}>
                {noChipsView ?
                    <MultiselectNoChips
                        disabled={disabled}
                        data-testid={dataTestId}
                        className={className}
                        options={options}
                        value={selectedValues}
                        hasSelectAll={hasSelectAll}
                        valueRenderer={this.customValueRenderer}
                        onChange={handleSelectChange}
                    />
                    : <MultiselectDropdown
                        id={id}
                        data-testid={dataTestId}
                        className={className}
                        disabled={disabled}
                        style={this.state.style}
                        singleSelect={singleSelect}
                        options={options}
                        placeholder={placeholder || 'Select'}
                        displayValue={displayValue}
                        selectedValues={selectedValues}
                        showCheckbox={true}
                        closeOnSelect={false}
                        avoidHighlightFirstOption={true}
                        closeIcon="cancel"
                        onSelect={handleSelectChange}
                        onRemove={handleSelectRemove}
                    />
                }
            </div>
        )
    }
}
