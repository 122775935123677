import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminComponent from './Admin';

class AdminContainer extends Component {
	render() {
		return (
			<AdminComponent/>
		);
	}
}

function mapStateToProps(state) {
	return {
		state,
	};
}

export default connect(mapStateToProps)(AdminContainer);