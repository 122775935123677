import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddUserComponent from './AddUser';
import { addUserToGroupCognito, sendEmail, userSignUpCognito } from '../../../services/aws/aws-services';
import { loggerEventName, loggerEventOutcome, loggerEventTypes, resetPasswordMessages, userMessages } from '../../../helpers/messages';
import { validateAlphanumeric, validateEmail, validateUserFields, validateUsername } from '../ValidateUser';
import { charset, getUserCreatedTemplate, sourceEmail, subjectLines } from '../../../helpers/Email';
import { matchExpression, passwordRegexMatch, passwordStrength } from '../../../helpers/PasswordValidate';
import { saveUserService } from '../../../services/java/java-services';
import { logger } from '../../../services/logger/logger-service';
import { cognitoConstants } from '../../../helpers/constants';
import { validateWhiteSpace } from '../../../helpers/GlobalFunctions';

let startDate;
class AddUserContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showSuccessMsg: '',
            showEmailSuccessMsg: '',
            showErrorMsg: '',
            successMessage: '',
            pwdStrengthLabel: '',
            pwdStrengthProgress: '',
            pwdStrengthVarient: '',
            expMatch: {},
            cognitoUser: '',
            userSaveError: null,
            countries: null,
            userObj: {
                firstName: '',
                lastName: '',
                userName: '',
                email: '',
                role: '',
                password: '',
                confirmPassword: '',
            },
            errors: {
                firstName: '',
                lastName: '',
                userName: '',
                email: '',
                role: '',
                password: '',
                confirmPassword: '',
            }
        }
    }

    componentDidMount() {
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.timeSpentLogger);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
    }

    /**
     * @description function to handle application logs for time spent on the page
     * @param {*}
     * @memberof AddCustomerContainer
     */
    timeSpentLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.userCreate,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description Function to handle input changes
     * @param  value Value of the input
     * @param  field Input field name
     * @memberof AddUserContainer
     */
    handleInputChange = (field, value) => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '', userSaveError: null });
        var { errors, userObj } = this.state;
        var errorMessage = !value ? userMessages[field] : '';
        if ((field === 'firstName' || field === 'lastName') && value) {
            const surgeonNameCheck = validateAlphanumeric(value);
            errorMessage = surgeonNameCheck.error;
        }
        if (field === 'email' && value) {
            const emailCheck = validateEmail(value);
            errorMessage = emailCheck.error;
        }
        if (field === 'userName' && value) {
            const userNameCheck = validateUsername(value);
            errorMessage = userNameCheck.error;
        }
        if (field === 'password') {
            if (validateWhiteSpace(value)) {
                errorMessage = userMessages.whiteSpace;
            }
            var strengthObj = passwordStrength(value, userObj.userName);
            this.setState({ ...strengthObj, expMatch: {} });

            if (!passwordRegexMatch(value, userObj.userName)) {
                errorMessage = '';
                errors.password = '';
                errors.confirmPassword = '';
                errors.passwordMatch = true;
            } else {
                errors.passwordMatch = '';

            }

            if (value === '') {
                this.setState({ pwdStrengthLabel: '' });
            }
        }

        if (field === 'confirmPassword') {
            errorMessage = '';
            errors.confirmPassword = '';
        }

        this.setState(prevState => {
            return {
                [field]: value,
                userObj: {
                    ...prevState.userObj,
                    [field]: value
                },
                errors: {
                    ...errors,
                    [field]: errorMessage
                }
            }
        });
    }

    /**
    * @description Function to handle blur event in reset password form
    * @memberof AddUserContainer
    */
    handleBlur = (field, value) => {
        this.handleInputChange(field, value);
        setTimeout(() => {
            if (field === 'password') {
                var matchResults = matchExpression(value, this.state.expMatch, this.state.userObj.userName);
                this.setState({ expMatch: matchResults });
            }
        }, 0);
    }

    /** 
     * @description Function submit form by hitting enter key
     *  @param e Keypress event
     *  @memberof AddUserContainer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            this.saveUser();
        }
    }

    /**
     * @description Function to validate fields at the time of submit
     * @returns Boolean value if there are any errors
     * @memberof AddUserContainer
     */
    validateFields = () => {
        const { userObj } = this.state;
        const userFields = ['firstName', 'lastName', 'email', 'userName', 'password', 'confirmPassword', 'role'];
        let { errorFlag, errors } = validateUserFields({ editObj: userObj, errors: this.state.errors }, userFields);
        if (!errorFlag && userObj.firstName) {
            let firstName = validateAlphanumeric(userObj.firstName);
            errorFlag = firstName.errorFlag;
            errors['firstName'] = firstName.error;
        }
        if (!errorFlag && userObj.lastName) {
            let lastName = validateAlphanumeric(userObj.lastName);
            errorFlag = lastName.errorFlag;
            errors['lastName'] = lastName.error;
        }
        if (!errorFlag && userObj.email) {
            let email = validateEmail(userObj.email);
            errorFlag = email.errorFlag;
            errors['email'] = email.error;
        }
        if (!errorFlag && userObj.userName) {
            let userName = validateUsername(userObj.userName);
            errorFlag = userName.errorFlag;
            errors['userName'] = userName.error;
        }
        if (!errorFlag && userObj.password && userObj.confirmPassword) {
            this.handleBlur(userObj.password, 'password');
            if (!passwordRegexMatch(userObj.password, userObj.userName)) {
                errorFlag = true;
                errors["passwordMatch"] = true;
            }
            if (userObj.password !== userObj.confirmPassword) {
                errorFlag = true;
                errors["confirmPassword"] = resetPasswordMessages.error.notMatch;
            }
        }
        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }

        return errorFlag;
    }

    /**
     * @description function to get cognito user attribute array
     * @param {*}
     * @memberof AddUserContainer
     */
    getCognitoUserAttributes = () => {
        const { userObj } = this.state;
        return [
            { Name: 'given_name', Value: userObj.firstName },
            { Name: 'family_name', Value: userObj.lastName },
            { Name: 'email', Value: userObj.email },
            { Name: 'email_verified', Value: "true" },
        ];
    }

    /**
     * @description function to validate and save user details
     * @param {*}
     * @memberof AddUserContainer
     */
    saveUser = () => {
        this.setState({ showSuccessMsg: '', showErrorMsg: '' });
        const formHasErrors = this.validateFields();
        if (!formHasErrors) {
            this.setState({ loading: true });
            const userAttributes = this.getCognitoUserAttributes();
            this.updateCognito(userAttributes);
        }
    }

    /**
     * @description function to update cognito userpool
     * @param {*}
     * @memberof AddUserContainer
     */
    updateCognito = (updateAttributes) => {
        const { userObj } = this.state;
        userSignUpCognito(userObj.userName, userObj.password, updateAttributes)
            .then(res => {
                if (res && res.User) {
                    this.setState({ cognitoUser: res.User })
                    this.addUserToGroup();
                }
            })
            .catch(err => {
                let { errors } = this.state;
                const errObj = {
                    ...err,
                    errorCode: err.statusCode
                }
                if (err.code === cognitoConstants.exceptions.UsernameExistsException) {
                    errors['userName'] = userMessages.duplicateUserName;
                }
                this.setState({ errors });
                this.handleErrorWithLogs(errObj);
            });
    }

    /**
     * @description function to add user to group
     * @param {*}
     * @memberof AddUserContainer
     */
    addUserToGroup = () => {
        const { userObj } = this.state;
        addUserToGroupCognito(userObj.userName, userObj.role)
            .then(res => {
                if (res) {
                    this.addUserInDB();
                }
            })
            .catch(err => {
                let { errors } = this.state;
                const errObj = {
                    ...err,
                    errorCode: err.statusCode
                }
                if (err.code === cognitoConstants.exceptions.ResourceNotFoundException) {
                    errors['role'] = userMessages.roleNotFound;
                }
                this.setState({ errors });
                this.handleErrorWithLogs(errObj);
            });
    }

    /**
     * @description function to add user in db
     * @param {*}
     * @memberof AddUserContainer
     */
    addUserInDB = () => {
        const { userObj, cognitoUser } = this.state;
        var params = {
            username: cognitoUser.Username,
            firstname: userObj.firstName,
            lastname: userObj.lastName,
            email: userObj.email,
            userstatus: cognitoUser.UserStatus,
            usercreateddate: cognitoUser.UserCreateDate.toISOString(),
            group: userObj.role,
            enabled: true
        }
        saveUserService(params, (err, res) => {
            if (err) {
                this.handleErrorWithLogs(err);
            } else {
                const loggerParams = {
                    outcome: loggerEventOutcome.success,
                    message: userMessages.userCreated
                }
                this.applicationLogger(loggerParams);
                this.sendEmailToUser();
            }
        });
    }

    /**
     * @description function to notify user of an email update
     * @param {*}
     * @memberof AddUserContainer
     */
    sendEmailToUser = () => {
        const { userObj } = this.state;
        const params = {
            Destination: {
                ToAddresses: [userObj.email]
            },
            Message: {
                Body: {
                    Html: {
                        Charset: charset,
                        Data: getUserCreatedTemplate(userObj)
                    }
                },
                Subject: {
                    Charset: charset,
                    Data: subjectLines.welcome
                }
            },
            Source: sourceEmail
        };
        sendEmail(params, (res) => {
            this.setState({
                loading: false,
                showSuccessMsg: userMessages.userCreated,
                showErrorMsg: '',
                showEmailSuccessMsg: userMessages.emailSent,
                userSaveError: null
            });
            this.props.userAdded(userObj.userName);
        })
    }

    /**
     * @description function to handle errors and log them
     * @memberof AddUserContainer
     */
    handleErrorWithLogs = (err) => {
        const errMsg = err && err.message ? err.message : userMessages.saveError;
        const params = {
            outcome: loggerEventOutcome.failure,
            message: errMsg
        }
        this.applicationLogger(params);
        this.setState({ loading: false, showErrorMsg: errMsg, userSaveError: err });
    }

    /**
     * @description function to log user creation event
     * @memberof AddUserContainer
     */
    /* istanbul ignore next  */
    applicationLogger = (params) => {
        const loggerObj = {
            "EventOutcome": params.outcome,
            "EventType": loggerEventTypes.create,
            "EventName": loggerEventName.userCreate,
            "Content": {
                "Data": params.message
            }
        }
        logger(loggerObj);
    }

    render() {
        return (
            <AddUserComponent
                loading={this.state.loading}
                userObj={this.state.userObj}
                countries={this.state.countries}
                userSaveError={this.state.userSaveError}
                pwdStrengthLabel={this.state.pwdStrengthLabel}
                pwdStrengthProgress={this.state.pwdStrengthProgress}
                pwdStrengthVarient={this.state.pwdStrengthVarient}
                pwdMatchObj={this.state.expMatch}
                resetPwdErrMsg={resetPasswordMessages.error.passwordErrors}
                handleInputChange={this.handleInputChange}
                handleBlur={this.handleBlur}
                onEnterPress={this.onEnterPress}
                showSuccessMsg={this.state.showSuccessMsg}
                showEmailSuccessMsg={this.state.showEmailSuccessMsg}
                showErrorMsg={this.state.showErrorMsg}
                formErrors={this.state.errors}
                saveUser={this.saveUser}
                handleCancel={this.props.handleCancel}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(AddUserContainer);