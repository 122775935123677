import React, { Component } from 'react';
import { loggerEventMessage, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../../helpers/messages';
import { activityUpdaterWithPromise } from '../../../services/activity/activity-service';
import { updateFileDetails } from '../../../services/java/java-services';
import { logger } from '../../../services/logger/logger-service';
import FileStatusComponent from './FileStatus';


export default class FileStatusContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			file: props.file ? props.file : null,
			error: null
		}
	}

	/**
	 * @description Function to change file status
	 * @memberof FileStatusContainer
	 */
	changeFileStatus = (isEnable) => {
		const { file } = this.state;
		if (file) {
			let params = {
				logsFlag: false,
				data: {
					id: file.id,
					filename: file.filename,
					daStatus: isEnable
				}
			}

			this.props.toggleLoader(true);
			updateFileDetails(params, (err, res) => {
				if (err) {
					this.handleError(err);
				} else {
					this.props.toggleLoader(false);
					const updatedFile = res && res.data ? res.data : file;
					this.fileStatusUpdateLogger({ outcome: loggerEventOutcome.success, message: loggerEventMessage.fileStatusUpdated(updatedFile) });
					setTimeout(() => {
						this.props.fileStatusUpdated(updatedFile);
					}, 0);
				}

			})
		}
	}

	/**
	 * @description Function to handle errors
	 * @param error Error object with error code and message
	 * @memberof FileStatusContainer
	 */
	handleError = (error) => {
		this.props.toggleLoader(false);
		this.setState({ error: error });
		this.fileStatusUpdateLogger({ outcome: loggerEventOutcome.failure, message: error.message });
		this.props.updateError(error);
	}

	/**
	 * @description Function to update file status in backend DB
	 * @memberof FileStatusContainer
	 */
	updateUserStatusInDB = (file) => {
		const activityObj = {
			enabled: file.enabled,
			userName: file.userName
		}

		activityUpdaterWithPromise(activityObj).then(res => {

		}).catch(err => {
			this.props.toggleLoader(false);
			this.handleError(err);
		});
	}

	/**
	 * @description function to log file status update event
	 * @param params Params with outcome and message
	 * @memberof FileStatusContainer
	 */
	fileStatusUpdateLogger = (params) => {
		const loggerObj = {
			"EventOutcome": params.outcome,
			"EventType": loggerEventTypes.update,
			"EventName": loggerEventName.fileStatus,
			"Content": {
				"Data": params.message
			}
		}
		logger(loggerObj);
	}

	render() {
		const { file } = this.state;
		return (
			<FileStatusComponent file={file} changeFileStatus={this.changeFileStatus} />
		)
	}
}
