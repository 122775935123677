import React, { Component } from 'react';
import { connect } from 'react-redux';
import FileDetailsComponent from './FileDetails'
import { errorMessages, loggerEventName, loggerEventOutcome, loggerEventTypes } from '../../helpers/messages';
import { logger } from '../../services/logger/logger-service';
import { getAllCustomers, getAllRegionsPromise, getFileDetailsService, updateFileDetails } from '../../services/java/java-services';
import { DAConfig, RolePermission } from '../../helpers/constants';
import { getUserRoleType } from '../../services/aws/aws-services';
import { validateDAFields } from '../FileUpload/ValidateFile';

let startDate;
class FileDetailsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isEditMode: false,
            fileID: props?.location?.state?.fileID,
            fileDetails: null,
            fileUpdateError: null,
            fileUpdateSuccess: null,
            editObj: {
                regions: [],
                customers: [],
                releaseNotes: '',
                description: '',
                status: ''
            },
            fileErrors: null,
            allRegions: null,
            allCustomers: null,
            rawRegions: null,
            rawCustomers: null,
            regionError: null,
            customerError: null,
            descCharCount: 0,
            descCharLimit: DAConfig.descCharLimit,
            releaseCharCount: 0,
            releaseCharLimit: DAConfig.releaseNoteCharLimit,
            errors: {
                regions: '',
                customers: '',
                releaseNotes: '',
                description: '',
                status: ''
            }
        }
    }

    componentDidMount() {
        startDate = new Date();
        window.addEventListener('beforeunload', this.timeSpentLogger);
        this.getAllData();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.timeSpentLogger);
        this.timeSpentLogger();
    }

    /**
     * @description Function to fetch data for dropdowns
     * @memberof FileDetailsContainer
     */
    getAllData = () => {
        var regionParams = { sortorder: 'asc', column: 'region', page: 'upload' };
        var regionRes = {};
        var customerRes = {};
        var fileRes = {};
        var editObj = { daEditObj: {}, regionEditObj: {}, customerEditObj: {} };
        this.setState({ loading: true });
        Promise.allSettled([
            getFileDetailsService(this.state.fileID),
            getAllRegionsPromise(regionParams),
            getAllCustomers()
        ]).then(res => {
            // DA DETAILS RESPONSE
            if (res[0] && res[0].status === 'fulfilled') {
                let res0 = this.handleFileResponse(res[0].value);
                fileRes = res0.stateObj;
                editObj['daEditObj'] = res0.editObj;
            } else {
                fileRes = { fileErrors: res[0].reason };
            }

            // REGIONS RESPONSE
            if (res[1] && res[1].status === 'fulfilled') {
                let res1 = this.handleRegionResponse(res[1].value, fileRes.fileDetails);
                regionRes = res1.stateObj;
                editObj['regionEditObj'] = res1.editObj;
            } else {
                regionRes = { regionError: res[1].reason };
            }

            // CUSTOMERS RESPONSE
            if (res[2] && res[2].status === 'fulfilled') {
                let res2 = this.handleCustomersResponse(res[2].value, fileRes.fileDetails);
                customerRes = res2.stateObj;
                editObj['customerEditObj'] = res2.editObj;
            } else {
                customerRes = { customerError: res[2].reason };
            }

            this.setState(prevState => {
                return {
                    loading: false,
                    ...fileRes,
                    ...regionRes,
                    ...customerRes,
                    editObj: {
                        ...prevState.editObj,
                        ...editObj.daEditObj,
                        ...editObj.regionEditObj,
                        ...editObj.customerEditObj
                    }
                }
            });
        }).catch(err => {
            this.setState({ loading: false, fetchError: err });
        })
    }

    /**
     * @description Function to handle DA details response
     * @memberof FileDetailsContainer
     */
    handleFileResponse = (res) => {
        const fileDetails = res?.data ? res.data : null;
        var editObj = null;
        if (fileDetails) {
            editObj = {
                releaseNotes: fileDetails.releasenote,
                description: fileDetails.comments,
                status: fileDetails.status
            }
        }

        return {
            stateObj: {
                fileErrors: null,
                fileDetails,
                descCharCount: fileDetails?.comments ? fileDetails.comments.length : 0
            },
            editObj
        }
    }

    /**
     * @description Function to fetch regions
     * @memberof FileDetailsContainer
     */
    handleRegionResponse = (res, fileDetails) => {
        const regions = res?.data?.regionlist?.length ? res.data.regionlist : [];
        var regionSelectFormat = [];
        if (fileDetails && regions?.length) {
            var preselectRegions = this.processPreselectedRegions(regions, fileDetails);
            regionSelectFormat = regions.map(x => {
                return {
                    label: x.region,
                    value: x.id
                }
            })
        }
        return {
            stateObj: {
                regionError: null,
                rawRegions: regions,
                allRegions: regionSelectFormat
            },
            editObj: {
                regions: preselectRegions
            }
        }
    }

    /**
     * @description function to process the regions to preslecting the dropdown
     * @param regions The api response for regions
     * @memberof FileDetailsContainer
     */
    processPreselectedRegions = (regions, fileDetails) => {
        var preselectRegions = [];
        
        if (!fileDetails?.allregion && fileDetails?.regions?.length) {
            regions.forEach(region => {
                if (fileDetails.regions.find(x => x === region.region)) {
                    preselectRegions.push({
                        label: region.region,
                        value: region.id
                    })
                }
            })
            return preselectRegions;
        } else {
            return regions.map(x => {
                return {
                    label: x.region,
                    value: x.id
                }
            })
        }

    }

    /**
     * @description Function to fetch customers
     * @memberof FileUploadContainer
     */
    handleCustomersResponse = (res, fileDetails) => {
        const customers = res?.data?.length ? res.data : [];
        var customerSelectFormat = [];
        if (fileDetails && customers?.length) {
            var preselectedCustomers = this.processPreselectedCustomers(customers, fileDetails);
            customerSelectFormat = customers.map(x => {
                return {
                    label: `${x.customername} (${x.customerid})`,
                    value: x.customerid
                }
            })
        }
        return {
            stateObj: {
                customerError: null,
                rawCustomers: customers,
                allCustomers: customerSelectFormat,
                fileMetadataObj: {
                    ...this.state.fileMetadataObj,
                    customers: customerSelectFormat
                }
            },
            editObj: {
                customers: preselectedCustomers
            }
        }
    }

    /**
     * @description function to process the customers to preslecting the dropdown
     * @param customers The api response for customers
     * @memberof FileDetailsContainer
     */
    processPreselectedCustomers = (customers, fileDetails) => {
        if (!fileDetails?.allcustomer && fileDetails?.customerIds?.length) {
            var preselectedCustomers = [];
            customers.forEach(customer => {
                if (fileDetails.customerIds.find(x => x === customer.customerid)) {
                    preselectedCustomers.push({
                        label: `${customer.customername} (${customer.customerid})`,
                        value: customer.customerid
                    })
                }
            })
            return preselectedCustomers;
        } else {
            return customers.map(x => {
                return {
                    label: `${x.customername} (${x.customerid})`,
                    value: x.customerid
                }
            })
        }
    }

    /**
     * @description function to handle application logs for time spent on the page
     * @param {*}
     * @memberof FileDetailsContainer
     */
    timeSpentLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.fileDetails,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description function to toggle edit and view only modes
     * @memberof FileDetailsContainer
     */
    toggleEditMode = () => {
        const { rawCustomers, rawRegions, fileDetails } = this.state;
        this.setState(prevState => {
            return {
                isEditMode: !prevState.isEditMode,
                editObj: {
                    regions: this.processPreselectedRegions(rawRegions, fileDetails),
                    customers: this.processPreselectedCustomers(rawCustomers, fileDetails),
                    releaseNotes: prevState.fileDetails.releasenote,
                    description: prevState.fileDetails.comments,
                    status: prevState.fileDetails.status
                },
                errors: {
                    regions: '',
                    customers: '',
                    releaseNotes: '',
                    description: '',
                    status: ''
                }
            }
        })
    }

    /**
     * @description Function to handle input changes
     * @param  value Value of the input
     * @param  field Input field name
     * @memberof FileUploadContainer
     */
    handleInputChange = (field, value) => {
        var errorMessage = !value ? errorMessages.fileUpload[field] : '';
        var descCharCount = 0;
        var releaseCharCount = 0;
        var stateObj = { [field]: value };
        switch (field) {
            case 'description':
                descCharCount = value ? value.length : 0;
                stateObj['descCharCount'] = descCharCount;
                if (descCharCount > this.state.descCharLimit) {
                    errorMessage = errorMessages.fileUpload.charCount(descCharCount, this.state.descCharLimit)
                }
                break;
            case 'releaseNotes':
                releaseCharCount = value && value.plainTextData ? value.plainTextData.length : 0;
                stateObj['releaseCharCount'] = releaseCharCount;
                if (releaseCharCount > this.state.releaseCharLimit) {
                    errorMessage = errorMessages.fileUpload.charCount(releaseCharCount, this.state.releaseCharLimit)
                }
                break;
            case 'regions':
                errorMessage = value && value.length ? '' : errorMessages.fileUpload.regions;
                break;
            case 'customers':
                errorMessage = value && value.length ? '' : errorMessages.fileUpload.customers;
                break;
            default:
                break;
        }
        this.setState(prevState => {
            return {
                ...stateObj,
                editObj: {
                    ...prevState.editObj,
                    [field]: value
                },
                errors: {
                    ...prevState.errors,
                    [field]: errorMessage
                }
            }
        });
    }

    /**
     * @description function for file upload form 
     * @param {*}
     * @memberof FileUploadContainer
     */
    formValidation() {
        var initialErrors = { ...this.state.errors }
        var { editObj, releaseCharCount, releaseCharLimit, descCharCount, descCharLimit } = this.state;
        const mandatoryFields = ['regions', 'customers'];
        let { errorFlag, errors } = validateDAFields({ editObj: editObj, errors: initialErrors }, mandatoryFields);

        // Release Notes Validation
        if (!errorFlag && editObj?.releaseNotes?.editorData) {
            errorFlag = releaseCharCount > releaseCharLimit;
            errors['releaseNotes'] = errorFlag ? errorMessages.fileUpload.charCount(releaseCharCount, releaseCharLimit) : '';
        }

        // Description Validation
        if (!errorFlag && editObj.description) {
            errorFlag = editObj.description && descCharCount > descCharLimit;
            errors['description'] = errorFlag ? errorMessages.fileUpload.charCount(descCharCount, descCharLimit) : '';
        }

        if (errorFlag) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                }
            })
        }

        return errorFlag;
    }

    /**
     * @description function to handle submit action 
     * @memberof FileDetailsContainer
     */
    uploadSubmit = () => {
        if (RolePermission.fileUpload.indexOf(getUserRoleType()) === -1) {
            this.setState({ fileUpdateError: { message: errorMessages.fileUpload.permissionDenied } });
            return;
        }
        this.setState({ fileUpdateError: '' });
        const formHasErrors = this.formValidation();
        if (!formHasErrors) {
            this.updateFileDetails();
        }
    }

    /**
     * @description Function to update file details
     * @memberof FileDetailsContainer
     */
    updateFileDetails = () => {
        const { editObj, fileDetails, allCustomers, rawCustomers, allRegions, rawRegions } = this.state;
        if (editObj && fileDetails) {
            let params = {
                logsFlag: true,
                data: {
                    id: fileDetails.id,
                    filename: fileDetails.filename,
                    daStatus: editObj.status,
                    description: editObj.description,
                    releasenote: editObj.releaseNotes?.editorData
                }
            }

            if (editObj.regions?.length && allRegions?.length && editObj.regions.length !== allRegions.length) {
                params.data['regionids'] = editObj.regions.map(x => x.value);
            } else {
                params.data['allregion'] = true;
            }

            if (editObj.customers?.length && allCustomers?.length && editObj.customers.length !== allCustomers.length) {
                params.data['customerIds'] = editObj.customers.map(x => x.value);
            } else {
                params.data['allcustomer'] = true;
            }

            this.setState({ loading: true });
            updateFileDetails(params, (err, res) => {
                if (err) {
                    this.setState({ loading: false, fileUpdateError: err });
                } else {
                    var updatedFile = res.data ? res.data : fileDetails;
                    var updatedEditObj = null;
                    if (updatedFile) {
                        updatedEditObj = {
                            regions: this.processPreselectedRegions(rawRegions, updatedFile),
                            customers: this.processPreselectedCustomers(rawCustomers, updatedFile),
                            releaseNotes: updatedFile.releasenote,
                            description: updatedFile.comments,
                            status: updatedFile.status
                        }
                    }
                    this.setState({
                        loading: false,
                        isEditMode: false,
                        fileUpdateSuccess: true,
                        fileDetails: updatedFile,
                        editObj: updatedEditObj
                    });

                    setTimeout(() => {
                        this.setState({ fileUpdateSuccess: false });
                    }, 10000);
                }

            })
        }
    }

    render() {
        const { allRegions, allCustomers, descCharCount, descCharLimit, editObj, fileDetails, fileErrors, loading, regionError, customerError, releaseCharCount, releaseCharLimit, isEditMode, errors, fileUpdateError, fileUpdateSuccess } = this.state;
        return (
            <FileDetailsComponent
                allRegions={allRegions}
                allCustomers={allCustomers}
                descCharCount={descCharCount}
                descCharLimit={descCharLimit}
                editObj={editObj}
                fileDetails={fileDetails}
                fileErrors={fileErrors}
                loading={loading}
                regionError={regionError}
                customerError={customerError}
                releaseCharCount={releaseCharCount}
                releaseCharLimit={releaseCharLimit}
                isEditMode={isEditMode}
                formErrors={errors}
                fileUpdateError={fileUpdateError}
                fileUpdateSuccess={fileUpdateSuccess}
                toggleEditMode={this.toggleEditMode}
                handleInputChange={this.handleInputChange}
                onSubmit={this.uploadSubmit}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default connect(mapStateToProps)(FileDetailsContainer);