import React, { Component } from 'react'
import { parseHTML } from '../../../helpers/GlobalFunctions';
import { regionMessages } from '../../../helpers/messages';
import CustomModal from '../../../shared/CustomModal';

export default class RegionStatusComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirmModal: false,
			status: props.region && props.region.status === true
		}
	}

	/**
	 * @description Function to handle open and close of modal
	 * @param enabled Boolean: false for uncheck, true for check
	 * @param isOpen Boolean: false to close, true to open
	 * @memberof RegionStatusComponent
	 */
	toggleConfirmModal = (status, isOpen) => {
		this.setState({ status: status, showConfirmModal: isOpen });
	}

	/**
	 * @description Function to handle explicitly close modal
	 * @memberof RegionStatusComponent
	 */
	 closeModal = () => {
		const { region } = this.props;
		this.setState({ 
			status: region && region.status === true,
			showConfirmModal: false 
		});
	}

	/**
	 * @description Function to handle confirmation
	 * @memberof RegionStatusComponent
	 */
	changeRegionStatus = () => {
		this.setState({ showConfirmModal: false });
		this.props.changeRegionStatus(this.state.status);
	}

	render() {
		const { region } = this.props;
		const { status, showConfirmModal } = this.state;
		return (
			<>
				<div className="device-status-toggle" data-testid="regionStatus">
					<label className="switch">
						<input onChange={(e) => this.toggleConfirmModal(e.target.checked, true)} type="checkbox" data-testid={`region-item-${region.status}`} id={`region-item-${region.status}`} value={status} checked={status} />
						<div className="slider round">
							<span className="on">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
									<path d="M7.5 1v7h1V1h-1z" />
									<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
								</svg>
							</span>
							<span className="off">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" className="bi bi-power" viewBox="0 0 16 16">
									<path d="M7.5 1v7h1V1h-1z" />
									<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
								</svg>
							</span>
						</div>
					</label>
				</div>

				{/* Modal to confirm activate/deactivate region */}
				<CustomModal
					id="region-status-modal"
					data-testid="region-status-modal"
					show={showConfirmModal}
					isConfirm={true}
					title={region && region.region ? `Region Status: ${region.region}` : 'Region Status'}
					body={parseHTML(regionMessages.statusConfirmation(status))}
					closeAction={() => this.closeModal()}
					rejectAction={() => this.closeModal()}
					confirmAction={this.changeRegionStatus}
				/>
			</>
		)
	}
}
