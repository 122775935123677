import React, { Component } from 'react'
import { parseHTML } from '../../../helpers/GlobalFunctions';
import { fileListMessages } from '../../../helpers/messages';
import CustomModal from '../../../shared/CustomModal';

export default class FileStatusComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirmModal: false,
			status: props.file && props.file.status === true
		}
	}

	/**
	 * @description Function to handle open and close of modal
	 * @param status Boolean: false for uncheck, true for check
	 * @param isOpen Boolean: false to close, true to open
	 * @memberof FileStatusComponent
	 */
	toggleConfirmModal = (status, isOpen) => {
		this.setState({ status: status, showConfirmModal: isOpen });
	}

	/**
	 * @description Function to handle explicitly close modal
	 * @memberof FileStatusComponent
	 */
	closeModal = () => {
		const { file } = this.props;
		this.setState({
			status: file && file.status === true,
			showConfirmModal: false
		});
	}

	/**
	 * @description Function to handle confirmation
	 * @memberof FileStatusComponent
	 */
	changeFileStatus = () => {
		this.setState({ showConfirmModal: false });
		this.props.changeFileStatus(this.state.status);
	}
    /* istanbul ignore next  */
	getModalHeader = (fileName) => {
		if (!fileName) {
			return 'File Status';
		}
		// let fileArray = file.split('.');
		// let fileNameText = fileArray && fileArray.length ? fileArray.filter((x, i) => i !== fileArray.length - 1).join('.') : '';
		// let fileExtension = fileArray && fileArray.length ? fileArray[fileArray.length - 1] : '';
		let fileHeader = fileName.length > 25 ? `${fileName.substring(0, 25)}...` : fileName;
		return `File Status: ${fileHeader}`;
	}

	render() {
		const { file } = this.props;
		const { status, showConfirmModal } = this.state;
		return (
			<>
				<div className="device-status-toggle" data-testid="fileStatus">
					<label className="switch">
						<input onChange={(e) => this.toggleConfirmModal(e.target.checked, true)} type="checkbox" data-testid={`file-item-${file.filename}`} id={`file-item-${file.filename}`} value={status} checked={status} />
						<div className="slider round">
							<span className="on">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
									<path d="M7.5 1v7h1V1h-1z" />
									<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
								</svg>
							</span>
							<span className="off">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" className="bi bi-power" viewBox="0 0 16 16">
									<path d="M7.5 1v7h1V1h-1z" />
									<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
								</svg>
							</span>
						</div>
					</label>
				</div>

				{/* Modal to confirm activate/deactivate file */}
				<CustomModal
					id="file-status-modal"
					data-testid="file-status-modal"
					show={showConfirmModal}
					isConfirm={true}
					title={this.getModalHeader(file && file.filename)}
					body={parseHTML(fileListMessages.statusConfirmation(status))}
					closeAction={() => this.closeModal()}
					rejectAction={() => this.closeModal()}
					confirmAction={this.changeFileStatus}
				/>
			</>
		)
	}
}
